import React, {
  useRef,
  useEffect,
  useLayoutEffect,
  useContext,
  useState
} from 'react';
import * as d3 from 'd3';
import SEO from '../components/SEO';
import { DimensionContext } from '../components/ResponsiveWrapper';
import InfoBox from '../components/InfoBox';
import { useDataset } from '../components/DataFetcher';
import LoadingSpinner from '../components/LoadingSpinner';

const HappyPath = () => {
  const canvasRef = useRef(null);
  const dimensions = useContext(DimensionContext);

  const { data, status, error } = useDataset();

  // Local state to store parsed data
  const [chartData, setChartData] = useState({
    mainHappyPath: null,
    disjointedPaths: null,
  });

  /**
   * 1) Parse data & store in local state.
   *    This effect handles JSON parsing, stripping page events, etc.
   */
  useEffect(() => {
    if (status !== 'success' || !data?.HappyPath || typeof data.HappyPath !== 'string') {
      setChartData({ mainHappyPath: null, disjointedPaths: null });
      return;
    }

    try {
      const stripPageFromEvent = (path) =>
        path.map((node) => node.split('@')[0].trim());

      // Parse and strip mainHappyPath
      let mainHappyPath = JSON.parse(data.HappyPath);
      mainHappyPath = stripPageFromEvent(mainHappyPath);

      if (!mainHappyPath || mainHappyPath.length === 0) {
        console.warn('HappyPath is empty or invalid.');
        setChartData({ mainHappyPath: null, disjointedPaths: null });
        return;
      }

      // Parse and strip disjointedPaths
      let disjointedPaths = data.DisjointedPaths
        ? JSON.parse(data.DisjointedPaths).map(stripPageFromEvent)
        : [];

      // Remove mainHappyPath duplicates from disjointedPaths
      disjointedPaths = disjointedPaths.filter(
        (path) => JSON.stringify(path) !== JSON.stringify(mainHappyPath)
      );

      // Store in local state
      setChartData({ mainHappyPath, disjointedPaths });
    } catch (parseErr) {
      console.error('Failed to parse HappyPath or DisjointedPaths:', parseErr);
      setChartData({ mainHappyPath: null, disjointedPaths: null });
    }
  }, [data, status]);

  /**
   * 2) Draw the chart in useLayoutEffect, so we know the canvas is in the DOM.
   */
  useLayoutEffect(() => {
    const { mainHappyPath, disjointedPaths } = chartData;
    if (!mainHappyPath || !canvasRef.current) {
      return;
    }

    // -- Canvas & context
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    if (!context) {
      console.error('Failed to get 2D context from canvas.');
      return;
    }

    // -- Dimensions
    const width = dimensions.width;
    const height = dimensions.height;
    canvas.width = width;
    canvas.height = height;

    console.log('HappyPath main:', mainHappyPath);
    console.log('HappyPath disjointed:', disjointedPaths);

    // ---------------------------
    //  Original chart logic below
    // ---------------------------

    // Sizing
    const xmargin = 10;
    const ymargin = 50;
    const baseNodeWidth = 150; // For better text fit
    const baseNodeHeight = 50;

    // Combine all paths
    const allPaths = [mainHappyPath, ...(disjointedPaths || [])].sort(
      (a, b) => a.length - b.length
    );

    // Create "rows" for layout
    const startNode = mainHappyPath[0];
    const endNode = mainHappyPath[mainHappyPath.length - 1];
    const numRows = allPaths.length;
    const middleRowIndex = Math.floor(numRows / 2);

    // For distributing paths around the main one
    const pathsWithoutStartEnd = allPaths.map((path) => path.slice(1, -1));
    const middlePath = [startNode, ...pathsWithoutStartEnd[0], endNode];
    const rows = new Array(numRows).fill(null).map(() => []);
    rows[middleRowIndex] = middlePath;

    let topIndex = middleRowIndex - 1;
    let bottomIndex = middleRowIndex + 1;
    for (let i = 1; i < allPaths.length; i++) {
      const path = allPaths[i];
      const rowIndex = i % 2 === 0 ? topIndex-- : bottomIndex++;
      rows[rowIndex] = path.slice(1, -1);
    }

    // Scales
    const xScale = d3
      .scaleLinear()
      .domain([0, Math.max(...rows.map((r) => r.length)) - 1])
      .range([xmargin, width - xmargin - baseNodeWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([0, rows.length - 1])
      .range([ymargin, height - ymargin - baseNodeHeight]);

    const commonStartX = xScale(0);
    const commonStartY = yScale(middleRowIndex);
    const commonEndX = xScale(Math.max(...rows.map((r) => r.length)) - 1);

    function handleMouseMove(e) {
      const rect = canvas.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;
    
      let hoveringLabel = null;
    
      // Check each bounding box
      for (let box of nodeBoxes) {
        if (
          mouseX >= box.x &&
          mouseX <= box.x + box.w &&
          mouseY >= box.y &&
          mouseY <= box.y + box.h
        ) {
          hoveringLabel = box.label;
          break; // Stop if we found one
        }
      }
    
      // If we found a hovered label, set it; otherwise clear
      if (hoveringLabel && canvas.title !== hoveringLabel) {
        canvas.title = hoveringLabel;
      } else if (!hoveringLabel && canvas.title) {
        canvas.title = '';
      }
    }
    
    const nodeBoxes = [];

    // // Tooltip on hover
    // canvas.addEventListener('mousemove', (e) => {
    //   const rect = canvas.getBoundingClientRect();
    //   const mouseX = e.clientX - rect.left;
    //   const mouseY = e.clientY - rect.top;
    //   if (
    //     mouseX >= x &&
    //     mouseX <= x + nodeWidth &&
    //     mouseY >= y &&
    //     mouseY <= y + nodeHeight
    //   ) {
    //     canvas.title = node; // show node text
    //   } else if (canvas.title === node) {
    //     canvas.title = ''; // clear tooltip
    //   }
    // });

    // 1) Draw lines
    rows.forEach((row, rowIndex) => {
      const emptySpaces = Math.max(...rows.map((r) => r.length)) - row.length;
      const startPosition = emptySpaces / 2;

      // Adjust box size based on row proximity
      const boxScale = 1 - Math.abs(rowIndex - middleRowIndex) * 0.1;
      const nodeWidth = baseNodeWidth * boxScale;
      const nodeHeight = baseNodeHeight * boxScale;

      row.forEach((node, nodeIndex) => {
        const adjustedIndex = startPosition + nodeIndex;

        // Skip start/end nodes not on middle row
        if (
          (node === startNode || node === endNode) &&
          rowIndex !== middleRowIndex
        ) {
          return;
        }

        let x = xScale(adjustedIndex);
        const y = yScale(rowIndex);

        if (node === startNode) {
          x = xScale(0);
        } else if (node === endNode) {
          x = xScale(Math.max(...rows.map((r) => r.length)) - 1);
        }

        context.lineWidth = 3;
        context.strokeStyle = 'gray';
        context.setLineDash([10, 5]);

        // Horizontal lines within the same row
        if (
          nodeIndex > 0 &&
          node !== endNode &&
          row[nodeIndex - 1] !== startNode
        ) {
          context.beginPath();
          context.moveTo(
            xScale(startPosition + nodeIndex - 1) + nodeWidth / 2,
            y + nodeHeight / 2
          );
          context.lineTo(x + nodeWidth / 2, y + nodeHeight / 2);
          context.stroke();
        }

        // Vertical lines connecting to common start/end
        if (rowIndex !== middleRowIndex) {
          // Connect to start node
          if (nodeIndex === 0) {
            context.beginPath();
            context.moveTo(commonStartX + nodeWidth, commonStartY + nodeHeight / 2);
            context.lineTo(x, y + nodeHeight / 2);
            context.stroke();
          }
          // Connect to end node
          if (nodeIndex === row.length - 1) {
            context.beginPath();
            context.moveTo(x + nodeWidth, y + nodeHeight / 2);
            context.lineTo(commonEndX, commonStartY + nodeHeight / 2);
            context.stroke();
          }
        }
        nodeBoxes.push({
          x,
          y,
          w: nodeWidth,
          h: nodeHeight,
          label: node,
        });
      });
    });

    // 2) Draw the main happy path line
    if (middlePath.length > 2) {
      context.beginPath();
      context.moveTo(
        commonStartX + baseNodeWidth,
        commonStartY + baseNodeHeight / 2
      );
      context.lineTo(commonEndX, commonStartY + baseNodeHeight / 2);
      context.lineWidth = 10;
      context.strokeStyle = '#ebb844';
      context.setLineDash([20, 25]);
      context.stroke();
    }

    // 3) Draw nodes (boxes + text + tooltips)
    rows.forEach((row, rowIndex) => {
      const emptySpaces = Math.max(...rows.map((r) => r.length)) - row.length;
      const startPosition = emptySpaces / 2;

      const boxScale = 1 - Math.abs(rowIndex - middleRowIndex) * 0.1;
      const nodeWidth = baseNodeWidth * boxScale;
      const nodeHeight = baseNodeHeight * boxScale;

      row.forEach((node, nodeIndex) => {
        const adjustedIndex = startPosition + nodeIndex;

        // Skip start/end nodes not on middle row
        if (
          (node === startNode || node === endNode) &&
          rowIndex !== middleRowIndex
        ) {
          return;
        }

        let x = xScale(adjustedIndex);
        const y = yScale(rowIndex);

        if (node === startNode) {
          x = xScale(0);
        } else if (node === endNode) {
          x = xScale(Math.max(...rows.map((r) => r.length)) - 1);
        }

        // Box
        context.setLineDash([]);
        context.beginPath();
        context.rect(x, y, nodeWidth, nodeHeight);
        context.fillStyle = 'white';
        context.fill();
        context.lineWidth = 1;
        context.strokeStyle = '#958e86';
        context.stroke();

        // Clip text
        context.save();
        context.rect(x, y, nodeWidth, nodeHeight);
        context.clip();
        context.font = `${Math.min(12 * boxScale, 12)}px Arial`;
        context.fillStyle = 'black';
        context.fillText(node, x + 10, y + nodeHeight / 2 + 5);
        context.restore();

      });
    });
    canvas.addEventListener('mousemove', handleMouseMove);

    console.log('Canvas drawing completed successfully.');
  }, [chartData, dimensions]);

  
  // ------------------------------------------
  //  Render Logic: Loading & Error States
  // ------------------------------------------

  if (status === 'loading') {
    return <LoadingSpinner />;
  }

  if (
    status === 'error' ||
    (status === 'success' && (!data.HappyPath || typeof data.HappyPath !== 'string'))
  ) {
    return (
      <div style={{ color: 'red', textAlign: 'center', marginTop: '2rem' }}>
        {error?.message || 'Something went wrong or data is not valid JSON.'}
      </div>
    );
  }

  // ------------------------------------------
  //  Render: Canvas + Everything Else
  // ------------------------------------------
  return (
    <div className="p-4 bg-white bg-opacity-80">
      <h1 className="h1 banner">Happy Path</h1>
      <InfoBox
        title={"YourBiz's Happy Path"}
        description="The shortest route, that the most people take, to your most desired outcome"
      />
      {/* 
        We always render the canvas so its ref is set immediately, 
        even while we parse data in the background.
      */}
      <canvas ref={canvasRef} style={{ display: 'block' }} />

      <SEO
        title="Happy Path"
        description="The fastest routes through your product to get to activation."
        url="/happypath"
      />
    </div>
  );
};

export default HappyPath;

// TreeExplorer.jsx
import React, {
    useContext,
    useState,
    useEffect,
    useCallback,
    useMemo,
    useRef,
  } from 'react';
  import { useSelector, useDispatch } from 'react-redux';
  import { Select, Switch } from '@mantine/core';
  import ReactFlow, {
    MiniMap,
    Background,
    Controls,
    Handle,
  } from 'react-flow-renderer';
  import LoadingSpinner from '../components/LoadingSpinner';
  import { DimensionContext } from '../components/ResponsiveWrapper';
  import { useDataset } from '../components/DataFetcher';
  
  const TreeExplorer = () => {
    const [selectedNode, setSelectedNode] = useState(null);
    const [graphElements, setGraphElements] = useState([]);
    const { data, status, error } = useDataset();
    const dispatch = useDispatch();
    const dimensions = useContext(DimensionContext);
    const { width, height } = useContext(DimensionContext);
  
    const [currentLayer, setCurrentLayer] = useState(0);
    const [graph, setGraph] = useState([]);
    const [hasNodeBeenSelected, setHasNodeBeenSelected] = useState(false);
  
    // NEW: toggle state for backward / forward mode
    const [isBackwardMode, setIsBackwardMode] = useState(false);
  
    /**
     * Process incoming data into a structured format.
     */
    const processedData = useMemo(() => {
      console.log('processData has begun');
      if (!data || !data.MarkovChain) return null;
  
      try {
        // Step 1: Parse the outer string to get the JSON string
        const markovChainString = JSON.parse(data.MarkovChain);
  
        // Step 2: Parse the inner JSON string to get the array
        const markovChainData = JSON.parse(markovChainString);
  
        // Validate that markovChainData is indeed an array
        if (!Array.isArray(markovChainData)) {
          throw new Error('Parsed data is not an array');
        }
  
        const setPossibleValues = [
          ...(data?.UniqueURL
            ? Object.keys(data.UniqueURL).map((key) => ({
                value: key,
                label: key,
              }))
            : []),
          ...(data?.UniqueEventDescList
            ? Object.keys(data.UniqueEventDescList).map((key) => ({
                value: key,
                label: key,
              }))
            : []),
        ];
  
        console.log('Processing with markovChainData', markovChainData);
        console.log('Processing with setPossibleValues', setPossibleValues);
  
        return { markovChainData, possibleValues: setPossibleValues };
      } catch (error) {
        console.error('Error parsing MarkovChain data:', error);
        return null;
      }
    }, [data]);
  
    /**
     * Once data is ready, generate the nodes and edges,
     * then build the `graph` structure.
     */
    useEffect(() => {
      console.log('useEffect processedData has begun');
  
      if (status === 'success' && processedData?.markovChainData) {
        console.log('Data processing and graph generation has begun');
  
        const nodes = generateNodes(processedData.markovChainData);
        const edges = generateEdges(processedData.markovChainData);
  
        // Build the structured graph object
        const structuredGraph = {
          nodes: nodes,
          // For edges, we store them by source so we can quickly find "next" nodes.
          edges: edges.reduce((acc, edge) => {
            const [source, target] = edge.id.split(' | ');
            if (source && target && source !== target) {
              // We already parsed numeric probability on the edge itself
              const { probability } = edge;
              if (!isNaN(probability)) {
                if (!acc[source]) acc[source] = [];
                acc[source].push({ target, probability });
              }
            }
            return acc;
          }, {}),
        };
  
        setGraph(structuredGraph);
      }
    }, [processedData, status]);
  
    /**
     * Generate all unique nodes from the Markov chain data.
     */
    const generateNodes = useCallback((markovChainData) => {
      console.log('Started to generateNodes with ', markovChainData);
  
      if (!markovChainData) {
        console.log('No valid data or MarkovChain data available.');
        return [];
      }
  
      const nodes = [];
      // We gather all unique node names from 'From' and 'To'.
      const nodeNames = new Set(
        markovChainData
          .map((item) => item.From)
          .concat(markovChainData.map((item) => item.To))
      );
      console.log('generateNodes nodeNames ', nodeNames);
  
      let index = 0;
      nodeNames.forEach((name) => {
        nodes.push({
          id: name,
          type: 'node',
          data: { label: name },
          position: { x: 10, y: 100 + index * 50 }, // Simple stagger
        });
        index++;
      });
  
      console.log('generateNodes nodes ', nodes);
      return nodes;
    }, []);
  
    /**
     * Generate edges from the Markov chain data, and
     * sort them by probability (descending).
     */
    const generateEdges = useCallback((markovChainData) => {
      console.log('generateEdges has begun');
  
      if (!markovChainData) {
        console.log('No valid data or MarkovChain data available.');
        return [];
      }
  
      const edges = [];
      markovChainData.forEach(({ From: source, To: target, Probability }) => {
        // Skip self-loops
        if (source !== target) {
          const numericProbability = Probability * 100;
          edges.push({
            id: `${source} | ${target}`,
            type: 'default',
            source,
            target,
            label: `Probability: ${numericProbability.toFixed(1)}%`,
            probability: numericProbability, // Store numeric value for sorting
          });
        }
      });
  
      // Sort edges in descending order by probability
      edges.sort((a, b) => b.probability - a.probability);
  
      console.log('generateEdges sorted', edges);
      return edges;
    }, []);
  
    /**
     * A custom node component, if needed. Otherwise using default.
     */
    const CustomNode = ({ data, id }) => {
      console.log('CustomNode has begun');
      // If you have special UI or interactive behavior for each node, define it here.
      // Otherwise, the default node type can suffice.
  
      // Example inline click
      const handleClick = (e) => {
        e.stopPropagation();
        handleNodeSelect(id);
      };
  
      return (
        <div onDragStart={(e) => e.stopPropagation()} onClick={handleClick}>
          <div>{data.label}</div>
          {data.hasMore && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                // Possibly load more data or expand the node
                handleNodeSelect(`more-${id}`);
              }}
            >
              + More
            </button>
          )}
        </div>
      );
    };
  
    /**
     * Retrieve connected elements for a given node. The behavior changes
     * based on forward or backward mode.
     */
    // const getConnectedElements = (
    //   selectedNodeId,
    //   graph,
    //   dimensions,
    //   isBackwardMode
    // ) => {
    //   console.log('getConnectedElements called for nodeId', selectedNodeId);
    //   console.log('graph passed to getConnectedElements', graph);
    //   console.log('dimensions for getConnectedElements', dimensions);
  
    //   const connectedNodes = [
    //     {
    //       id: selectedNodeId,
    //       type: 'node',
    //       data: { label: selectedNodeId },
    //       position: { x: 50, y: 50 },
    //       layer: 1,
    //       color: '#7174b0',
    //     },
    //   ];
    //   const connectedEdges = [];
  
    //   // Forward mode: show "next" targets from the selected node
    //   if (!isBackwardMode) {
    //     if (graph.edges && graph.edges[selectedNodeId]) {
    //       graph.edges[selectedNodeId].forEach(({ target, probability }, index) => {
    //         connectedEdges.push({
    //           id: `${selectedNodeId} | ${target}`,
    //           type: 'default',
    //           source: selectedNodeId,
    //           target,
    //           label: `Probability: ${probability.toFixed(1)}%`,
    //           layer: 2,
    //         });
  
    //         if (target !== selectedNodeId) {
    //           connectedNodes.push({
    //             id: target,
    //             type: 'node',
    //             data: { label: target },
    //             position: {
    //               x: (dimensions.width / 10) * (index + 2) - 200,
    //               y: index % 2 === 0 ? 200 : 275,
    //             },
    //             layer: 2,
    //           });
    //         }
    //       });
    //     } else {
    //       console.log('No connected edges found for nodeId', selectedNodeId);
    //     }
    //   } else {
    //     // Backward mode: find all sources that point to the selected node
    //     if (graph.edges) {
    //       // We iterate over all known sources to see which has a target matching the selected node
    //       Object.keys(graph.edges).forEach((sourceKey) => {
    //         const edgesArr = graph.edges[sourceKey];
    //         edgesArr.forEach(({ target, probability }, index) => {
    //           if (target === selectedNodeId) {
    //             // We found a path leading into selectedNodeId
    //             connectedEdges.push({
    //               id: `${sourceKey} | ${selectedNodeId}`,
    //               type: 'default',
    //               source: sourceKey,
    //               target: selectedNodeId,
    //               label: `Probability: ${probability.toFixed(1)}%`,
    //               layer: 2,
    //             });
  
    //             if (sourceKey !== selectedNodeId) {
    //               connectedNodes.push({
    //                 id: sourceKey,
    //                 type: 'node',
    //                 data: { label: sourceKey },
    //                 position: {
    //                   x: (dimensions.width / 10) * (index + 2) - 200,
    //                   y: index % 2 === 0 ? 200 : 275,
    //                 },
    //                 layer: 2,
    //               });
    //             }
    //           }
    //         });
    //       });
    //     }
    //   }
  
    //   console.log('getConnectedElements connectedNodes', connectedNodes);
    //   console.log('getConnectedElements connectedEdges', connectedEdges);
  
    //   return [...connectedNodes, ...connectedEdges];
    // };

    const getConnectedElements = (
        selectedNodeId,
        graph,
        dimensions,
        isBackwardMode
      ) => {
        console.log('getConnectedElements called for nodeId', selectedNodeId);
        console.log('graph passed to getConnectedElements', graph);
        console.log('dimensions for getConnectedElements', dimensions);
      
        const connectedNodes = [
          {
            id: selectedNodeId,
            type: 'node',
            data: { label: selectedNodeId },
            position: { x: 50, y: 50 },
            layer: 1,
            color: '#7174b0',
          },
        ];
        const connectedEdges = [];
      
        if (!isBackwardMode) {
          // FORWARD MODE: show next nodes
          if (graph.edges && graph.edges[selectedNodeId]) {
            graph.edges[selectedNodeId].forEach(({ target, probability }, index) => {
              connectedEdges.push({
                id: `${selectedNodeId} | ${target}`,
                type: 'default',
                source: selectedNodeId,
                target,
                label: `Probability: ${probability.toFixed(1)}%`,
                layer: 2,
              });
      
              if (target !== selectedNodeId) {
                connectedNodes.push({
                  id: target,
                  type: 'node',
                  data: { label: target },
                  position: {
                    x: (dimensions.width / 10) * (index + 2) - 200,
                    // *Below* for forward mode
                    y: index % 2 === 0 ? 200 : 275,
                  },
                  layer: 2,
                });
              }
            });
          } else {
            console.log('No connected edges found for nodeId', selectedNodeId);
          }
        } else {
          // BACKWARD MODE: find all sources that lead into selectedNodeId
          if (graph.edges) {
            Object.keys(graph.edges).forEach((sourceKey) => {
              const edgesArr = graph.edges[sourceKey];
              edgesArr.forEach(({ target, probability }, index) => {
                if (target === selectedNodeId) {
                  connectedEdges.push({
                    id: `${sourceKey} | ${selectedNodeId}`,
                    type: 'default',
                    source: sourceKey,
                    target: selectedNodeId,
                    label: `Probability: ${probability.toFixed(1)}%`,
                    layer: 2,
                  });
      
                  if (sourceKey !== selectedNodeId) {
                    connectedNodes.push({
                      id: sourceKey,
                      type: 'node',
                      data: { label: sourceKey },
                      position: {
                        x: (dimensions.width / 10) * (index + 2) - 200,
                        // *Above* for backward mode
                        y: index % 2 === 0 ? -200 : -275,
                      },
                      layer: 2,
                    });
                  }
                }
              });
            });
          }
        }
      
        console.log('getConnectedElements connectedNodes', connectedNodes);
        console.log('getConnectedElements connectedEdges', connectedEdges);
      
        return [...connectedNodes, ...connectedEdges];
      };
  
    /**
     * Fired when a user selects a node via the <Select> dropdown.
     */
    const handleNodeSelect = (nodeId) => {
      console.log('handleNodeSelect has begun', nodeId);
      console.log('Current graph in handleNodeSelect', graph);
  
      setSelectedNode(nodeId);
  
      const connectedElements = getConnectedElements(
        nodeId,
        graph,
        dimensions,
        isBackwardMode
      );
      console.log('connectedElements in handleNodeSelect', connectedElements);
  
      // Only update if the elements are actually different to avoid unnecessary re-renders
      setGraphElements((prevElements) => {
        const newElements = JSON.stringify(connectedElements);
        const oldElements = JSON.stringify(prevElements);
        if (newElements !== oldElements) {
          return connectedElements;
        }
        return prevElements;
      });
  
      console.log('setHasNodeBeenSelected has begun', hasNodeBeenSelected);
      // Delays by 5 seconds
      setTimeout(() => {
        setHasNodeBeenSelected(true);
      }, 5000);
    };
  
    /**
     * For newly discovered elements, we calculate positions so
     * they don't overlap existing ones.
     */
    const calculateNewNodePositions = (newNodes, dimensions, existingNodesLength) => {
      console.log('calculateNewNodePositions has begun');
  
      return newNodes.map((node, index) => {
        const x =
          ((existingNodesLength + index) % 5) * (dimensions.width / 5) + 100;
        const y =
          Math.floor((existingNodesLength + index) / 5) * 150 + 100;
        return { ...node, position: { x, y } };
      });
    };
  
    /**
     * Called when the user selects a node inside the flow diagram (click or drag-selection).
     */
    const onSelectionChange = useCallback(
      ({ nodes }) => {
        if (nodes && nodes.length > 0) {
          const nodeId = nodes[0].id;
          const layer = getCurrentLayer(nodeId);
          setCurrentLayer(layer);
  
          const connectedElements = getConnectedElements(
            nodeId,
            graph,
            dimensions,
            isBackwardMode
          );
  
          setGraphElements((prevElements) => {
            const existingIds = new Set(prevElements.map((el) => el.id));
            // Filter out those we don't already have
            const newElements = connectedElements.filter(
              (el) => !existingIds.has(el.id)
            );
  
            if (newElements.length > 0) {
              // Calculate positions without moving existing ones
              const newElementsWithPositions = calculateNewNodePositions(
                newElements,
                dimensions,
                prevElements.length
              );
              return [...prevElements, ...newElementsWithPositions];
            }
            return prevElements;
          });
  
          setSelectedNode(nodeId);
        }
      },
      [graph, dimensions, isBackwardMode]
    );
  
    /**
     * Debug / logging
     */
    useEffect(() => {
      console.log('graphElements updated', graphElements);
    }, [graphElements]);
  
    useEffect(() => {
      console.log('hasNodeBeenSelected updated', hasNodeBeenSelected);
    }, [hasNodeBeenSelected]);
  
    /**
     * Extract the current layer from the node ID, if you use a naming pattern.
     */
    const getCurrentLayer = (nodeId) => {
      console.log('getCurrentLayer has begun with nodeId:', nodeId);
      // Example: "node-2-abc123"
      const layerMatch = nodeId.match(/node-(\d+)-[\w\d]+/);
      if (layerMatch && layerMatch.length > 1) {
        const layer = parseInt(layerMatch[1], 10);
        console.log(`Extracted layer: ${layer} from nodeId: ${nodeId}`);
        return layer;
      } else {
        console.error(`getCurrentLayer: Unable to extract layer from nodeId: ${nodeId}`);
        return 0; // default fallback
      }
    };
  
    /**
     * When nodes move, we update their positions in state.
     */
    const handleNodesChange = useCallback((changes) => {
      console.log('handleNodesChange has begun');
      setGraphElements((els) => {
        let updated = false;
        const newEls = els.map((el) => {
          const change = changes.find((c) => c.id === el.id);
          if (
            change &&
            change.position &&
            (change.position.x !== el.position.x ||
              change.position.y !== el.position.y)
          ) {
            updated = true;
            return { ...el, position: change.position };
          }
          return el;
        });
        return updated ? newEls : els;
      });
    }, []);
  
    /**
     * When new edges are drawn in the UI, add them.
     */
    const handleConnect = (connection) => {
      console.log('handleConnect has begun');
      setGraphElements((prevElements) => [
        ...prevElements,
        {
          id: `e-${connection.source}-${connection.target}`,
          source: connection.source,
          target: connection.target,
          label: 'New Connection',
          type: 'default',
        },
      ]);
    };
  
    /**
     * Loading / Error Handling
     */
    if (status === 'loading') return <LoadingSpinner />;
    if (status === 'error') return <div>Error: {error.message}</div>;
    if (data.length === 0) {
      console.log('Still loading from data.length:', data);
      return <LoadingSpinner />;
    }
    if (processedData.markovChainData === '') {
      console.log('Still loading from processData.markovChainData:', processedData.markovChainData);
      return <LoadingSpinner />;
    }
    if (processedData?.possibleValues === '') {
      console.log('Still loading from processData.possibleValues:', processedData.possibleValues);
      return <LoadingSpinner />;
    }
  
    if (!processedData.possibleValues === '') {
      console.log('possibleValues have loaded:', processedData.possibleValues);
    }
    if (currentLayer !== null) {
      console.log('CurrentLayer is:', currentLayer);
    } else {
      console.log('Current Layer is null.');
      return <LoadingSpinner />;
    }
  
    console.log('At the Return');
  
    /**
     * Final return / component layout.
     */
    return (
      <div className="TreeExplorer-Container">
        <h1 className="h1 banner">Tree Explorer</h1>
  
        {/* NEW: Toggle switch for backward / forward mode */}
        <div style={{ marginBottom: '1rem' }}>
          <Switch
            checked={isBackwardMode}
            onChange={(event) => setIsBackwardMode(event.currentTarget.checked)}
            label="Backward Mode"
          />
        </div>
  
        <Select
          data={processedData?.possibleValues || []}
          onChange={handleNodeSelect}
          placeholder="Select a node"
          searchable
          nothingFound="No options"
        />
  
        <div className="flow-container">
          <ReactFlow
            elements={graphElements}
            defaultEdges={graphElements.filter((e) => e.type === 'default')}
            defaultNodes={graphElements.filter((e) => e.type === 'node')}
            onNodesChange={handleNodesChange}
            onConnect={handleConnect}
            onNodeDragStart={(event, node) => console.log(`Dragging node: ${node.id}`)}
            onSelectionChange={onSelectionChange}
            nodeTypes={{ customNode: CustomNode }}
            className="reactflow"
            minZoom={0.5}
            maxZoom={2}
            panOnScroll={true}
            panOnDrag={true}
          >
            <Background variant="dots" gap={26} size={2} color="#ebe5df" />
            <Controls />
            <MiniMap
              nodeStrokeColor={() => '#2c294b'}
              nodeColor={() => '#fff'}
              nodeBorderRadius={2}
            />
            <Handle type="target" position="left" />
            <Handle type="source" position="right" />
          </ReactFlow>
        </div>
      </div>
    );
  };
  
  export default TreeExplorer;
  
  
  // // TreeExplorer.jsx
// import React, { useContext, useState, useEffect, useCallback, useMemo, useRef } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { Select } from '@mantine/core';
// import ReactFlow, { MiniMap, Background, Controls, Handle } from 'react-flow-renderer';
// import LoadingSpinner from '../components/LoadingSpinner';
// import { DimensionContext } from '../components/ResponsiveWrapper';
// import { useDataset } from '../components/DataFetcher';
// // import { setGraph, updateGraph, setGraphElements, addGraphElement, removeGraphElement, updateGraphElement, setSelectedNodesByLayer, addSelectedNode, removeSelectedNode, setExpandedNodes, setCurrentLayer, selectExpandedNodes, selectCurrentLayer, selectGraphElements } from '../redux/slices/treeExplorerSlice';
// // import { index } from 'd3';


// const TreeExplorer = () => {
//     const [selectedNode, setSelectedNode] = useState(null);
//     const [graphElements, setGraphElements] = useState([]);
//     // const [expandedNodes, setExpandedNodes] = useState(new Set());
//     // const [selectedElements, setSelectedElements] = useState([]);
//     const { data, status, error } = useDataset();
//     const dispatch = useDispatch();
//     const dimensions = useContext(DimensionContext);
//     const { width, height } = useContext(DimensionContext);

//     // const [selectedNodesByLayer, setSelectedNodesByLayer] = useState({});
//     const [currentLayer, setCurrentLayer] = useState(0);
//     const [graph, setGraph] = useState([]);
//     // const [possibleValues, setPossibleValues] = useState([]);
//     const [hasNodeBeenSelected, setHasNodeBeenSelected] = useState(false);
//     // const lastProcessedDataRef = useRef();

//     const processedData = useMemo(() => {
//         console.log("processData has begun");
    
//         if (!data || !data.MarkovChain) return null;
    
//         try {
//             // Step 1: Parse the outer string to get the JSON string
//             const markovChainString = JSON.parse(data.MarkovChain);
    
//             // Step 2: Parse the inner JSON string to get the array
//             const markovChainData = JSON.parse(markovChainString);
    
//             // Validate that markovChainData is indeed an array
//             if (!Array.isArray(markovChainData)) {
//                 throw new Error('Parsed data is not an array');
//             }
    
//             const setPossibleValues = [
//                 ...(data?.UniqueURL ? Object.keys(data.UniqueURL).map(key => ({ value: key, label: key })) : []),
//                 ...(data?.UniqueEventDescList ? Object.keys(data.UniqueEventDescList).map(key => ({ value: key, label: key })) : [])
//             ];
    
//             console.log("Processing with markovChainData", markovChainData);
//             console.log("Processing with setPossibleValues", setPossibleValues);
    
//             return { markovChainData, possibleValues: setPossibleValues };
    
//         } catch (error) {
//             console.error("Error parsing MarkovChain data:", error);
//             return null;
//         }
//     }, [data]);
    
    
//     useEffect(() => {
//         console.log("useEffect processedData has begun");

//         if (status === 'success' && processedData?.markovChainData) {
//             console.log("Data processing and graph generation has begun");
    
//             const nodes = generateNodes(processedData.markovChainData);
//             const edges = generateEdges(processedData.markovChainData);
            
//             // Creating a structured graph object
//             const graph = {
//                 nodes: nodes,
//                 edges: edges.reduce((acc, edge) => {
//                     // Assuming edge.id is of the form 'source to target'
//                     const [source, target] = edge.id.split(' | ');
//                     if (source && target && source !== target) {
//                         const probabilityStr = edge.label.split(':')[1].replace('%', '').trim();
//                         const probability = parseFloat(probabilityStr);
//                     if (!isNaN(probability)) {
//                         if (!acc[source]) acc[source] = [];
//                         acc[source].push({ target, probability });
//                     }
//                 }

//         return acc;
//                 }, {})
//             };
    
//             setGraph(graph); // Assuming setGraphElements expects this structured graph
//         }
//     }, [processedData]);

//     const generateNodes = useCallback((markovChainData) => {
//         console.log("Started to generateNodes with ", markovChainData);
    
//         if (!markovChainData) {
//             console.log("No valid data or MarkovChain data available.");
//             return [];
//         }
    
//         const nodes = [];
        
//         // Use a Set to track unique node names from the `From` and `To` properties
//         const nodeNames = new Set(markovChainData.map(item => item.From).concat(markovChainData.map(item => item.To)));
//         console.log("generateNodes nodeNames ", nodeNames);
    
//         nodeNames.forEach((name, index) => {
//             nodes.push({
//                 id: name,
//                 type: 'node',
//                 data: { label: name },
//                 position: { x: 10, y: 100 + index * 50 } // Staggering y position for visibility
//             });
//         });
        
//         console.log("generateNodes nodes ", nodes);
//         return nodes;
//     }, []);
    
//     const generateEdges = useCallback((markovChainData) => {
//         console.log("generateEdges has begun");
    
//         if (!markovChainData) {
//             console.log("No valid data or MarkovChain data available.");
//             return [];
//         }
    
//         const edges = [];
//         markovChainData.forEach(({ From: source, To: target, Probability: probability }) => {
//             if (source !== target) {
//                 edges.push({
//                     id: `${source} | ${target}`,
//                     type: 'default',
//                     source,
//                     target,
//                     label: `Probability: ${(probability * 100).toFixed(1)}%`
//                 });
//             }
//         });
    
//         console.log("generateEdges", edges);
//         return edges;
//     }, []);
    
//     const CustomNode = ({ data, id }) => {
//         console.log("CustomNode has begun");

//         return (
//             <div onDragStart={(e) => e.stopPropagation()} onClick={(e) => onSelectionChange(e, { id, ...data })}>
//                 <div>{data.label}</div>
//                 {data.hasMore && <button onClick={(e) => {
//                     e.stopPropagation(); // Prevent triggering drag or node click
//                     onSelectionChange(e, { id: `more-${id}`, ...data });
//                 }}>+ More</button>}
//             </div>
//         );
//     };

//     const getConnectedElements = (selectedNodeId, graph, dimensions) => {
    

//         console.log("getConnectedElements called for nodeId", selectedNodeId);
//         console.log("graph passed to getConnectedElements", graph);
//         console.log("dimensions for getConnectedElements", dimensions);
    
//             const connectedNodes = [{ 
//                 id: selectedNodeId, 
//                 type: 'node', 
//                 data: { label: selectedNodeId }, 
//                 position: { x: 50 , y: 50 },
//                 // position: { x: dimensions.width / 2 - 50 , y: 20 },
//                 layer: 1, 
//                 color: '#7174b0',
//             }];
//             const connectedEdges = [];
        
//             if (graph.edges && graph.edges[selectedNodeId]) {
//                 graph.edges[selectedNodeId].forEach(({ target, probability }, index) => {
//                     // Add edge
//                     connectedEdges.push({
//                         id: `${selectedNodeId} | ${target}`,
//                         type: 'default',
//                         source: selectedNodeId,
//                         target,
//                         label: `Probability: ${probability}%`,
//                         layer: 2,

//                     });
        
//                     // Add target node if it's not the same as the selected node
//                     if (target !== selectedNodeId) {
//                         connectedNodes.push({
//                             id: target,
//                             type: 'node',
//                             data: { label: target },
//                             position: { 
//                                 x: (dimensions.width / 10 * (index + 2)) - 200, 
//                                 y: (index % 2 === 0) ? 200 : 275 // If index is even, y is 200, otherwise 250
//                             },
//                             layer: 2,
//                         });
//                     }
//                 });
//             } else {
//                 console.log("No connected edges found for nodeId", selectedNodeId);
//             }

//             console.log("getConnectedElements connectedNodes", connectedNodes);
//             console.log("getConnectedElements connectedEdges", connectedEdges);

//             return [...connectedNodes, ...connectedEdges];
//         };
   
//     const handleNodeSelect = (nodeId) => {
//             console.log("handleNodeSelect has begun", nodeId);
//             console.log("Current graph in handleNodeSelect", graph);
        
//             setSelectedNode(nodeId);
//             const connectedElements = getConnectedElements(nodeId, graph, dimensions);
//             console.log("connectedElements in handleNodeSelect", connectedElements);
        
//             // Only update if the elements are actually different to avoid unnecessary re-renders
//             setGraphElements(prevElements => {
//                 const newElements = JSON.stringify(connectedElements);
//                 const oldElements = JSON.stringify(prevElements);
//                 if (newElements !== oldElements) {
//                     return connectedElements;
//                 }
//                 return prevElements;
//             });
        
//             console.log("setHasNodeBeenSelected has begun", hasNodeBeenSelected);
//             // This seems to be intended to delay something by 5 seconds, not 500 milliseconds as the comment suggests
//             setTimeout(() => {
//                 setHasNodeBeenSelected(true);
//             }, 5000);
//         };

//     const calculateNewNodePositions = (newNodes, dimensions, existingNodesLength) => {
//         console.log("calculateNewNodePositions has begun");

//         // Calculates positions for new nodes based on the total number of existing nodes
//         return newNodes.map((node, index) => {
//             let x = ((existingNodesLength + index) % 5) * (dimensions.width / 5) + 100; // Adjust grid size as needed
//             let y = Math.floor((existingNodesLength + index) / 5) * 150 + 100; // Vertical spacing between layers
//             return { ...node, position: { x, y } };
//         });
//     };
    
    
//     const onSelectionChange = useCallback(({ nodes }) => {
//         if (nodes.length > 0) {
//             const nodeId = nodes[0].id;
//             const layer = getCurrentLayer(nodeId);
//             setCurrentLayer(layer);
    
//             const connectedElements = getConnectedElements(nodeId, graph, dimensions);
    
//             setGraphElements((prevElements) => {
//                 const existingElements = new Set(prevElements.map(el => el.id));
//                 const newElements = connectedElements.filter(el => !existingElements.has(el.id));
    
//                 if (newElements.length > 0) {
//                     // Calculate positions for new nodes without moving existing ones
//                     const newElementsWithPositions = calculateNewNodePositions(newElements, dimensions, prevElements.length);
//                     return [...prevElements, ...newElementsWithPositions];
//                 }
    
//                 return prevElements; // Return unchanged if no new elements
//             });
    
//             setSelectedNode(nodeId);
//         }
//     }, [graph, dimensions]);
    
//     useEffect(() => {
//         console.log("graphElements updated", graphElements);
//     }, [graphElements]);

//     useEffect(() => {
//         console.log("hasNodeBeenSelected updated", hasNodeBeenSelected);
//     }, [hasNodeBeenSelected]);

    

// // Assuming node ID format is "node-layer-uniqueId"
// const getCurrentLayer = (nodeId) => {
//     console.log("getCurrentLayer has begun with nodeId:", nodeId);
    
//     // Use a regular expression to extract layer information
//     // This regex assumes the format "node-layer-uniqueId"
//     const layerMatch = nodeId.match(/node-(\d+)-[\w\d]+/);

//     if (layerMatch && layerMatch.length > 1) {
//         // Successfully extracted layer number from the node ID
//         const layer = parseInt(layerMatch[1], 10);
//         console.log(`Extracted layer: ${layer} from nodeId: ${nodeId}`);
//         return layer;
//     } else {
//         // Log an error or handle the unexpected format gracefully
//         console.error(`getCurrentLayer: Unable to extract layer from nodeId: ${nodeId}`);
//         // Return a default layer or handle this scenario appropriately in your application
//         return 0; // Assuming layer 0 as a fallback might not be ideal for all applications
//     }
// };



// const handleNodesChange = useCallback((changes) => {
//     console.log("handleNodesChange has begun");

//     setGraphElements((els) => {
//         // Flag to check if any element has been updated
//         let updated = false;
//         const newEls = els.map((el) => {
//             const change = changes.find((c) => c.id === el.id);
//             if (change && change.position && (change.position.x !== el.position.x || change.position.y !== el.position.y)) {
//                 updated = true; // Mark as updated if positions differ
//                 return { ...el, position: change.position };
//             }
//             return el;
//         });

//         // Only update state if there was an actual update to positions
//         return updated ? newEls : els;
//     });
// }, []);


// // Function to handle new connections/edges
// const handleConnect = (connection) => {
//     console.log("handleConnect has begun");

//     setGraphElements(prevElements => [
//         ...prevElements,
//         {
//             id: `e-${connection.source}-${connection.target}`,
//             source: connection.source,
//             target: connection.target,
//             // Add any additional properties you need for the edge
//             // For example, a label with default value or based on some logic
//             label: 'New Connection',
//             type: 'default', // or any other type you are using
//         }
//     ]);
// };

  

//         if (status === 'loading') return <LoadingSpinner />;
//         if (status === 'error') return <div>Error: {error.message}</div>;
//         if (data.length === 0) {
//             console.log('Still loading from data.length:', data);
//             return <LoadingSpinner />; // Display a loading spinner or loading indicator
//         }
//         if (processedData.markovChainData === "") {
//             console.log('Still loading from processData.markovChainData:', processedData.markovChainData);
//             return <LoadingSpinner />; // Display a loading spinner or loading indicator
//         }
//         if (processedData?.possibleValues === "") {
//         console.log('Still loading from processData.possibleValues:', processedData.possibleValues);
//         return <LoadingSpinner />; // Display a loading spinner or loading indicator
//           }

//         if (!processedData.possibleValues === "") {
//             console.log('possibleValues have loaded:', processedData.possibleValues);
//         }
//         if (currentLayer !== null) {
//             console.log('CurrentLayer is:', currentLayer);
//         } else {
//             console.log('Current Layer is null.');
//             return <LoadingSpinner />;
//           }
//           console.log("At the Return");
//         //   console.log("Current Graph Elements:", graphElements);

// return (
//             <div className='TreeExplorer-Container'>
//             <h1 className="h1 banner">Tree Explorer</h1>
//         <Select
//             data={processedData?.possibleValues || []}
//             onChange={handleNodeSelect}
//             placeholder="Select a node"
//             searchable
//             nothingFound="No options"
//         />
//         <div className="flow-container">
//         <ReactFlow
//             elements={graphElements}
//             defaultEdges={graphElements.filter(e => e.type === 'default')}
//             defaultNodes={graphElements.filter(e => e.type === 'node')}
//             onNodesChange={handleNodesChange}
//             // onKeyDown={handleDropdownKeyDown}
//             // onEdgesChange={handleEdgesChange}
//             onConnect={handleConnect} // put this back in
//             // onElementClick={handleNodeClick} // Integrating the node click handler
//             // onElementClick={(event, element) => handleNodeClick(event, element)}
//             onNodeDragStart={(event, node) => console.log(`Dragging node: ${node.id}`)}
//             onSelectionChange={onSelectionChange} // Use the onSelectionChange prop here
//             nodeTypes={{ customNode: CustomNode }}
//             className="reactflow"
//             minZoom={0.5}
//             maxZoom={2}
//             panOnScroll={true}
//             panOnDrag={true}
//         >
//             <Background variant="dots" gap={26} size={2} color={'#ebe5df'}/>
//             <Controls />
//             <MiniMap
//                 nodeStrokeColor={() => '#2c294b'}
//                 nodeColor={() => '#fff'}
//                 nodeBorderRadius={2}    
//             />
//             <Handle type="target" position="left" />
//             <Handle type="source" position="right" />
//             {/* <UndoRedoControls /> */}
//         </ReactFlow>
//     </div>
//     </div>
// );
// }

// export default TreeExplorer;


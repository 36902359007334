import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { QueryProvider } from './components/QueryProvider';
import Sidebar from './Sidebar';
import ErrorBoundary from './components/ErrorBoundary';
import Auth from './components/Auth';
import SignUp from './components/SignUp';
import { jwtDecode } from 'jwt-decode';

// import { AuthProvider } from './components/AuthContext';
import SEO from './components/SEO';
import { ResponsiveWrapper } from './components/ResponsiveWrapper';
import useDebouncedResize from './components/ScreenResized';
import { MantineProvider } from '@mantine/core';

// import OutcomesAndMilestones from './pages/OutcomesAndMilestones';
// import Experiments from './pages/Experiments';
// import Admin from './pages/Admin';
// import Tagging from './pages/Tagging';
import HappyPath from './pages/HappyPath';
import ModelOutput from './pages/ModelOutput';
import PathAnalyzer from './pages/PathAnalyzer';
// import BetaRequest from './pages/BetaRequest';
import Benchmarks from './pages/Benchmarks';
import FeatureInsights from './pages/FeatureInsights';
import Onboarding from './pages/Onboarding';
import Admin from './pages/Admin';
import Conversations from './pages/Conversations';
import JourneyFlow from './pages/JourneyFlow';
import { Provider } from 'react-redux';
import store from './redux/store';
import TreeExplorer from './pages/TreeExplorer';
import Funnel from './pages/Funnel';
// import SankeyDiagram from './pages/Sankey';
import Trends from './pages/Trends';
import UserInspector from './pages/UserInspector';
import Peaks from './pages/Peaks';



const routeConfig = {
  auth: { category: 'Admin', component: Auth, title: 'Authentication', description: 'User authentication page', url: '/auth' },
  signup: { category: 'Admin', component: SignUp, title: 'SignUp', description: 'Create a new Teraace Account', url: '/signup' },
  trends: { category: 'Insights', component: Trends, title: 'Trends', description: 'Description for Trends', url: '/trends' },
  benchmarks: { category: 'Insights', component: Benchmarks, title: 'Benchmarks', description: 'Description for Benchmarks', url: '/benchmarks' },
  funnel: { category: 'Insights', component: Funnel, title: 'Funnel', description: 'Conversion Funnel of Critical Pages', url: '/funnel' },
  happyPath: { category: 'Insights', component: HappyPath, title: 'Happy Path', description: 'Description for Happy Path', url: '/happypath' },
  userInspector: { category: 'Validation', component: UserInspector, title: 'User Inspector', description: 'Description for User Inspector', url: '/userinspector' },
  conversations: { category: 'Validation', component: Conversations, title: 'Conversations', description: 'Conversations', url: '/conversations' },
  pathAnalyzer: { category: 'Exploration', component: PathAnalyzer, title: 'Path Analyzer', description: 'Description for Path Analyzer', url: '/pathanalyzer' },
  journeyFlow: { category: 'Validation', component: JourneyFlow, title: 'Journey Flow', description: 'Description for Journey Flow', url: '/journeyflow' },
  peaks: { category: 'Exploration', component: Peaks, title: 'Peaks', description: 'Description for Peaks', url: '/peaks' },
  featureInsights: { category: 'Exploration', component: FeatureInsights, title: 'Feature Insights', description: 'Description for Feature Insights', url: '/featureinsights' },
  treeExplorer: { category: 'Exploration', component: TreeExplorer, title: 'Tree Explorer', description: 'Description for Tree Explorer', url: '/treeexplorer' },
  onboarding: { category: 'Admin', component: Onboarding, title: 'Onboarding', description: 'Description for Onboarding', url: '/onboarding' },
  admin: { category: 'Admin', component: Admin, title: 'Admin', description: 'Description for Admin', url: '/admin' },
  // sankey: { category: 'Exploration', component: SankeyDiagram, title: 'Sankey', description: 'Description for Sankey', url: '/sankey' },
  // betaRequest: { category: 'Admin', component: BetaRequest, title: 'Beta Request', description: 'Description for Beta Request', url: '/betarequest' },
  modelOutput: { category: 'Validation', component: ModelOutput, title: 'Model Data', description: 'Description for Seeing Data', url: '/modeloutput' },
};


const Content = React.memo(({ resizeKey }) => {
  // console.log("Content component rerendered");
  // const [currentPage, setCurrentPage] = useState('happyPath'); // Example page state
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(location.pathname.replace('/', ''));
  const { title, description, url } = routeConfig[currentPage] || {};
  const pages = Object.keys(routeConfig).map(key => ({
    name: routeConfig[key].title,
    route: routeConfig[key].url,
    category: routeConfig[key].category,
  }));
  // const [userRole, setUserRole] = useState('');

  // const [orgs, setOrgs] = useState([]);
  // const [activeOrgID, setActiveOrgID] = useState(undefined);

  // console.log('App component rendering'); // Add this log
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  
  const prevImageNumbersRef = useRef(new Set());

  const getRandomBackgroundImage = () => {
    // console.log('getRandomBackgroundImage called');
  
    let imageNumber;
    let attempts = 0; // To prevent infinite loop in case all images are already shown
  
    do {
      imageNumber = Math.floor(Math.random() * 137) + 1;
      attempts++;
    } while (prevImageNumbersRef.current.has(imageNumber) && attempts < 137);
  
    // If we've shown all images, reset the Set
    if (attempts === 137) {
      prevImageNumbersRef.current.clear();
    }
  
    prevImageNumbersRef.current.add(imageNumber);
    // console.log('prevImageNumbersRefs', prevImageNumbersRef);

    const paddedNumber = String(imageNumber).padStart(3, '0');
    return `/assets/bg/Teraace-bg-${paddedNumber}.webp`;
  };

  const [backgroundImage, setBackgroundImage] = useState(() => getRandomBackgroundImage());
  const [nextBackgroundImage, setNextBackgroundImage] = useState('');

  const backgroundImageStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    position: 'relative',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
  };

  const onNavigate = (route) => {
    console.log('onNavigate called with route:', route);
    navigate(route);

    // // console.log('onNavigate called');
    // console.log('onNavigate called with route:', route);
    // // console.log(`Old Background image set to: ${backgroundImage}`);
    setCurrentPage(route);
    // console.log('Current page after:', currentPage);
    setBackgroundImage(getRandomBackgroundImage()); // Update background image on navigation
    // console.log(`New Background image set to: ${backgroundImage}`);
  };

  const navigate = useNavigate();

  const preloadImage = (url) => {
    // console.log(`Preloading: ${url}`);
    const img = new Image();
    img.src = url;
    img.onload = () => console.log(`Image preloaded: ${url}`);
    img.onerror = () => console.log(`Error preloading: ${url}`);
  };
  

  const preloadNextBackground = useCallback(() => {
    // console.log('preloadNextBackground called');

    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(() => {
        const nextBg = getRandomBackgroundImage();
        preloadImage(nextBg);
        setNextBackgroundImage(nextBg);
        console.log(`nextBackgroundImage set to: ${nextBg}`);
      });
    } else {
      const nextBg = getRandomBackgroundImage();
      preloadImage(nextBg);
      setNextBackgroundImage(nextBg);
    }
  }, []);

  useEffect(() => {
    // console.log('useEffect triggered to set BackgroundImage'); // Debug line
    // console.log(`Background image set to: ${backgroundImage}`);
    console.log(`currentPage: ${currentPage}, nextBackgroundImage: ${nextBackgroundImage}`); // Debug line
    // console.log('currentPage changed:', currentPage);

    // Set the current background to the preloaded one only if nextBackgroundImage is not empty
    if (nextBackgroundImage) {
      setBackgroundImage(nextBackgroundImage);
    }
  
    // Preload the subsequent image for the next route
    preloadNextBackground();
    // console.log('backgroundImage after set:', backgroundImage);
    // console.log('nextbackgroundImage after set:', nextBackgroundImage);

  }, [currentPage]);

  // useEffect(() => {
  //   const token = localStorage.getItem('authToken');
  //   // console.log('Token from localStorage:', token);
  //   // console.log('isLoggedIn before:', isLoggedIn);
  //   if (token || isLoggedIn) {
  //     setIsLoggedIn(true);
  
  //     if (location.pathname === '/auth') {
  //       navigate("/happypath");
  //     }
  //   } else {
  //     // If the user is not logged in and doesn't have a token, redirect to /auth
  //     if (location.pathname !== '/signup') {
  //       navigate("/auth");
  //     }
  //   }
  //   // console.log('isLoggedIn after:', isLoggedIn);

  // }, [location.pathname, navigate]);
  
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    let isTokenValid = false;
  
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // in seconds
      if (decodedToken.exp > currentTime) {
        isTokenValid = true;
      } else {
        localStorage.removeItem('authToken'); // Remove expired token
      }
    }
  
    if (isTokenValid || isLoggedIn) {
      setIsLoggedIn(true);
      // Only redirect from '/auth' to '/happypath'
      if (location.pathname === '/auth') {
        navigate('/trends');
      }
    } else {
      // Redirect to '/auth' only if not on '/signup'
      if (location.pathname !== '/signup') {
        navigate('/auth');
      }
    }
  }, [location.pathname, navigate]);
  
// useEffect(() => {
//   const token = localStorage.getItem('authToken');
//   let isTokenValid = false;

//   if (token) {
//     const decodedToken = jwtDecode(token);
//     const currentTime = Date.now() / 1000; // in seconds
//     if (decodedToken.exp > currentTime) {
//       isTokenValid = true;
//     } else {
//       localStorage.removeItem('authToken'); // Remove expired token
//     }
//   }

//   if (isTokenValid || isLoggedIn) {
//     setIsLoggedIn(true);
//     if (location.pathname === '/auth') {
//       navigate('/happypath');
//     }
//   } else {
//     if (location.pathname !== '/signup') {
//       navigate('/auth');
//     }
//   }
// }, [isLoggedIn, location.pathname, navigate]);

  useEffect(() => {
    return () => {
      console.log('App component unmounted');
    };
  }, []);

    // useEffect(() => {
    //   // Directly log what's in localStorage for Organizations
    //   const orgsRawData = localStorage.getItem('Organizations');
    //   console.log("Directly from localStorage:", orgsRawData);
      
    //   // Fetch and parse organizations from localStorage
    //   const orgsData = JSON.parse(orgsRawData || '[]');
    //   console.log("Parsed Organizations from localStorage:", orgsData);
      
    //   // Update state and log
    //   setOrgs(orgsData);
    //   console.log("After setOrgs: ", orgs);
      
    //   // Directly log what's in localStorage for ActiveOrgID
    //   const activeOrgRawData = localStorage.getItem('ActiveOrgID');
    //   console.log("Directly from localStorage:", activeOrgRawData);
    
    //   // Logic to set activeOrgID
    //   let initialActiveOrgID = activeOrgRawData;
    //   if (!initialActiveOrgID && orgsData.length > 0) {
    //     initialActiveOrgID = orgsData[0].OrgID;
    //     localStorage.setItem('ActiveOrgID', initialActiveOrgID);
    //   }
      
    //   // Update state and log
    //   setActiveOrgID(initialActiveOrgID);
    //   console.log("After setActiveOrgID: ", activeOrgID);
    // }, []);
    
    // // This runs only once when the component mounts
    // useEffect(() => {
    //   const orgsData = JSON.parse(localStorage.getItem('Organizations') || '[]');
    //   setOrgs(orgsData);
    // }, []);

    // // This runs whenever `orgs` change
    // useEffect(() => {
    //   if (orgs.length > 0 && !activeOrgID) {
    //     const firstOrgID = orgs[0].OrgID;
    //     localStorage.setItem('ActiveOrgID', firstOrgID);
    //     setActiveOrgID(firstOrgID);
    //   }
    // }, [orgs, activeOrgID]);

    const canAccessFeature = (allowedRoles) => {
      const userRole = localStorage.getItem('UserRole');
  
      // Define a role hierarchy
      const roleHierarchy = {
          superadmin: ["enduser", "superadmin"], // Superadmins can access everything
          enduser: ["enduser"],                 // Endusers can access only their own pages
      };
  
      // Get the roles the current user has access to
      const userAccessibleRoles = roleHierarchy[userRole] || [];
  
      // Check if the user has access to any of the allowed roles
      const access = allowedRoles.some((role) => userAccessibleRoles.includes(role));
      console.log(`Access to feature for ${userRole}: ${access}`);
      return access;
  };
  
    // const canAccessFeature = (allowedRoles) => {
    //   const userRole = localStorage.getItem('UserRole');
    //   const access = allowedRoles.includes(userRole);
    //   console.log(`Access to feature for ${userRole}: ${access}`);
    //   return access;
    // };
    

return (
    <div className="relative min-h-screen scroll" style={backgroundImageStyle}>
      <div className="outerwrapper">
    <div className="flex-container">
    {/* orgs={orgs} activeOrgID={activeOrgID} handleOrgChange={handleOrgChange} */}
        {isLoggedIn && <Sidebar canAccessFeature={canAccessFeature} className="sidebar" pages={pages} onNavigate={onNavigate}/>}
        <ResponsiveWrapper>
        <div className="innerwrapper">
        {isLoggedIn ? (
            <>
                {console.log('Is user logged in:', isLoggedIn)}
                {console.log('Rendering logged-in components')}
                    <SEO title={title} description={description} url={url} />
                    <Routes>
                    {console.log('Rendering defined routes')}
                        <Route path="/auth" element={<Auth />} nonce={window.NONCE} />
                        <Route path="/trends" element={<Trends />} />
                        <Route path="/benchmarks" element={<Benchmarks />} />
                        <Route path="/funnel" element={<Funnel />} />
                        <Route path="/happypath" element={<HappyPath />} />
                        <Route path="/userinspector" element={<UserInspector />} />
                        <Route path="/conversations" element={<Conversations />} />
                        <Route path="/treeexplorer" element={<TreeExplorer />} />
                        <Route path="/pathanalyzer" element={<PathAnalyzer />} />
                        <Route path="/featureinsights" element={<FeatureInsights />} />
                        <Route path="/journeyflow" element={<JourneyFlow />} />
                        {/* <Route path="/sankey" element={<SankeyDiagram />} /> */}
                        <Route path="/peaks" element={<Peaks />} />
                        <Route path="/modeloutput" element={<ModelOutput />} />
                        <Route path="/admin" element={<Admin />} />
                    {canAccessFeature(['superadmin']) && (
                        <Route path="/onboarding" element={<Onboarding />} />
                        )}
                        <Route path="*" element={<Navigate to="/trends" />} />
                    </Routes>
            </>
        ) : (
            <>
                {console.log('Is user logged in:', isLoggedIn)}
                {console.log('Rendering logged-out components')}
                {/* <ResponsiveWrapper> */}
                    <Routes>
                        {console.log('Rendering /auth')}
                        <Route path="/signup" element={<SignUp />} nonce={window.NONCE} />
                        <Route path="/auth" element={<Auth />} nonce={window.NONCE} />
                        {console.log('Rendering *')}
                        {/* <Route path="*" element={<Navigate to="/auth" />} /> */}
                    </Routes>
                {/* </ResponsiveWrapper> */}
            </>
        )}
        </div>
        </ResponsiveWrapper>

      </div>          
      </div> 
    </div>
  );
 });

// const App = () => {
  
//   return (
//     // <StrictMode>
//     <ErrorBoundary>
//     <Provider store={store}>
//     <QueryProvider>
//     <MantineProvider theme={{ /* your theme settings */ }}>
//     {/* <AuthProvider> */}
//     <Router>
//     {/* <Router onStateChange={({ location, action }) => console.log(action, location)}> */}
//         <Content />
//         </Router>
//     {/* </AuthProvider> */}
//     </MantineProvider>
//     </QueryProvider>
//     </Provider>
//     </ErrorBoundary>
//     // </StrictMode>
//   );
// };

// export default App;


const App = () => {
  const { width } = useDebouncedResize(300); // Adjust the delay as needed
  const [resizeKey, setResizeKey] = useState(0);

  useEffect(() => {
    // Force a re-render by updating the key
    setResizeKey(prevKey => prevKey + 1);
  }, [width]);

  return (
    <ErrorBoundary>
    <Provider store={store}>
    {/* <MantineProvider theme={mantineTheme}> */}
    <QueryProvider>
    <MantineProvider>
    <Router>
            
            {/* <Content resizeKey={resizeKey} /> */}
            <Routes>
                <Route path="*" element={<Content resizeKey={resizeKey} />} />
            </Routes>
          </Router>
    </MantineProvider>
    </QueryProvider>
    </Provider>
    </ErrorBoundary>
  );
};

export default App;
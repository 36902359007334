import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

// const Sidebar = ({ pages, orgs, activeOrgID, handleOrgChange, onNavigate, canAccessFeature }) => {
//     const navigate = useNavigate(); // Hook to manage redirection
//     const location = useLocation(); // Get the current route
//     const isFirstRender = useRef(true); // To track the first render
//     const [isLoggedIn, setIsLoggedIn] = useState(true); // State to manage login status
//     const [openCategory, setOpenCategory] = useState("Insights"); // Default to "Insights"

//     // Define category order
//     const categoryOrder = ["Insights", "Exploration", "Validation"];

//     // Exclude /auth and /signup from the nav
//     const filteredPages = pages.filter(
//         (page) => page.route !== "/auth" && page.route !== "/signup"
//     );

//     // // Group pages by category, excluding Admin
//     // const groupedPages = filteredPages.reduce(
//     //     (acc, page) => {
//     //         if (page.category === "Admin") {
//     //             acc.admin.push(page); // Separate Admin routes
//     //         } else if (categoryOrder.includes(page.category)) {
//     //             acc.categories[page.category] = acc.categories[page.category] || [];
//     //             acc.categories[page.category].push(page);
//     //         }
//     //         return acc;
//     //     },
//     //     { categories: {}, admin: [] }
//     // );

//         // Determine and set the active category on initial render
//         useEffect(() => {
//           if (isFirstRender.current) {
//               const currentRoute = location.pathname;
//               const activePage = filteredPages.find((page) => page.route === currentRoute);
//               if (activePage) {
//                   setOpenCategory(activePage.category || "Insights");
//               }
//               isFirstRender.current = false; // Prevent further updates on toggle
//           }
//       }, [location.pathname, filteredPages]);

//     const handleCategoryToggle = (category) => {
//         setOpenCategory((prev) => (prev === category ? null : category));
//     };

//     const handleLogout = () => {
//         localStorage.removeItem('authToken'); // Remove the authToken
//         sessionStorage.removeItem('sessionId'); // Remove the sessionId
//         setIsLoggedIn(false); // Set isLoggedIn to false
//         console.log('Logged Out');

//         navigate('/auth'); // Redirect to /auth
//     };

//     // const canAccessFeature = (requiredRoles) => {
//     //     const userRoles = getUserRoles(); // Function to retrieve the user's roles
//     //     return requiredRoles.some(role => userRoles.includes(role));
//     // };

//     // // Group pages by category, excluding Admin, and apply filtering upfront
//     // const groupedPages = filteredPages
//     // .filter((page) => {
//     //     const requiredRoles = roleRequirements[page.route] || ["enduser"];
//     //     const isAccessible = canAccessFeature(requiredRoles);

//     //     console.log(
//     //         `Filtering Page: ${page.route}, Required Roles: ${requiredRoles}, Accessible: ${isAccessible}`
//     //     );

//     //     return isAccessible; // Only include pages the user can access
//     // })
//     // .reduce(
//     //     (acc, page) => {
//     //         if (page.category === "Admin") {
//     //             acc.admin.push(page); // Separate Admin routes
//     //         } else if (categoryOrder.includes(page.category)) {
//     //             acc.categories[page.category] = acc.categories[page.category] || [];
//     //             acc.categories[page.category].push(page);
//     //         }
//     //         return acc;
//     //     },
//     //     { categories: {}, admin: [] }
//     // );
    
//     const groupedPages = filteredPages.reduce(
//         (acc, page) => {
//             const requiredRoles = roleRequirements[page.route] || ["enduser"];
//             const isAccessible = canAccessFeature(requiredRoles);
    
//             console.log(
//                 `Processing Page: ${page.route}, Required Roles: ${requiredRoles}, Accessible: ${isAccessible}`
//             );
    
//             if (!isAccessible) return acc; // Skip inaccessible pages
    
//             if (page.category === "Admin") {
//                 acc.admin.push(page); // Separate Admin routes
//             } else if (categoryOrder.includes(page.category)) {
//                 acc.categories[page.category] = acc.categories[page.category] || [];
//                 acc.categories[page.category].push(page);
//             }
    
//             return acc;
//         },
//         { categories: {}, admin: [] }
//     );


//     const roleRequirements = {
//         "/onboarding": ["superadmin"],
//     };

    
//     return (
//         <div className="sidebar-container">
//             <div className="sidebar-logo">
//                 <img src="/logo-Teraace-250.png" alt="logo" />
//             </div>
//             {/* Render categories in defined order */}
//             {/* {categoryOrder.map((category, index) => (
//                 <div key={index} className="sidebar-category">
//                     <button
//                         className="category-toggle"
//                         onClick={() => handleCategoryToggle(category)}
//                     >
//                         {category}
//                     </button>
//                     <AnimatePresence initial={false}>
//                         {openCategory === category && (
//                             <motion.div
//                             initial={{ height: 0 }}
//                             animate={{ height: "auto" }}
//                             exit={{ height: 0 }}
//                             transition={{ duration: 0.3, ease: "easeInOut" }}
//                             className="sidebar-category-content"
//                             style={{ overflow: "hidden" }}
//                         >
//                                 {groupedPages.categories[category]
//                                     // .filter(
//                                     //     (page) =>
//                                     //         !roleRequirements[page.route] ||
//                                     //         canAccessFeature(roleRequirements[page.route])
//                                     // )

//                                     .filter((page) => {
//                                         const requiredRoles = roleRequirements[page.route] || ["enduser"];
//                                         const isAccessible = canAccessFeature(requiredRoles); // Check access
                                        
//                                         console.log(
//                                             `Filtering Page: ${page.route}, Required Roles: ${requiredRoles}, Accessible: ${isAccessible}`
//                                         );
                                    
//                                         return isAccessible;
//                                     })
//                                     .map((page, idx) => (
//                                         <NavLink
//                                             key={idx}
//                                             to={page.route}
//                                             className="sidebar-button"
//                                             activeClassName="filter-active"
//                                             onClick={() => onNavigate(page.route.replace('/', ''))}
//                                         >
//                                             {page.name}
//                                         </NavLink>
//                                     ))}
//                             </motion.div>
//                         )}
//                     </AnimatePresence>
//                 </div>
//             ))} */}
//             {categoryOrder.map((category, index) => (
//                 <div key={index} className="sidebar-category">
//                     <button
//                         className="category-toggle"
//                         onClick={() => handleCategoryToggle(category)}
//                     >
//                         {category}
//                     </button>
//                     <AnimatePresence initial={false}>
//                         {openCategory === category && (
//                             <motion.div
//                                 initial={{ height: 0 }}
//                                 animate={{ height: "auto" }}
//                                 exit={{ height: 0 }}
//                                 transition={{ duration: 0.3, ease: "easeInOut" }}
//                                 className="sidebar-category-content"
//                                 style={{ overflow: "hidden" }}
//                             >
//                                 {groupedPages.categories[category]?.map((page, idx) => (
//                                     <NavLink
//                                         key={idx}
//                                         to={page.route}
//                                         className="sidebar-button"
//                                         activeClassName="filter-active"
//                                         onClick={() => onNavigate(page.route.replace("/", ""))}
//                                     >
//                                         {page.name}
//                                     </NavLink>
//                                 ))}
//                             </motion.div>
//                         )}
//                     </AnimatePresence>
//                 </div>
//             ))}
//             {/* Render Admin routes below categories */}
//             {groupedPages.admin.map((page, idx) => (
//                 <NavLink
//                     key={idx}
//                     to={page.route}
//                     className="sidebar-button"
//                     activeClassName="filter-active"
//                     onClick={() => onNavigate(page.route.replace('/', ''))}
//                 >
//                     {page.name}
//                 </NavLink>
//             ))}
//             <button className="auth-logout-btn" onClick={handleLogout}>
//                 Logout
//             </button>
//         </div>
//     );
// };
const Sidebar = ({ pages, orgs, activeOrgID, handleOrgChange, onNavigate, canAccessFeature }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isFirstRender = useRef(true);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [openCategory, setOpenCategory] = useState("Insights");

    const categoryOrder = ["Insights", "Exploration", "Validation"];

    const roleRequirements = {
        "/onboarding": ["superadmin"],
    };

    const filteredPages = pages.filter(
        (page) => page.route !== "/auth" && page.route !== "/signup"
    );

    useEffect(() => {
        if (isFirstRender.current) {
            const currentRoute = location.pathname;
            const activePage = filteredPages.find((page) => page.route === currentRoute);
            if (activePage) {
                setOpenCategory(activePage.category || "Insights");
            }
            isFirstRender.current = false;
        }
    }, [location.pathname, filteredPages]);

    const handleCategoryToggle = (category) => {
        setOpenCategory((prev) => (prev === category ? null : category));
    };

    const handleLogout = () => {
        localStorage.removeItem("authToken");
        sessionStorage.removeItem("sessionId");
        setIsLoggedIn(false);
        navigate("/auth");
    };

    const groupedPages = filteredPages.reduce(
        (acc, page) => {
            const requiredRoles = roleRequirements[page.route] || ["enduser"];
            const isAccessible = canAccessFeature(requiredRoles);

            console.log(
                `Processing Page: ${page.route}, Required Roles: ${requiredRoles}, Accessible: ${isAccessible}`
            );

            if (!isAccessible) return acc;

            if (page.category === "Admin") {
                acc.admin.push(page);
            } else if (categoryOrder.includes(page.category)) {
                acc.categories[page.category] = acc.categories[page.category] || [];
                acc.categories[page.category].push(page);
            }

            return acc;
        },
        { categories: {}, admin: [] }
    );

    return (
        <div className="sidebar-container">
            <div className="sidebar-logo">
                <img src="/logo-Teraace-250.png" alt="logo" />
            </div>
            {categoryOrder.map((category, index) => (
                <div key={index} className="sidebar-category">
                    <button
                        className="category-toggle"
                        onClick={() => handleCategoryToggle(category)}
                    >
                        {category}
                    </button>
                    <AnimatePresence initial={false}>
                        {openCategory === category && (
                            <motion.div
                                initial={{ height: 0 }}
                                animate={{ height: "auto" }}
                                exit={{ height: 0 }}
                                transition={{ duration: 0.3, ease: "easeInOut" }}
                                className="sidebar-category-content"
                                style={{ overflow: "hidden" }}
                            >
                                {groupedPages.categories[category]?.map((page, idx) => (
                                    <NavLink
                                        key={idx}
                                        to={page.route}
                                        className="sidebar-button"
                                        activeClassName="filter-active"
                                        onClick={() => onNavigate(page.route.replace("/", ""))}
                                    >
                                        {page.name}
                                    </NavLink>
                                ))}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            ))}
            {groupedPages.admin.map((page, idx) => (
                <NavLink
                    key={idx}
                    to={page.route}
                    className="sidebar-button"
                    activeClassName="filter-active"
                    onClick={() => onNavigate(page.route.replace("/", ""))}
                >
                    {page.name}
                </NavLink>
            ))}
            <button className="auth-logout-btn" onClick={handleLogout}>
                Logout
            </button>
        </div>
    );
};

export default Sidebar;


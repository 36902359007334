import React, { useEffect, useState, useContext, useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { motion } from 'framer-motion';
import { Card, Title, SegmentedControl } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import LoadingSpinner from '../components/LoadingSpinner';
import {
  format,
  isWithinInterval,
  parseISO,
  startOfWeek,
  startOfMonth,
  isValid,
} from 'date-fns';
import { askOracle } from '../components/AskOracle';
import { DimensionContext } from '../components/ResponsiveWrapper';

const WeekOverWeekAnalysis = ({ orgID }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [isWeekly, setIsWeekly] = useState(true);

  const { width, height } = useContext(DimensionContext); // Responsive dimensions if needed

  const customColors = [
    '#ebb844', '#e08a3c', '#de6736', '#c5316a', '#762861',
    '#2c294b', '#3b3484', '#7174b0', '#7174b0', '#7174b0',
  ];

  const NivoTheme = {
    axis: {
      ticks: { text: { fontFamily: 'Manjari, sans-serif', fontSize: '12px' } },
      legend: { text: { fontFamily: 'Manjari, sans-serif', fontSize: '18px' } },
    },
    legends: { text: { fontFamily: 'Manjari, sans-serif' } },
    labels: { text: { fontFamily: 'Manjari, sans-serif' } },
    tooltip: { container: { fontFamily: 'Manjari, sans-serif' } },
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await askOracle('by-page', { params: { orgID } });
        const chunkSize = 1000;
        const validData = [];
        for (let i = 0; i < response.length; i += chunkSize) {
          const chunk = response.slice(i, i + chunkSize).filter((entry) => {
            return (
              entry.Timestamp &&
              isValid(parseISO(entry.Timestamp)) &&
              entry.URL &&
              entry.URL.trim() !== '' &&
              entry.HappyID &&
              entry.HappyID.trim() !== '' &&
              Array.isArray(entry.EventDescList) &&
              entry.EventDescList.length > 0 &&
              entry.EventDescList.every((event) => event)
            );
          });
          validData.push(...chunk);
        }
        setData(validData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [orgID]);

  // Helper functions
  const safeNumber = (value, defaultValue = 0) => (
    value === undefined || value === null || value === '' || isNaN(Number(value))
      ? defaultValue || 0.0001
      : Number(value)
  );

  const cleanData = (arr) => arr.filter((d) => (
    d && Object.values(d).every((v) =>
      v !== undefined && v !== null && (typeof v !== 'number' || !isNaN(v))
    )
  ));

  const getTimePeriodKey = (timestamp) => (
    isWeekly
      ? format(startOfWeek(parseISO(timestamp)), 'yyyy-MM-dd')
      : format(startOfMonth(parseISO(timestamp)), 'yyyy-MM')
  );

  const formatDuration = (seconds) => (
    `${Math.floor(seconds / 60)}m ${Math.round(seconds % 60)}s`
  );

  // Unconditional useMemo hooks for all computed data
  const filteredData = useMemo(() => {
    const chunkSize = 1000;
    const finalData = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      const chunk = data.slice(i, i + chunkSize).filter((entry) => (
        dateRange[0] &&
        dateRange[1] &&
        isValid(dateRange[0]) &&
        isValid(dateRange[1])
          ? isWithinInterval(parseISO(entry.Timestamp), {
            start: dateRange[0],
            end: dateRange[1],
          })
          : true
      ));
      finalData.push(...chunk);
    }
    return finalData;
  }, [data, dateRange]);

  const pageVisitsData = useMemo(() => {
    const visits = filteredData.reduce((acc, entry) => {
      if (isValid(parseISO(entry.Timestamp))) {
        const period = getTimePeriodKey(entry.Timestamp);
        acc[period] = (acc[period] || 0) + 1;
      }
      return acc;
    }, {});

    return cleanData(Object.entries(visits).map(([period, count]) => ({
      period: period.replace(/-/g, '/'),
      count: safeNumber(count),
    }))
    .sort((a, b) => new Date(a.period) - new Date(b.period))); // Sort by chronological order

  }, [filteredData, isWeekly]);

  const averagePageDurationData = useMemo(() => {
    const durations = filteredData.reduce((acc, entry) => {
      if (entry.PageDuration && !isNaN(entry.PageDuration)) {
        if (!acc[entry.URL]) acc[entry.URL] = { total: 0, count: 0 };
        acc[entry.URL].total += entry.PageDuration;
        acc[entry.URL].count += 1;
      }
      return acc;
    }, {});
  
    return cleanData(
      Object.entries(durations)
        .map(([url, { total, count }]) => ({
          url: url || 'No Data',
          averageDurationNumeric: safeNumber(total / count),
          averageDuration: formatDuration(safeNumber(total / count)),
        }))
        .sort((a, b) => a.url.localeCompare(b.url)) // Sort alphabetically by URL
    );
  }, [filteredData]);
  

  const usersPerPeriodData = useMemo(() => {
    const users = filteredData.reduce((acc, entry) => {
      if (isValid(parseISO(entry.Timestamp))) {
        const period = getTimePeriodKey(entry.Timestamp);
        acc[period] = acc[period] || new Set();
        acc[period].add(entry.HappyID);
      }
      return acc;
    }, {});

    return cleanData(Object.entries(users).map(([period, setOfUsers]) => ({
      period: period.replace(/-/g, '/'),
      users: safeNumber(setOfUsers.size),
    }))
    .sort((a, b) => new Date(a.period) - new Date(b.period))); // Sort by chronological order

  }, [filteredData, isWeekly]);

  const sessionsPerPeriodData = useMemo(() => {
    const sessions = filteredData.reduce((acc, entry) => {
      if (isValid(parseISO(entry.Timestamp))) {
        const period = getTimePeriodKey(entry.Timestamp);
        acc[period] = acc[period] || new Set();
        acc[period].add(entry.SessionId);
      }
      return acc;
    }, {});

    return cleanData(Object.entries(sessions).map(([period, setOfSessions]) => ({
      period: period.replace(/-/g, '/'),
      sessions: safeNumber(setOfSessions.size),
    }))
    .sort((a, b) => new Date(a.period) - new Date(b.period))); // Sort by chronological order

  }, [filteredData, isWeekly]);

  const top10EventsData = useMemo(() => {
    const events = filteredData.reduce((acc, entry) => {
      if (Array.isArray(entry.EventDescList)) {
        entry.EventDescList.forEach((event) => {
          if (event) acc[event] = (acc[event] || 0) + 1;
        });
      }
      return acc;
    }, {});

    return cleanData(Object.entries(events).slice(0, 10).map(([event, count]) => ({
      event: event || 'N/A',
      count: safeNumber(count),
    }))
    .sort((a, b) => new Date(a.period) - new Date(b.period))); // Sort by chronological order

  }, [filteredData]);

  const pageVisitsPerPeriodData = useMemo(() => {
    const pagesPerPeriod = filteredData.reduce((acc, entry) => {
      if (isValid(parseISO(entry.Timestamp))) {
        const period = getTimePeriodKey(entry.Timestamp); // Ensure the timestamp is valid and parsed
        const page = entry.URL || 'Other';
        if (!acc[period]) acc[period] = {};
        acc[period][page] = (acc[period][page] || 0) + 1;
      }
      return acc;
    }, {});
  
    return cleanData(
      Object.entries(pagesPerPeriod).map(([period, pages]) => {
        const pageEntries = Object.entries(pages);
  
        const topPages = pageEntries
          .sort((a, b) => b[1] - a[1]) // Sort by count descending
          .slice(0, 5); // Take top 5 pages
  
        const otherCount = pageEntries
          .slice(5) // Remaining pages after top 5
          .reduce((sum, [, count]) => sum + count, 0); // Sum their counts
  
        const row = { period: period.replace(/-/g, '/') }; // Format period
        topPages.forEach(([page, count]) => {
          row[page] = count; // Add top pages to row
        });
  
        if (otherCount) row.Other = otherCount; // Add "Other" count if it exists
        return row;
      }).sort((a, b) => new Date(a.period) - new Date(b.period)) // Ensure rows are sorted by period
    );
  }, [filteredData, isWeekly]);
  

  const keys = useMemo(() => {
    const uniqueKeys = new Set();
    pageVisitsPerPeriodData.forEach((d) => {
      Object.keys(d).forEach((k) => {
        if (k !== 'period' && k) uniqueKeys.add(k);
      });
    });
    return Array.from(uniqueKeys);
  }, [pageVisitsPerPeriodData]);

  const colorMapping = useMemo(() => {
    const map = {};
    keys.forEach((key, i) => { map[key] = customColors[i % customColors.length]; });
    map.Other = '#CCCCCC';
    return map;
  }, [keys]);

  if (loading) return <LoadingSpinner />;


  // Logs to confirm data shape
  console.log('pageVisitsData:', pageVisitsData);
  console.log('pageVisitsPerPeriodData:', pageVisitsPerPeriodData);
  console.log('usersPerPeriodData:', usersPerPeriodData);
  console.log('sessionsPerPeriodData:', sessionsPerPeriodData);
  console.log('averagePageDurationData:', averagePageDurationData);
  console.log('top10EventsData:', top10EventsData);

  /**
   *  Render all charts
   */
  return (
    <div className="visualization-container">
      <div className="week-analysis-page">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h1 className="h1">{isWeekly ? 'Trends Week Over Week' : 'Trends Month Over Month'}</h1>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <SegmentedControl
              data={[
                { label: 'Weekly', value: 'weekly' },
                { label: 'Monthly', value: 'monthly' },
              ]}
              value={isWeekly ? 'weekly' : 'monthly'}
              onChange={(val) => setIsWeekly(val === 'weekly')}
              style={{ marginLeft: '1rem' }}
            />
            <DatePickerInput
              type="range"
              placeholder="Filter date range"
              value={dateRange}
              onChange={setDateRange}
              style={{ marginLeft: '2rem' }}
            />
            {dateRange[0] && dateRange[1] && (
              <button
                onClick={() => setDateRange([null, null])}
                className="form-button"
                style={{ marginLeft: '1rem', maxWidth: '200px' }}
              >
                Reset Dates
              </button>
            )}
          </div>
        </motion.div>

        <div className="overview-chart">
          {/* 1) Simple Bar: Page Visits */}
          <Card shadow="sm" padding="lg" className="my-4">
            <Title order={3}>
              {isWeekly ? 'Weekly Page Visits' : 'Monthly Page Visits'}
            </Title>
            <div style={{ height: 400 }}>
              <ResponsiveBar
                data={pageVisitsData}
                keys={['count']}
                indexBy="period"
                margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                padding={0.3}
                layout="vertical"
                colors={customColors[0]}
                axisBottom={{
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 40,
                  format: (d) => String(d),
                  tickRotation: 45,
                }}
                axisLeft={{
                  legend: 'Page Visits',
                  legendPosition: 'middle',
                  legendOffset: -60,
                }}
                enableLabel={false}
                animate={true}
                theme={NivoTheme}
                tooltip={({ value, indexValue }) => (
                  <div className="nivoTool">
                    {indexValue} count: <strong>{value}</strong>
                  </div>
                )}
              />
            </div>
          </Card>

          {/* 2) Stacked Bar: Weekly/Monthly Page Visits by Top Pages */}
          <Card shadow="sm" padding="lg" className="my-4">
            <Title order={3}>
              {isWeekly
                ? 'Weekly Page Visits by Top Pages'
                : 'Monthly Page Visits by Top Pages'}
            </Title>
            <div style={{ height: 400 }}>
              <ResponsiveBar
                data={pageVisitsPerPeriodData}
                keys={keys}
                indexBy="period"
                margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                padding={0.3}
                layout="vertical"
                colors={({ id }) => colorMapping[id] || '#000000'}
                theme={NivoTheme}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 45,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 40,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Page Visits',
                  legendPosition: 'middle',
                  legendOffset: -60,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
                ariaLabel="Page Visits by Top Pages"
                barAriaLabel={(e) => `${e.id}: ${e.formattedValue} in period: ${e.indexValue}`}
              />
            </div>
          </Card>

          {/* 3) Simple Bar: Number of Users per Period */}
          <Card shadow="sm" padding="lg" className="my-4">
            <Title order={3}>
              {isWeekly ? 'Number of Users per Week' : 'Number of Users per Month'}
            </Title>
            <div style={{ height: 400 }}>
              <ResponsiveBar
                data={usersPerPeriodData}
                keys={['users']}
                indexBy="period"
                margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                padding={0.3}
                layout="vertical"
                colors={customColors[2]}
                axisBottom={{
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 40,
                  format: (d) => String(d),
                  tickRotation: 45,
                }}
                axisLeft={{
                  legend: 'Number of Users',
                  legendPosition: 'middle',
                  legendOffset: -60,
                }}
                enableLabel={false}
                animate={true}
                theme={NivoTheme}
                tooltip={({ value, indexValue }) => (
                  <div className="nivoTool">
                    {indexValue} user count: <strong>{value}</strong>
                  </div>
                )}
              />
            </div>
          </Card>

          {/* 4) Simple Bar: Number of Sessions per Period */}
          <Card shadow="sm" padding="lg" className="my-4">
            <Title order={3}>
              {isWeekly
                ? 'Number of Sessions per Week'
                : 'Number of Sessions per Month'}
            </Title>
            <div style={{ height: 400 }}>
              <ResponsiveBar
                data={sessionsPerPeriodData}
                keys={['sessions']}
                indexBy="period"
                margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                padding={0.3}
                layout="vertical"
                colors={customColors[3]}
                axisBottom={{
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 40,
                  format: (d) => String(d),
                  tickRotation: 45,
                }}
                axisLeft={{
                  legend: 'Number of Sessions',
                  legendPosition: 'middle',
                  legendOffset: -60,
                }}
                enableLabel={false}
                animate={true}
                theme={NivoTheme}
                tooltip={({ value, indexValue }) => (
                  <div className="nivoTool">
                    {indexValue} session count: <strong>{value}</strong>
                  </div>
                )}
              />
            </div>
          </Card>

          {/* 5) Simple Bar: Average Page Duration */}
          <Card shadow="sm" padding="lg" className="my-4">
            <Title order={3}>Average Page Duration</Title>
            <div style={{ height: 400 }}>
              <ResponsiveBar
                data={averagePageDurationData}
                keys={['averageDurationNumeric']}
                indexBy="url"
                margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                padding={0.3}
                layout="vertical"
                colors={customColors[4]}
                axisBottom={{
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 40,
                  format: (d) => String(d),
                  tickRotation: 45,
                }}
                axisLeft={{
                  legend: 'Average Duration (seconds)',
                  legendPosition: 'middle',
                  legendOffset: -60,
                }}
                enableLabel={false}
                animate={true}
                theme={NivoTheme}
                tooltip={({ value, indexValue }) => (
                  <div className="nivoTool">
                    {indexValue} duration: <strong>{formatDuration(value)}</strong>
                  </div>
                )}
              />
            </div>
          </Card>

          {/* 6) Simple Bar: Top 10 Events by Count */}
          <Card shadow="sm" padding="lg" className="my-4">
            <Title order={3}>Top 10 Events by Count</Title>
            <div style={{ height: 400 }}>
              <ResponsiveBar
                data={top10EventsData}
                keys={['count']}
                indexBy="event"
                margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                padding={0.3}
                layout="vertical"
                colors={customColors[5]}
                axisBottom={{
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 40,
                  format: (d) => String(d),
                  tickRotation: 45,
                }}
                axisLeft={{
                  legend: 'Count',
                  legendPosition: 'middle',
                  legendOffset: -60,
                }}
                enableLabel={false}
                animate={true}
                theme={NivoTheme}
                tooltip={({ value, indexValue }) => (
                  <div className="nivoTool">
                    {indexValue} count: <strong>{value}</strong>
                  </div>
                )}
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default WeekOverWeekAnalysis;




// import React, { useEffect, useState, useContext, useMemo } from 'react';
// import { ResponsiveBar } from '@nivo/bar';
// import { motion } from 'framer-motion';
// import { Card, Title, SegmentedControl } from '@mantine/core';
// import { DatePickerInput } from '@mantine/dates';
// import LoadingSpinner from '../components/LoadingSpinner';
// import {
//   format,
//   isWithinInterval,
//   parseISO,
//   startOfWeek,
//   startOfMonth,
//   isValid,
// } from 'date-fns';
// import { askOracle } from '../components/AskOracle';
// import { DimensionContext } from '../components/ResponsiveWrapper';

// const WeekOverWeekAnalysis = ({ orgID }) => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [dateRange, setDateRange] = useState([null, null]);
//   const [isWeekly, setIsWeekly] = useState(true);

//   // We can use width and height if needed for responsive charts
//   const { width, height } = useContext(DimensionContext);

//   // Custom colors for the charts
//   const customColors = [
//     '#ebb844', // ltorange
//     '#e08a3c', // mdorange
//     '#de6736', // dkorange
//     '#c5316a', // magenta
//     '#762861', // cranberry
//     '#2c294b', // dkpurple
//     '#3b3484', // mdpurple
//     '#7174b0', // ltpurple
//     '#7174b0', // ltpurple
//     '#7174b0', // ltpurple
//     '#7174b0', // ltpurple
//     '#7174b0', // ltpurple
//   ];

//   // Nivo chart theming
//   const NivoTheme = {
//     axis: {
//       ticks: {
//         text: {
//           fontFamily: ['Manjari', 'sans-serif'],
//           fontSize: '12px',
//         },
//       },
//       legend: {
//         text: {
//           fontFamily: ['Manjari', 'sans-serif'],
//           fontSize: '18px',
//         },
//       },
//     },
//     legends: {
//       text: {
//         fontFamily: ['Manjari', 'sans-serif'],
//       },
//     },
//     labels: {
//       text: {
//         fontFamily: ['Manjari', 'sans-serif'],
//       },
//     },
//     tooltip: {
//       container: {
//         fontFamily: ['Manjari', 'sans-serif'],
//       },
//     },
//   };

//   // Fetch the data in chunks, filtering out invalid entries
//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       console.log('Sent by-page to askOracle with params:', { orgID });

//       try {
//         const response = await askOracle('by-page', { params: { orgID } });
//         console.log('Received response from askOracle:', response);

//         const chunkSize = 1000;
//         const validData = [];

//         for (let i = 0; i < response.length; i += chunkSize) {
//           const chunk = response.slice(i, i + chunkSize).filter((entry) => {
//             return (
//               entry.Timestamp &&
//               isValid(parseISO(entry.Timestamp)) &&
//               entry.URL &&
//               entry.URL.trim() !== '' &&
//               entry.HappyID &&
//               entry.HappyID.trim() !== '' &&
//               // If you need SessionId to be non-empty, add it back here:
//               // entry.SessionId && entry.SessionId.trim() !== '' &&
//               Array.isArray(entry.EventDescList) &&
//               entry.EventDescList.length > 0 &&
//               entry.EventDescList.every((event) => event)
//             );
//           });
//           validData.push(...chunk);
//         }

//         console.log('Filtered Data:', validData);
//         setData(validData);
//       } catch (err) {
//         console.error('Error fetching data:', err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [orgID]);

//   // Memoized date-range filtering, also chunk-based to handle large datasets
//   const filteredData = useMemo(() => {
//     if (
//       dateRange[0] &&
//       dateRange[1] &&
//       isValid(dateRange[0]) &&
//       isValid(dateRange[1])
//     ) {
//       const chunkSize = 1000;
//       const filtered = [];
//       for (let i = 0; i < data.length; i += chunkSize) {
//         const chunk = data.slice(i, i + chunkSize).filter((entry) =>
//           isWithinInterval(parseISO(entry.Timestamp), {
//             start: dateRange[0],
//             end: dateRange[1],
//           })
//         );
//         filtered.push(...chunk);
//       }
//       return filtered;
//     }
//     return data;
//   }, [data, dateRange]);

//   // Loading spinner
//   if (loading) return <LoadingSpinner />;

//   // Safe number conversion (avoids NaN issues)
//   const safeNumber = (value, defaultValue = 0, entry = null) => {
//     if (
//       value === undefined ||
//       value === null ||
//       value === '' ||
//       isNaN(Number(value))
//     ) {
//       return defaultValue === 0 ? 0.0001 : defaultValue;
//     }
//     return Number(value);
//   };

//   // Filter out any data rows with invalid fields
//   const cleanData = (arr) => {
//     return arr.filter(
//       (d) =>
//         d &&
//         Object.values(d).every(
//           (v) =>
//             v !== undefined &&
//             v !== null &&
//             (typeof v !== 'number' || !isNaN(v))
//         )
//     );
//   };

//   // Decide if we're grouping by week or by month
//   const getTimePeriodKey = (timestamp) => {
//     return isWeekly
//       ? format(startOfWeek(parseISO(timestamp)), 'yyyy-MM-dd')
//       : format(startOfMonth(parseISO(timestamp)), 'yyyy-MM');
//   };

//   // 1. Page Visits (simple bar)
//   const pageVisits = filteredData.reduce((acc, entry) => {
//     if (isValid(parseISO(entry.Timestamp))) {
//       const periodKey = getTimePeriodKey(entry.Timestamp);
//       acc[periodKey] = (acc[periodKey] || 0) + 1;
//     }
//     return acc;
//   }, {});

//   const pageVisitsData = cleanData(
//     Object.entries(pageVisits)
//       .sort(([a], [b]) => new Date(a) - new Date(b))
//       .map(([period, count]) => ({
//         period: `${period.replace(/-/g, '/')}`,
//         count: safeNumber(count, 0, { period, count }),
//       }))
//   );

//   // 2. Average Page Duration (simple bar)
//   const averagePageDuration = filteredData.reduce((acc, entry) => {
//     if (
//       entry.PageDuration !== undefined &&
//       entry.PageDuration !== null &&
//       !isNaN(entry.PageDuration)
//     ) {
//       if (!acc[entry.URL]) {
//         acc[entry.URL] = { totalDuration: 0, count: 0 };
//       }
//       acc[entry.URL].totalDuration += entry.PageDuration;
//       acc[entry.URL].count += 1;
//     }
//     return acc;
//   }, {});

//   // Format duration from seconds to m/s
//   const formatDuration = (durationInSeconds) => {
//     const minutes = Math.floor(durationInSeconds / 60);
//     const seconds = Math.round(durationInSeconds % 60);
//     return `${minutes}m ${seconds}s`;
//   };

//   const averagePageDurationData = cleanData(
//     Object.entries(averagePageDuration)
//       .map(([url, { totalDuration, count }]) => {
//         const avgDuration = count > 0 ? totalDuration / count : 0;
//         return {
//           url: url || 'No Data',
//           averageDurationNumeric: avgDuration,
//           averageDuration: formatDuration(
//             safeNumber(avgDuration, 0, { url, totalDuration, count })
//           ),
//         };
//       })
//       .sort((a, b) => b.averageDurationNumeric - a.averageDurationNumeric)
//   );

//   // 3. Users per Period (simple bar)
//   const usersPerPeriod = filteredData.reduce((acc, entry) => {
//     if (isValid(parseISO(entry.Timestamp))) {
//       const periodKey = getTimePeriodKey(entry.Timestamp);
//       acc[periodKey] = acc[periodKey] || new Set();
//       acc[periodKey].add(entry.HappyID);
//     }
//     return acc;
//   }, {});

//   const usersPerPeriodData = cleanData(
//     Object.entries(usersPerPeriod)
//       .sort(([a], [b]) => new Date(a) - new Date(b))
//       .map(([period, users]) => ({
//         period: `${period.replace(/-/g, '/')}`,
//         users: safeNumber(users.size, 0, { period, userCount: users.size }),
//       }))
//   );

//   // 4. Sessions per Period (simple bar)
//   const sessionsPerPeriod = filteredData.reduce((acc, entry) => {
//     if (isValid(parseISO(entry.Timestamp))) {
//       const periodKey = getTimePeriodKey(entry.Timestamp);
//       acc[periodKey] = acc[periodKey] || new Set();
//       acc[periodKey].add(entry.SessionId);
//     }
//     return acc;
//   }, {});

//   const sessionsPerPeriodData = cleanData(
//     Object.entries(sessionsPerPeriod)
//       .sort(([a], [b]) => new Date(a) - new Date(b))
//       .map(([period, sessions]) => ({
//         period: `${period.replace(/-/g, '/')}`,
//         sessions: safeNumber(sessions.size, 0, {
//           period,
//           sessionCount: sessions.size,
//         }),
//       }))
//   );

//   // 5. Top 10 Events (simple bar)
//   const eventDescCounts = filteredData.reduce((acc, entry) => {
//     if (Array.isArray(entry.EventDescList)) {
//       entry.EventDescList.forEach((event) => {
//         if (event) {
//           acc[event] = (acc[event] || 0) + 1;
//         }
//       });
//     }
//     return acc;
//   }, {});

//   const top10EventsData = cleanData(
//     Object.entries(eventDescCounts)
//       .sort((a, b) => b[1] - a[1])
//       .slice(0, 10)
//       .map(([event, count]) => ({
//         event: event || 'N/A',
//         count: safeNumber(count, 0, { event, count }),
//       }))
//   );

//   // 6. Top Pages per Period (stacked bar) with chunk-based approach
//   const getTopPagesPerPeriodData = (arr, weekly) => {
//     console.log('Filtered Data:', arr);

//     const chunkSize = 1000;
//     const pagesPerPeriod = {};

//     for (let i = 0; i < arr.length; i += chunkSize) {
//       const chunk = arr.slice(i, i + chunkSize);
//       chunk.forEach((entry) => {
//         if (isValid(parseISO(entry.Timestamp))) {
//           const periodKey = weekly
//             ? format(startOfWeek(parseISO(entry.Timestamp)), 'yyyy-MM-dd')
//             : format(startOfMonth(parseISO(entry.Timestamp)), 'yyyy-MM');

//           pagesPerPeriod[periodKey] = pagesPerPeriod[periodKey] || {};
//           const page = entry.URL || 'Other';
//           pagesPerPeriod[periodKey][page] =
//             (pagesPerPeriod[periodKey][page] || 0) + 1;
//         }
//       });
//     }

//     console.log('Pages per Period:', pagesPerPeriod);

//     const topPagesPerPeriodData = Object.entries(pagesPerPeriod).map(
//       ([period, pages]) => {
//         const sortedPages = Object.entries(pages).sort((a, b) => b[1] - a[1]);
//         const topPages = sortedPages.slice(0, 5);
//         const otherCount = sortedPages
//           .slice(5)
//           .reduce((sum, [, count]) => sum + count, 0);

//         const row = { period };
//         topPages.forEach(([page, count]) => {
//           row[page] = count;
//         });
//         if (otherCount > 0) {
//           row['Other'] = otherCount;
//         }
//         return row;
//       }
//     );

//     topPagesPerPeriodData.sort((a, b) => new Date(a.period) - new Date(b.period));
//     console.log('Top Pages per Period Data after sorting:', topPagesPerPeriodData);

//     return cleanData(topPagesPerPeriodData);
//   };

//   // Generate stacked bar data for top pages
//   const pageVisitsPerPeriodData = useMemo(() => {
//     return getTopPagesPerPeriodData(filteredData, isWeekly);
//   }, [filteredData, isWeekly]);

//   // Extract all possible keys from stacked bar data
//   const keys = useMemo(() => {
//     const uniqueKeys = new Set();
//     pageVisitsPerPeriodData.forEach((d) => {
//       Object.keys(d).forEach((k) => {
//         if (k !== 'period' && k !== null && k !== '') {
//           uniqueKeys.add(k);
//         }
//       });
//     });
//     return Array.from(uniqueKeys);
//   }, [pageVisitsPerPeriodData]);

//   // Build a color mapping for each key
//   const colorMapping = useMemo(() => {
//     const mapping = {};
//     keys.forEach((key, index) => {
//       mapping[key] = customColors[index % customColors.length];
//     });
//     // Ensure 'Other' has a default color
//     mapping['Other'] = '#CCCCCC';
//     return mapping;
//   }, [keys]);

//   console.log("pageVisitsData:", pageVisitsData);
// console.log("pageVisitsPerPeriodData:", pageVisitsPerPeriodData);
// console.log("usersPerPeriodData:", usersPerPeriodData);
// console.log("sessionsPerPeriodData:", sessionsPerPeriodData);
// console.log("averagePageDurationData:", averagePageDurationData);
// console.log("top10EventsData:", top10EventsData);

//   // Now render all charts
//   return (
//     <div className="visualization-container">
//       <div className="week-analysis-page">
//         <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
//           <h1 className="h1">{isWeekly ? 'Trends Week Over Week' : 'Trends Month Over Month'}</h1>
//           <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
//             <SegmentedControl
//               data={[
//                 { label: 'Weekly', value: 'weekly' },
//                 { label: 'Monthly', value: 'monthly' },
//               ]}
//               value={isWeekly ? 'weekly' : 'monthly'}
//               onChange={(value) => setIsWeekly(value === 'weekly')}
//               style={{ marginLeft: '1rem' }}
//             />
//             <DatePickerInput
//               type="range"
//               placeholder="Filter date range"
//               value={dateRange}
//               onChange={setDateRange}
//               style={{ marginLeft: '2rem' }}
//             />
//             {/* Reset Button */}
//             {dateRange[0] && dateRange[1] && (
//               <button
//                 onClick={() => setDateRange([null, null])}
//                 className="form-button"
//                 style={{ marginLeft: '1rem', maxWidth: '200px' }}
//               >
//                 Reset Dates
//               </button>
//             )}
//           </div>
//         </motion.div>

//         <div className="overview-chart">
//           {/* 1. Weekly/Monthly Page Visits (Simple Bar) */}
//           <Card shadow="sm" padding="lg" className="my-4">
//             <Title order={3}>
//               {isWeekly ? 'Weekly Page Visits' : 'Monthly Page Visits'}
//             </Title>
//             <div style={{ height: 400 }}>
//               <ResponsiveBar
//                 data={pageVisitsData}
//                 keys={['count']}
//                 indexBy="period"
//                 indexScale={{ type: 'band', round: true }}
//                 valueScale={{ type: 'linear' }}
//                 margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
//                 padding={0.3}
//                 layout="vertical"
//                 colors={customColors[0]}
//                 axisBottom={{
//                   legend: '',
//                   legendPosition: 'middle',
//                   legendOffset: 40,
//                   format: (d) => String(d),
//                   tickRotation: 45,
//                 }}
//                 axisLeft={{
//                   legend: 'Page Visits',
//                   legendPosition: 'middle',
//                   legendOffset: -60,
//                 }}
//                 enableLabel={false}
//                 animate={true}
//                 theme={NivoTheme}
//                 tooltip={({ value, indexValue }) => (
//                   <div className="nivoTool">
//                     {indexValue} count: <strong>{value}</strong>
//                   </div>
//                 )}
//               />
//             </div>
//           </Card>

//           {/* 2. Stacked Bar for Top Pages per Period */}
//           <Card shadow="sm" padding="lg" className="my-4">
//             <Title order={3}>
//               {isWeekly
//                 ? 'Weekly Page Visits by Top Pages'
//                 : 'Monthly Page Visits by Top Pages'}
//             </Title>
//             <div style={{ height: 400 }}>
//               <ResponsiveBar
//                 data={pageVisitsPerPeriodData}
//                 keys={keys}
//                 indexBy="period"
//                 margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
//                 padding={0.3}
//                 layout="vertical"
//                 colors={({ id }) => {
//                   // Defensive check for an undefined id
//                   if (!id) {
//                     console.error('Encountered a null or undefined id in stacked bar');
//                     return '#000000'; // fallback color
//                   }
//                   return colorMapping[id] || '#000000';
//                 }}
//                 theme={NivoTheme}
//                 axisBottom={{
//                   tickSize: 5,
//                   tickPadding: 5,
//                   tickRotation: 45,
//                   legend: '',
//                   legendPosition: 'middle',
//                   legendOffset: 40,
//                 }}
//                 axisLeft={{
//                   tickSize: 5,
//                   tickPadding: 5,
//                   tickRotation: 0,
//                   legend: 'Page Visits',
//                   legendPosition: 'middle',
//                   legendOffset: -60,
//                 }}
//                 labelSkipWidth={12}
//                 labelSkipHeight={12}
//                 labelTextColor={{
//                   from: 'color',
//                   modifiers: [['darker', 1.6]],
//                 }}
//                 legends={[
//                   {
//                     dataFrom: 'keys',
//                     anchor: 'bottom-right',
//                     direction: 'column',
//                     justify: false,
//                     translateX: 120,
//                     translateY: 0,
//                     itemsSpacing: 2,
//                     itemWidth: 100,
//                     itemHeight: 20,
//                     itemDirection: 'left-to-right',
//                     itemOpacity: 0.85,
//                     symbolSize: 20,
//                     effects: [
//                       {
//                         on: 'hover',
//                         style: {
//                           itemOpacity: 1,
//                         },
//                       },
//                     ],
//                   },
//                 ]}
//                 role="application"
//                 ariaLabel="Page Visits by Top Pages"
//                 barAriaLabel={(e) =>
//                   `${e.id}: ${e.formattedValue} in period: ${e.indexValue}`
//                 }
//               />
//             </div>
//           </Card>

//           {/* 3. Number of Users per Period */}
//           <Card shadow="sm" padding="lg" className="my-4">
//             <Title order={3}>
//               {isWeekly ? 'Number of Users per Week' : 'Number of Users per Month'}
//             </Title>
//             <div style={{ height: 400 }}>
//               <ResponsiveBar
//                 data={usersPerPeriodData}
//                 keys={['users']}
//                 indexBy="period"
//                 indexScale={{ type: 'band', round: true }}
//                 valueScale={{ type: 'linear' }}
//                 margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
//                 padding={0.3}
//                 layout="vertical"
//                 colors={customColors[2]}
//                 axisBottom={{
//                   legend: '',
//                   legendPosition: 'middle',
//                   legendOffset: 40,
//                   format: (d) => String(d),
//                   tickRotation: 45,
//                 }}
//                 axisLeft={{
//                   legend: 'Number of Users',
//                   legendPosition: 'middle',
//                   legendOffset: -60,
//                 }}
//                 enableLabel={false}
//                 animate={true}
//                 theme={NivoTheme}
//                 tooltip={({ value, indexValue }) => (
//                   <div className="nivoTool">
//                     {indexValue} user count: <strong>{value}</strong>
//                   </div>
//                 )}
//               />
//             </div>
//           </Card>

//           {/* 4. Number of Sessions per Period */}
//           <Card shadow="sm" padding="lg" className="my-4">
//             <Title order={3}>
//               {isWeekly
//                 ? 'Number of Sessions per Week'
//                 : 'Number of Sessions per Month'}
//             </Title>
//             <div style={{ height: 400 }}>
//               <ResponsiveBar
//                 data={sessionsPerPeriodData}
//                 keys={['sessions']}
//                 indexBy="period"
//                 indexScale={{ type: 'band', round: true }}
//                 valueScale={{ type: 'linear' }}
//                 margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
//                 padding={0.3}
//                 layout="vertical"
//                 colors={customColors[3]}
//                 axisBottom={{
//                   legend: '',
//                   legendPosition: 'middle',
//                   legendOffset: 40,
//                   format: (d) => String(d),
//                   tickRotation: 45,
//                 }}
//                 axisLeft={{
//                   legend: 'Number of Sessions',
//                   legendPosition: 'middle',
//                   legendOffset: -60,
//                 }}
//                 enableLabel={false}
//                 animate={true}
//                 theme={NivoTheme}
//                 tooltip={({ value, indexValue }) => (
//                   <div className="nivoTool">
//                     {indexValue} session count: <strong>{value}</strong>
//                   </div>
//                 )}
//               />
//             </div>
//           </Card>

//           {/* 5. Average Page Duration */}
//           <Card shadow="sm" padding="lg" className="my-4">
//             <Title order={3}>Average Page Duration</Title>
//             <div style={{ height: 400 }}>
//               <ResponsiveBar
//                 data={averagePageDurationData}
//                 keys={['averageDurationNumeric']}
//                 indexBy="url"
//                 indexScale={{ type: 'band', round: true }}
//                 valueScale={{ type: 'linear' }}
//                 margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
//                 padding={0.3}
//                 layout="vertical"
//                 colors={customColors[4]}
//                 axisBottom={{
//                   legend: '',
//                   legendPosition: 'middle',
//                   legendOffset: 40,
//                   format: (d) => String(d),
//                   tickRotation: 45,
//                 }}
//                 axisLeft={{
//                   legend: 'Average Duration (seconds)',
//                   legendPosition: 'middle',
//                   legendOffset: -60,
//                 }}
//                 tooltip={({ value, indexValue }) => (
//                   <div className="nivoTool">
//                     {indexValue} duration: <strong>{formatDuration(value)}</strong>
//                   </div>
//                 )}
//                 enableLabel={false}
//                 animate={true}
//                 theme={NivoTheme}
//               />
//             </div>
//           </Card>

//           {/* 6. Top 10 Events by Count */}
//           <Card shadow="sm" padding="lg" className="my-4">
//             <Title order={3}>Top 10 Events by Count</Title>
//             <div style={{ height: 400 }}>
//               <ResponsiveBar
//                 data={top10EventsData}
//                 keys={['count']}
//                 indexBy="event"
//                 indexScale={{ type: 'band', round: true }}
//                 valueScale={{ type: 'linear' }}
//                 margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
//                 padding={0.3}
//                 layout="vertical"
//                 colors={customColors[5]}
//                 axisBottom={{
//                   legend: '',
//                   legendPosition: 'middle',
//                   legendOffset: 40,
//                   format: (d) => String(d),
//                   tickRotation: 45,
//                 }}
//                 axisLeft={{
//                   legend: 'Count',
//                   legendPosition: 'middle',
//                   legendOffset: -60,
//                 }}
//                 enableLabel={false}
//                 animate={true}
//                 theme={NivoTheme}
//                 tooltip={({ value, indexValue }) => (
//                   <div className="nivoTool">
//                     {indexValue} count: <strong>{value}</strong>
//                   </div>
//                 )}
//               />
//             </div>
//           </Card>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default WeekOverWeekAnalysis;


// // import React, { useEffect, useState, useContext } from 'react';
// // import { ResponsiveBar } from '@nivo/bar';
// // import { motion } from 'framer-motion';
// // import { Card, Title, SegmentedControl } from '@mantine/core';
// // import { DatePickerInput } from '@mantine/dates';
// // import LoadingSpinner from '../components/LoadingSpinner';
// // import { format, isWithinInterval, parseISO, startOfWeek, startOfMonth, isValid } from 'date-fns';
// // import { askOracle } from '../components/AskOracle';
// // import { DimensionContext } from '../components/ResponsiveWrapper';

// // const WeekOverWeekAnalysis = ({ orgID }) => {
// //     const [data, setData] = useState([]);
// //     const [loading, setLoading] = useState(true);
// //     const [dateRange, setDateRange] = useState([null, null]);
// //     const [isWeekly, setIsWeekly] = useState(true);
// //     const { width, height } = useContext(DimensionContext);

// //     const customColors = [
// //         '#ebb844', '#e08a3c', '#de6736', '#c5316a',
// //         '#762861', '#2c294b', '#3b3484', '#7174b0',
// //     ];

// //     const NivoTheme = {
// //         axis: {
// //             ticks: { text: { fontFamily: ['Manjari', 'sans-serif'], fontSize: '12px' } },
// //             legend: { text: { fontFamily: ['Manjari', 'sans-serif'], fontSize: '18px' } },
// //         },
// //         legends: { text: { fontFamily: ['Manjari', 'sans-serif'] } },
// //         labels: { text: { fontFamily: ['Manjari', 'sans-serif'] } },
// //         tooltip: { container: { fontFamily: ['Manjari', 'sans-serif'] } },
// //     };

// //     useEffect(() => {
// //         const fetchData = async () => {
// //             setLoading(true);
// //             try {
// //                 const response = await askOracle('by-page', { params: { orgID } });
// //                 const chunkSize = 1000;
// //                 const validData = [];
// //                 for (let i = 0; i < response.length; i += chunkSize) {
// //                     const chunk = response.slice(i, i + chunkSize).filter(entry => (
// //                         entry.Timestamp &&
// //                         isValid(parseISO(entry.Timestamp)) &&
// //                         entry.URL &&
// //                         entry.URL.trim() !== '' &&
// //                         entry.HappyID &&
// //                         entry.HappyID.trim() !== '' &&
// //                         Array.isArray(entry.EventDescList) &&
// //                         entry.EventDescList.length > 0 &&
// //                         entry.EventDescList.every(event => event)
// //                     ));
// //                     validData.push(...chunk);
// //                 }
// //                 setData(validData);
// //             } catch (err) {
// //                 console.error("Error fetching data:", err);
// //             } finally {
// //                 setLoading(false);
// //             }
// //         };
// //         fetchData();
// //     }, [orgID]);

// //     const filteredData = React.useMemo(() => {
// //         if (dateRange[0] && dateRange[1] && isValid(dateRange[0]) && isValid(dateRange[1])) {
// //             const chunkSize = 1000;
// //             const filtered = [];
// //             for (let i = 0; i < data.length; i += chunkSize) {
// //                 const chunk = data.slice(i, i + chunkSize).filter(entry =>
// //                     isWithinInterval(parseISO(entry.Timestamp), {
// //                         start: dateRange[0],
// //                         end: dateRange[1],
// //                     })
// //                 );
// //                 filtered.push(...chunk);
// //             }
// //             return filtered;
// //         }
// //         return data;
// //     }, [data, dateRange]);

// //     const getTimePeriodKey = (timestamp) => {
// //         return isWeekly
// //             ? format(startOfWeek(parseISO(timestamp)), 'yyyy-MM-dd')
// //             : format(startOfMonth(parseISO(timestamp)), 'yyyy-MM');
// //     };

// //     const pageVisitsPerPeriodData = React.useMemo(() => {
// //         const pagesPerPeriod = filteredData.reduce((acc, entry) => {
// //             if (isValid(parseISO(entry.Timestamp))) {
// //                 const periodKey = getTimePeriodKey(entry.Timestamp);
// //                 acc[periodKey] = acc[periodKey] || {};
// //                 const page = entry.URL || 'Other';
// //                 acc[periodKey][page] = (acc[periodKey][page] || 0) + 1;
// //             }
// //             return acc;
// //         }, {});

// //         return Object.entries(pagesPerPeriod).map(([period, pages]) => {
// //             const sortedPages = Object.entries(pages).sort((a, b) => b[1] - a[1]);
// //             const topPages = sortedPages.slice(0, 5);
// //             const otherCount = sortedPages.slice(5).reduce((sum, [, count]) => sum + count, 0);

// //             const data = { period };
// //             topPages.forEach(([page, count]) => (data[page] = count));
// //             if (otherCount > 0) data['Other'] = otherCount;
// //             return data;
// //         }).sort((a, b) => new Date(a.period) - new Date(b.period));
// //     }, [filteredData, isWeekly]);

// //     const keys = React.useMemo(() => {
// //         const uniqueKeys = new Set();
// //         pageVisitsPerPeriodData.forEach(d => {
// //             Object.keys(d).forEach(key => {
// //                 if (key !== 'period' && key !== null && key !== '') {
// //                     uniqueKeys.add(key);
// //                 }
// //             });
// //         });
// //         return Array.from(uniqueKeys);
// //     }, [pageVisitsPerPeriodData]);

// //     const colorMapping = React.useMemo(() => {
// //         const mapping = {};
// //         keys.forEach((key, index) => {
// //             mapping[key] = customColors[index % customColors.length];
// //         });
// //         mapping['Other'] = '#CCCCCC';
// //         return mapping;
// //     }, [keys]);

// //     if (loading) return <LoadingSpinner />;

// //     return (
// //         <div className="visualization-container">
// //             <div className="week-analysis-page">
// //                 <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
// //                     <h1 className="h1">{isWeekly ? 'Trends Week Over Week' : 'Trends Month Over Month'}</h1>
// //                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
// //                         <SegmentedControl
// //                             data={[
// //                                 { label: 'Weekly', value: 'weekly' },
// //                                 { label: 'Monthly', value: 'monthly' }
// //                             ]}
// //                             value={isWeekly ? 'weekly' : 'monthly'}
// //                             onChange={(value) => setIsWeekly(value === 'weekly')}
// //                             style={{ marginLeft: '1rem' }}
// //                         />
// //                         <DatePickerInput
// //                             type="range"
// //                             placeholder="Filter date range"
// //                             value={dateRange}
// //                             onChange={setDateRange}
// //                             style={{ marginLeft: '2rem' }}
// //                         />
// //                         {dateRange[0] && dateRange[1] && (
// //                             <button
// //                                 onClick={() => setDateRange([null, null])}
// //                                 className="form-button"
// //                                 style={{ marginLeft: '1rem', maxWidth: '200px' }}
// //                             >
// //                                 Reset Dates
// //                             </button>
// //                         )}
// //                     </div>
// //                 </motion.div>

// //                 <Card shadow="sm" padding="lg" className="my-4">
// //                     <Title order={3}>{isWeekly ? 'Weekly Page Visits by Top Pages' : 'Monthly Page Visits by Top Pages'}</Title>
// //                     <div style={{ height: 400 }}>
// //                         <ResponsiveBar
// //                             data={pageVisitsPerPeriodData}
// //                             keys={keys}
// //                             indexBy="period"
// //                             margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
// //                             padding={0.3}
// //                             layout="vertical"
// //                             colors={({ id }) => colorMapping[id] || '#000000'}
// //                             theme={NivoTheme}
// //                             axisBottom={{
// //                                 tickSize: 5, tickPadding: 5, tickRotation: 45,
// //                                 legend: '', legendPosition: 'middle', legendOffset: 40
// //                             }}
// //                             axisLeft={{
// //                                 tickSize: 5, tickPadding: 5,
// //                                 legend: 'Page Visits', legendPosition: 'middle', legendOffset: -60
// //                             }}
// //                             role="application"
// //                             ariaLabel="Page Visits by Top Pages"
// //                         />
// //                     </div>
// //                 </Card>
// //             </div>
// //         </div>
// //     );
// // };

// // export default WeekOverWeekAnalysis;


// // // import React, { useEffect, useState, useContext } from 'react';
// // // import { ResponsiveBar } from '@nivo/bar';
// // // import { motion } from 'framer-motion';
// // // import { Card, Title, Text, SegmentedControl } from '@mantine/core';
// // // import { DatePickerInput } from '@mantine/dates';
// // // import LoadingSpinner from '../components/LoadingSpinner';
// // // import { format, isWithinInterval, parseISO, startOfWeek, startOfMonth, isValid } from 'date-fns';
// // // import { askOracle } from '../components/AskOracle';
// // // import { DimensionContext } from '../components/ResponsiveWrapper';

// // // const WeekOverWeekAnalysis = ({ orgID }) => {
// // //     const [data, setData] = useState([]);
// // //     const [loading, setLoading] = useState(true);
// // //     const [dateRange, setDateRange] = useState([null, null]);
// // //     const [isWeekly, setIsWeekly] = useState(true);
// // //     const { width, height } = useContext(DimensionContext);

// // //     const customColors = [
// // //         '#ebb844', // ltorange
// // //         '#e08a3c', // mdorange
// // //         '#de6736', // dkorange
// // //         '#c5316a',  // magenta
// // //         '#762861', // cranberry
// // //         '#2c294b', // dkpurple
// // //         '#3b3484', // mdpurple
// // //         '#7174b0', // ltpurple
// // //         '#7174b0', // ltpurple
// // //         '#7174b0', // ltpurple
// // //         '#7174b0', // ltpurple
// // //         '#7174b0' // ltpurple
// // //     ];

// // //     const NivoTheme = {
// // //         axis: {
// // //           ticks: {
// // //             text: {
// // //               fontFamily: ['Manjari', 'sans-serif'],
// // //               fontSize: '12px',
// // //             },
// // //           },
// // //           legend: {
// // //             text: {
// // //               fontFamily: ['Manjari', 'sans-serif'],
// // //               fontSize: '18px',
// // //             },
// // //           },
// // //         },
// // //         legends: {
// // //           text: {
// // //             fontFamily: ['Manjari', 'sans-serif'],
// // //           },
// // //         },
// // //         labels: {
// // //           text: {
// // //             fontFamily: ['Manjari', 'sans-serif'],
// // //           },
// // //         },
// // //         tooltip: {
// // //           container: {
// // //             fontFamily: ['Manjari', 'sans-serif'],
// // //           },
// // //         },
// // //       };

// // //     // useEffect(() => {
// // //     //     const fetchData = () => {
// // //     //         setLoading(true);
// // //     //         console.log("Sent by-page to askOracle with params:", { orgID });
    
// // //     //         askOracle('by-page', {
// // //     //             params: { orgID },
// // //     //         })
// // //     //             .then(response => {
// // //     //                 console.log("Received response from askOracle:", response);
    
// // //     //                 const validData = response.filter(entry => (
// // //     //                     entry.Timestamp && isValid(parseISO(entry.Timestamp)) &&
// // //     //                     entry.URL && entry.URL.trim() !== '' &&
// // //     //                     entry.HappyID && entry.HappyID.trim() !== '' &&
// // //     //                     entry.SessionId && entry.SessionId.trim() !== '' &&
// // //     //                     Array.isArray(entry.EventDescList) &&
// // //     //                     entry.EventDescList.length > 0 &&
// // //     //                     entry.EventDescList.every(event => event)
// // //     //                 ));
    
// // //     //                 setData(validData);
// // //     //             })
// // //     //             .catch(err => {
// // //     //                 console.error("Error fetching data:", err);
// // //     //             })
// // //     //             .finally(() => {
// // //     //                 setLoading(false);
// // //     //             });
// // //     //     };
// // //     //     fetchData();
// // //     // }, [orgID]); 

// // //     useEffect(() => {
// // //         const fetchData = async () => {
// // //             setLoading(true);
// // //             console.log("Sent by-page to askOracle with params:", { orgID });
    
// // //             try {
// // //                 const response = await askOracle('by-page', { params: { orgID } });
// // //                 console.log("Received response from askOracle:", response);
    
// // //                 const chunkSize = 1000; // Process data in chunks of 1000
// // //                 const validData = [];
// // //                 for (let i = 0; i < response.length; i += chunkSize) {
// // //                     const chunk = response.slice(i, i + chunkSize).filter(entry => (
// // //                         entry.Timestamp &&
// // //                         isValid(parseISO(entry.Timestamp)) &&
// // //                         entry.URL &&
// // //                         entry.URL.trim() !== '' &&
// // //                         entry.HappyID &&
// // //                         entry.HappyID.trim() !== '' &&
// // //                         Array.isArray(entry.EventDescList) &&
// // //                         entry.EventDescList.length > 0 &&
// // //                         entry.EventDescList.every(event => event)
// // //                     ));
// // //                     validData.push(...chunk);
// // //                 }
    
// // //                 console.log("Filtered Data:", validData);
// // //                 setData(validData);
// // //             } catch (err) {
// // //                 console.error("Error fetching data:", err);
// // //             } finally {
// // //                 setLoading(false);
// // //             }
// // //         };
    
// // //         fetchData();
// // //     }, [orgID]);
    
 
    
// // //     // const filteredData = dateRange[0] && dateRange[1] && isValid(dateRange[0]) && isValid(dateRange[1])
// // //     // ? data.filter(entry =>
// // //     //     isWithinInterval(parseISO(entry.Timestamp), {
// // //     //         start: dateRange[0],
// // //     //         end: dateRange[1],
// // //     //     })
// // //     // )
// // //     // : data;

// // //     const filteredData = React.useMemo(() => {
// // //         if (dateRange[0] && dateRange[1] && isValid(dateRange[0]) && isValid(dateRange[1])) {
// // //             const chunkSize = 1000;
// // //             const filtered = [];
// // //             for (let i = 0; i < data.length; i += chunkSize) {
// // //                 const chunk = data.slice(i, i + chunkSize).filter(entry =>
// // //                     isWithinInterval(parseISO(entry.Timestamp), {
// // //                         start: dateRange[0],
// // //                         end: dateRange[1],
// // //                     })
// // //                 );
// // //                 filtered.push(...chunk);
// // //             }
// // //             return filtered;
// // //         }
// // //         return data;
// // //     }, [data, dateRange]);
    
// // //     if (loading) return <LoadingSpinner />;

// // //     const safeNumber = (value, defaultValue = 0, entry = null) => {
// // //         if (value === undefined || value === null || value === '' || isNaN(Number(value))) {
// // //             return defaultValue === 0 ? 0.0001 : defaultValue;
// // //         }
// // //         return Number(value);
// // //     };

// // //     const cleanData = (filteredData) => {
// // //         return filteredData.filter(d =>
// // //             d &&
// // //             Object.values(d).every(v =>
// // //                 v !== undefined &&
// // //                 v !== null &&
// // //                 (typeof v !== 'number' || !isNaN(v))
// // //             )
// // //         );
// // //     };

// // //     const getTimePeriodKey = (timestamp) => {
// // //         if (isWeekly) {
// // //             return format(startOfWeek(parseISO(timestamp)), 'yyyy-MM-dd');
// // //         } else {
// // //             return format(startOfMonth(parseISO(timestamp)), 'yyyy-MM');
// // //         }
// // //     };

// // //     const pageVisits = filteredData.reduce((acc, entry) => {
// // //         if (isValid(parseISO(entry.Timestamp))) {
// // //             const periodKey = getTimePeriodKey(entry.Timestamp);
// // //             acc[periodKey] = (acc[periodKey] || 0) + 1;
// // //         }
// // //         return acc;
// // //     }, {});

// // //     const pageVisitsData = cleanData(
// // //         Object.entries(pageVisits)
// // //             .sort(([a], [b]) => new Date(a) - new Date(b))
// // //             .map(([period, count]) => ({
// // //                 period: `${period.replace(/-/g, '/')}`,
// // //                 count: safeNumber(count, 0, { period, count }),
// // //             }))
// // //     );

// // //     const averagePageDuration = filteredData.reduce((acc, entry) => {
// // //         if (entry.PageDuration !== undefined && entry.PageDuration !== null && !isNaN(entry.PageDuration)) {
// // //             if (!acc[entry.URL]) {
// // //                 acc[entry.URL] = { totalDuration: 0, count: 0 };
// // //             }
// // //             acc[entry.URL].totalDuration += entry.PageDuration;
// // //             acc[entry.URL].count += 1;
// // //         }
// // //         return acc;
// // //     }, {});

// // //     const formatDuration = (durationInSeconds) => {
// // //         const minutes = Math.floor(durationInSeconds / 60);
// // //         const seconds = Math.round(durationInSeconds % 60);
// // //         return `${minutes}m ${seconds}s`;
// // //     };

// // //     const averagePageDurationData = cleanData(
// // //         Object.entries(averagePageDuration)
// // //             .map(([url, { totalDuration, count }]) => {
// // //                 const avgDuration = count > 0 ? totalDuration / count : 0;
// // //                 return {
// // //                     url: url || 'No Data',
// // //                     averageDurationNumeric: avgDuration,
// // //                     averageDuration: formatDuration(safeNumber(avgDuration, 0, { url, totalDuration, count }))
// // //                 };
// // //             })
// // //             .sort((a, b) => b.averageDurationNumeric - a.averageDurationNumeric)
// // //     );

// // //     const keys = React.useMemo(() => {
// // //         const uniqueKeys = new Set();
// // //         pageVisitsPerPeriodData.forEach(d => {
// // //             Object.keys(d).forEach(key => {
// // //                 if (key !== 'period' && key !== null && key !== '') {
// // //                     uniqueKeys.add(key);
// // //                 }
// // //             });
// // //         });
// // //         return Array.from(uniqueKeys);
// // //     }, [pageVisitsPerPeriodData]);

// // //     const usersPerPeriod = filteredData.reduce((acc, entry) => {
// // //         if (isValid(parseISO(entry.Timestamp))) {
// // //             const periodKey = getTimePeriodKey(entry.Timestamp);
// // //             acc[periodKey] = acc[periodKey] || new Set();
// // //             acc[periodKey].add(entry.HappyID);
// // //         }
// // //         return acc;
// // //     }, {});

// // //     const usersPerPeriodData = cleanData(
// // //         Object.entries(usersPerPeriod)
// // //             .sort(([a], [b]) => new Date(a) - new Date(b))
// // //             .map(([period, users]) => ({
// // //                 period: `${period.replace(/-/g, '/')}`,
// // //                 users: safeNumber(users.size, 0, { period, userCount: users.size }),
// // //             }))
// // //     );

// // //     const sessionsPerPeriod = filteredData.reduce((acc, entry) => {
// // //         if (isValid(parseISO(entry.Timestamp))) {
// // //             const periodKey = getTimePeriodKey(entry.Timestamp);
// // //             acc[periodKey] = acc[periodKey] || new Set();
// // //             acc[periodKey].add(entry.SessionId);
// // //         }
// // //         return acc;
// // //     }, {});

// // //     const sessionsPerPeriodData = cleanData(
// // //         Object.entries(sessionsPerPeriod)
// // //             .sort(([a], [b]) => new Date(a) - new Date(b))
// // //             .map(([period, sessions]) => ({
// // //                 period: `${period.replace(/-/g, '/')}`,
// // //                 sessions: safeNumber(sessions.size, 0, { period, sessionCount: sessions.size }),
// // //             }))
// // //     );

// // //     const eventDescCounts = filteredData.reduce((acc, entry) => {
// // //         if (Array.isArray(entry.EventDescList)) {
// // //             entry.EventDescList.forEach(event => {
// // //                 if (event) {
// // //                     acc[event] = (acc[event] || 0) + 1;
// // //                 }
// // //             });
// // //         }
// // //         return acc;
// // //     }, {});

// // //     const top10EventsData = cleanData(
// // //         Object.entries(eventDescCounts)
// // //             .sort((a, b) => b[1] - a[1])
// // //             .slice(0, 10)
// // //             .map(([event, count]) => ({
// // //                 event: event || 'N/A',
// // //                 count: safeNumber(count, 0, { event, count }),
// // //             }))
// // //     );


// // // // Function to get top pages per period for the stacked bar chart
// // // // const getTopPagesPerPeriodData = (filteredData, isWeekly) => {
// // // //     console.log("Filtered Data:", filteredData);

// // // //     const pagesPerPeriod = filteredData.reduce((acc, entry) => {
// // // //         if (isValid(parseISO(entry.Timestamp))) {
// // // //             const periodKey = getTimePeriodKey(entry.Timestamp, isWeekly);
// // // //             acc[periodKey] = acc[periodKey] || {};
// // // //             const page = entry.URL || 'Other';
// // // //             acc[periodKey][page] = (acc[periodKey][page] || 0) + 1;
// // // //         }
// // // //         return acc;
// // // //     }, {});

// // // //     console.log("Pages per Period:", pagesPerPeriod);

// // // //     // Map each period to the top 5 pages and 'Other'
// // // //     const topPagesPerPeriodData = Object.entries(pagesPerPeriod).map(([period, pages]) => {
// // // //         const sortedPages = Object.entries(pages).sort((a, b) => b[1] - a[1]);
// // // //         console.log("Sorted Pages for Period", period, ":", sortedPages);
// // // //         const topPages = sortedPages.slice(0, 5);
// // // //         const otherCount = sortedPages.slice(5).reduce((sum, [, count]) => sum + count, 0);

// // // //         const data = {
// // // //             period, // Keep period in ISO format for sorting
// // // //         };

// // // //         topPages.forEach(([page, count]) => {
// // // //             data[page] = count;
// // // //         });

// // // //         if (otherCount > 0) {
// // // //             data['Other'] = otherCount;
// // // //         }
// // // //         return data;
// // // //     });

// // // //     console.log("Top Pages per Period Data before sorting:", topPagesPerPeriodData);

// // // //     // Sort the periods chronologically
// // // //     topPagesPerPeriodData.sort((a, b) => new Date(a.period) - new Date(b.period));

// // // //     console.log("Top Pages per Period Data after sorting:", topPagesPerPeriodData);

// // // //     return cleanData(topPagesPerPeriodData);
// // // // };

// // // const getTopPagesPerPeriodData = (filteredData, isWeekly) => {
// // //     console.log("Filtered Data:", filteredData);

// // //     const chunkSize = 1000;
// // //     const pagesPerPeriod = {};

// // //     for (let i = 0; i < filteredData.length; i += chunkSize) {
// // //         const chunk = filteredData.slice(i, i + chunkSize);
// // //         chunk.forEach(entry => {
// // //             if (isValid(parseISO(entry.Timestamp))) {
// // //                 const periodKey = getTimePeriodKey(entry.Timestamp, isWeekly);
// // //                 pagesPerPeriod[periodKey] = pagesPerPeriod[periodKey] || {};
// // //                 const page = entry.URL || 'Other';
// // //                 pagesPerPeriod[periodKey][page] = (pagesPerPeriod[periodKey][page] || 0) + 1;
// // //             }
// // //         });
// // //     }

// // //     console.log("Pages per Period:", pagesPerPeriod);

// // //     const topPagesPerPeriodData = Object.entries(pagesPerPeriod).map(([period, pages]) => {
// // //         const sortedPages = Object.entries(pages).sort((a, b) => b[1] - a[1]);
// // //         const topPages = sortedPages.slice(0, 5);
// // //         const otherCount = sortedPages.slice(5).reduce((sum, [, count]) => sum + count, 0);

// // //         const data = { period };
// // //         topPages.forEach(([page, count]) => {
// // //             data[page] = count;
// // //         });
// // //         if (otherCount > 0) {
// // //             data['Other'] = otherCount;
// // //         }
// // //         return data;
// // //     });

// // //     topPagesPerPeriodData.sort((a, b) => new Date(a.period) - new Date(b.period));
// // //     console.log("Top Pages per Period Data after sorting:", topPagesPerPeriodData);

// // //     return cleanData(topPagesPerPeriodData);
// // // };


// // // // Using the new function to generate data for the stacked bar chart
// // // const pageVisitsPerPeriodData = getTopPagesPerPeriodData(filteredData, isWeekly);
// // // console.log("Page Visits Per Period Data:", isWeekly, pageVisitsPerPeriodData);

// // // // const keys = Array.from(
// // // //     new Set(
// // // //         pageVisitsPerPeriodData.flatMap(d => 
// // // //             Object.keys(d)
// // // //                 .filter(key => key !== 'period' && key != null && key !== '')
// // // //         )
// // // //     )
// // // // );

// // // // const colorMapping = React.useMemo(() => {
// // // //     const mapping = {};
// // // //     keys.forEach((key, index) => {
// // // //         mapping[key] = customColors[index % customColors.length];
// // // //     });
// // // //     mapping['Other'] = '#CCCCCC'; // Default color for 'Other'
// // // //     return mapping;
// // // // }, [keys]);

// // // // Log the keys to verify
// // // console.log("Keys:", keys);

// // // // Create color mapping
// // // const colorMapping = {};
// // // const colorPalette = customColors; // Ensure this is an array of colors
// // // console.log("Color Mapping:", colorMapping);

// // // keys.forEach((url, index) => {
// // //     colorMapping[url] = colorPalette[index % colorPalette.length];
// // // });



// // // // Assign a color for 'Other'
// // // colorMapping['Other'] = '#CCCCCC'; // Or any color you prefer
// // // console.log("Color Mapping:", colorMapping);




// // //     return (
// // //         <div className="visualization-container">

// // //         <div className="week-analysis-page">
// // //             <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
// // //                 <h1 className="h1" order={2}>{isWeekly ? 'Trends Week Over Week' : 'Trends Month Over Month'}</h1>
// // //                 <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
// // //                 <SegmentedControl
// // //                         data={[
// // //                             { label: 'Weekly', value: 'weekly' },
// // //                             { label: 'Monthly', value: 'monthly' }
// // //                         ]}
// // //                         value={isWeekly ? 'weekly' : 'monthly'}
// // //                         onChange={(value) => setIsWeekly(value === 'weekly')}
// // //                         style={{ marginLeft: '1rem' }}
// // //                     />      
// // //                     <DatePickerInput
// // //                         type="range"
// // //                         label=""
// // //                         placeholder="Filter date range"
// // //                         value={dateRange}
// // //                         onChange={setDateRange}
// // //                         style={{ marginLeft: '2rem' }}
// // //                     />
// // //                       {/* Reset Button */}
// // //                       {dateRange[0] && dateRange[1] && (
// // //                             <button
// // //                             onClick={() => setDateRange([null, null])} // Reset the date range
// // //                             className="form-button"
// // //                             style={{ marginLeft: '1rem', maxWidth: '200px' }}
// // //                             >
// // //                             Reset Dates
// // //                             </button>
// // //                         )}
// // //                 </div>
// // //             </motion.div>

// // //             <div className="overview-chart">
// // //                 <Card shadow="sm" padding="lg" className="my-4">
// // //                     <Title order={3}>{isWeekly ? 'Weekly Page Visits' : 'Monthly Page Visits'}</Title>
// // //                     <div style={{ height: 400 }}>
// // //                         <ResponsiveBar
// // //                             data={pageVisitsData}
// // //                             keys={['count']}
// // //                             indexBy="period"
// // //                             indexScale={{ type: 'band', round: true }}
// // //                             valueScale={{ type: 'linear' }}
// // //                             margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
// // //                             padding={0.3}
// // //                             layout="vertical"
// // //                             colors={customColors[0]}
// // //                             axisBottom={{ legend: '', legendPosition: 'middle', legendOffset: 40, format: (d) => String(d), tickRotation: 45 }}
// // //                             axisLeft={{ legend: 'Page Visits', legendPosition: 'middle', legendOffset: -60 }}
// // //                             enableLabel={false}
// // //                             animate={true}
// // //                             theme={NivoTheme} 
// // //                             tooltip={({ value, indexValue }) => (
// // //                                 <div className="nivoTool">
// // //                                     {indexValue} count: <strong>{value}</strong>
// // //                                 </div>
// // //                             )}
// // //                         />
// // //                     </div>
// // //                 </Card>

// // //                 <Card shadow="sm" padding="lg" className="my-4">
// // //         <Title order={3}>
// // //             {isWeekly ? "Weekly Page Visits by Top Pages" : "Monthly Page Visits by Top Pages"}
// // //         </Title>
// // //         <div style={{ height: 400 }}>
// // //             <ResponsiveBar
// // //                 data={pageVisitsPerPeriodData}
// // //                 keys={keys}
// // //                 indexBy="period"
// // //                 margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
// // //                 padding={0.3}
// // //                 layout="vertical"
// // //                 colors={({ id }) => {
// // //                     if (!id) {
// // //                         console.error('Encountered a null or undefined id');
// // //                         return '#000000'; // Default color
// // //                     }
// // //                     const color = colorMapping[id] || '#000000'; // Default color
// // //                     console.log(`Color for ${id}: ${color}`);
// // //                     return color;
// // //                 }}                
// // //                 theme={NivoTheme}
// // //                 axisBottom={{
// // //                     tickSize: 5,
// // //                     tickPadding: 5,
// // //                     tickRotation: 45,
// // //                     legend: "",
// // //                     legendPosition: "middle",
// // //                     legendOffset: 40,
// // //                 }}
// // //                 axisLeft={{
// // //                     tickSize: 5,
// // //                     tickPadding: 5,
// // //                     tickRotation: 0,
// // //                     legend: "Page Visits",
// // //                     legendPosition: "middle",
// // //                     legendOffset: -60,
// // //                 }}
// // //                 labelSkipWidth={12}
// // //                 labelSkipHeight={12}
// // //                 labelTextColor={{
// // //                     from: 'color',
// // //                     modifiers: [['darker', 1.6]],
// // //                 }}
// // //                 legends={[
// // //                     {
// // //                         dataFrom: 'keys',
// // //                         anchor: 'bottom-right',
// // //                         direction: 'column',
// // //                         justify: false,
// // //                         translateX: 120,
// // //                         translateY: 0,
// // //                         itemsSpacing: 2,
// // //                         itemWidth: 100,
// // //                         itemHeight: 20,
// // //                         itemDirection: 'left-to-right',
// // //                         itemOpacity: 0.85,
// // //                         symbolSize: 20,
// // //                         effects: [
// // //                             {
// // //                                 on: 'hover',
// // //                                 style: {
// // //                                     itemOpacity: 1,
// // //                                 },
// // //                             },
// // //                         ],
// // //                     },
// // //                 ]}
// // //                 role="application"
// // //                 ariaLabel="Page Visits by Top Pages"
// // //                 barAriaLabel={e => `${e.id}: ${e.formattedValue} in period: ${e.indexValue}`}
// // //             />
// // //         </div>
// // //     </Card>
                    
// // //                 <Card shadow="sm" padding="lg" className="my-4">
// // //                     <Title order={3}>{isWeekly ? 'Number of Users per Week' : 'Number of Users per Month'}</Title>
// // //                     <div style={{ height: 400 }}>
// // //                         <ResponsiveBar
// // //                             data={usersPerPeriodData}
// // //                             keys={['users']}
// // //                             indexBy="period"
// // //                             indexScale={{ type: 'band', round: true }}
// // //                             valueScale={{ type: 'linear' }}
// // //                             margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
// // //                             padding={0.3}
// // //                             layout="vertical"
// // //                             colors={customColors[2]}
// // //                             axisBottom={{ legend: '', legendPosition: 'middle', legendOffset: 40, format: (d) => String(d), tickRotation: 45 }}
// // //                             axisLeft={{ legend: 'Number of Users', legendPosition: 'middle', legendOffset: -60 }}
// // //                             enableLabel={false}
// // //                             animate={true}
// // //                             theme={NivoTheme} 
// // //                             tooltip={({ value, indexValue }) => (
// // //                                 <div className="nivoTool">
// // //                                     {indexValue} user count: <strong>{value}</strong>
// // //                                 </div>
// // //                             )}
// // //                         />
// // //                     </div>
// // //                 </Card>

// // //                 <Card shadow="sm" padding="lg" className="my-4">
// // //                     <Title order={3}>{isWeekly ? 'Number of Sessions per Week' : 'Number of Sessions per Month'}</Title>
// // //                     <div style={{ height: 400 }}>
// // //                         <ResponsiveBar
// // //                             data={sessionsPerPeriodData}
// // //                             keys={['sessions']}
// // //                             indexBy="period"
// // //                             indexScale={{ type: 'band', round: true }}
// // //                             valueScale={{ type: 'linear' }}
// // //                             margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
// // //                             padding={0.3}
// // //                             layout="vertical"
// // //                             colors={customColors[3]}
// // //                             axisBottom={{ legend: '', legendPosition: 'middle', legendOffset: 40, format: (d) => String(d), tickRotation: 45 }}
// // //                             axisLeft={{ legend: 'Number of Sessions', legendPosition: 'middle', legendOffset: -60 }}
// // //                             enableLabel={false}
// // //                             animate={true}
// // //                             theme={NivoTheme} 
// // //                             tooltip={({ value, indexValue }) => (
// // //                                 <div className="nivoTool">
// // //                                     {indexValue} session count: <strong>{value}</strong>
// // //                                 </div>
// // //                             )}
// // //                         />
// // //                     </div>
// // //                 </Card>

// // //                 <Card shadow="sm" padding="lg" className="my-4">
// // //                     <Title order={3}>Average Page Duration</Title>
// // //                     <div style={{ height: 400 }}>
// // //                         <ResponsiveBar
// // //                             data={averagePageDurationData}
// // //                             keys={['averageDurationNumeric']}
// // //                             indexBy="url"
// // //                             indexScale={{ type: 'band', round: true }}
// // //                             valueScale={{ type: 'linear' }}
// // //                             margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
// // //                             padding={0.3}
// // //                             layout="vertical"
// // //                             colors={customColors[4]}
// // //                             axisBottom={{ legend: '', legendPosition: 'middle', legendOffset: 40, format: (d) => String(d), tickRotation: 45 }}
// // //                             axisLeft={{ legend: 'Average Duration (seconds)', legendPosition: 'middle', legendOffset: -60 }}
// // //                             tooltip={({ value, indexValue }) => (
// // //                                 <div className="nivoTool">
// // //                                     {indexValue} duration: <strong>{formatDuration(value)}</strong>
// // //                                 </div>
// // //                             )}
// // //                             enableLabel={false}
// // //                             animate={true}
// // //                             theme={NivoTheme} 
// // //                         />
// // //                     </div>
// // //                 </Card>

// // //                 <Card shadow="sm" padding="lg" className="my-4">
// // //                     <Title order={3}>Top 10 Events by Count</Title>
// // //                     <div style={{ height: 400 }}>
// // //                         <ResponsiveBar
// // //                             data={top10EventsData}
// // //                             keys={['count']}
// // //                             indexBy="event"
// // //                             indexScale={{ type: 'band', round: true }}
// // //                             valueScale={{ type: 'linear' }}
// // //                             margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
// // //                             padding={0.3}
// // //                             layout="vertical"
// // //                             colors={customColors[5]}
// // //                             axisBottom={{ legend: '', legendPosition: 'middle', legendOffset: 40, format: (d) => String(d), tickRotation: 45 }}
// // //                             axisLeft={{ legend: 'Count', legendPosition: 'middle', legendOffset: -60 }}
// // //                             enableLabel={false}
// // //                             animate={true}
// // //                             theme={NivoTheme} 
// // //                             tooltip={({ value, indexValue }) => (
// // //                                 <div className="nivoTool">
// // //                                     {indexValue} count: <strong>{value}</strong>
// // //                                 </div>
// // //                             )}
// // //                         />
// // //                     </div>
// // //                 </Card>
// // //             </div>
// // //         </div>
// // //         </div>
// // //     );
// // // }

// // // export default WeekOverWeekAnalysis;

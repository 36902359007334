// FPGraphPage.js
import React, { useMemo, useRef, useContext } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Html } from "@react-three/drei";
import * as THREE from "three";
import { createNoise3D } from "simplex-noise";
import { useDataset } from "../components/DataFetcher";
import * as d3 from "d3-force";
import { DimensionContext } from '../components/ResponsiveWrapper';

// Color palette
const colorPalette = {
  dkpurple: "#2c294b",
  mdpurple: "#3b3484",
  ltpurple: "#7174b0",
  dkorange: "#de6736",
  mdorange: "#e08a3c",
  ltorange: "#ebb844",
  cranberry: "#762861",
  magenta: "#c5316a",
  mdgreen: "#80ba55",
};

function parseItemList(itemList) {
  if (!itemList) return [];
  return Array.isArray(itemList) ? itemList.flat(Infinity) : [];
}

function generateBaseNoise(width, depth, simplex, scale = 15, baseAmplitude = 0.75) {
  const heightmap = new Float32Array(width * depth);
  for (let i = 0; i < width * depth; i++) {
    const x = (i % width) / width;
    const z = Math.floor(i / width) / depth;
    heightmap[i] = simplex(x * scale, z * scale, 0) * baseAmplitude;
  }
  return heightmap;
}

function applyPeaks(
  width,
  depth,
  heightmap,
  itemPositions,
  normalizePositionFn,
  sigmaPeak = 0.05,
  amplitudeScale = 1.0
) {
  itemPositions.forEach(({ item, position, metrics }) => {
    const rawAmplitude =
      (metrics.support || 0) +
      (metrics.lift || 0) +
      (metrics.conviction || 0) +
      (metrics.zhangs_metric || 0);

    if (rawAmplitude <= 0) return;

    const [ix, iz] = normalizePositionFn(position, width);
    const scaledAmplitude = (rawAmplitude / 100) * amplitudeScale * 3;

    for (let i = 0; i < width * depth; i++) {
      const x = (i % width) / width;
      const z = Math.floor(i / width) / depth;

      const dx = x - ix / width;
      const dz = z - iz / depth;
      const distSq = dx * dx + dz * dz;

      const influence =
        Math.exp(-distSq / (2 * sigmaPeak * sigmaPeak)) * scaledAmplitude;
      heightmap[i] = Math.max(heightmap[i], heightmap[i] + influence);
    }
  });
  return heightmap;
}

function distanceAndProjectionToLineSegment(px, pz, ax, az, cx, cz) {
  const acx = cx - ax;
  const acz = cz - az;
  const apx = px - ax;
  const apz = pz - az;
  const acLengthSq = acx * acx + acz * acz;

  let dist, t;
  if (acLengthSq === 0) {
    // A and C are essentially the same point
    const dx = px - ax;
    const dz = pz - az;
    dist = Math.sqrt(dx * dx + dz * dz);
    t = 0;
  } else {
    t = (apx * acx + apz * acz) / acLengthSq;
    if (t < 0) {
      const dx = px - ax;
      const dz = pz - az;
      dist = Math.sqrt(dx * dx + dz * dz);
      t = 0;
    } else if (t > 1) {
      const dx = px - cx;
      const dz = pz - cz;
      dist = Math.sqrt(dx * dx + dz * dz);
      t = 1;
    } else {
      const projX = ax + t * acx;
      const projZ = az + t * acz;
      const dx = px - projX;
      const dz = pz - projZ;
      dist = Math.sqrt(dx * dx + dz * dz);
    }
  }
  return { dist, t };
}

function applyLineRidges(
  width,
  depth,
  heightmap,
  parsedData,
  itemPositions,
  normalizePositionFn,
  sigmaRidge,
  ridgeScale,
  baseline,
  adjustedXExtent,
  adjustedYExtent
) {
  const ridgeMap = new Float32Array(width * depth).fill(0);

  parsedData.forEach((rule) => {
    if (!rule.consequents || rule.consequents.length === 0) return;
    const consequentItem = rule.consequents[0];
    const consequentPosData = itemPositions.find((p) => p.item === consequentItem);

    if (!consequentPosData) return;

    const cPos = normalizePositionFn(consequentPosData.position, width);

    const supportInfluence = ((rule.support || 0) / 100) * ridgeScale * 100;

    if (!rule.antecedents || rule.antecedents.length === 0) return;

    rule.antecedents.forEach((antItem) => {
      const antPosData = itemPositions.find((p) => p.item === antItem);
      if (!antPosData) return;

      const aPos = normalizePositionFn(antPosData.position, width);

      const ax = aPos[0] / width;
      const az = aPos[1] / depth;
      const cx = cPos[0] / width;
      const cz = cPos[1] / depth;

      for (let i = 0; i < width * depth; i++) {
        const ix = i % width;
        const iz = Math.floor(i / width);
        const x = ix / width;
        const z = iz / depth;

        const { dist } = distanceAndProjectionToLineSegment(
          x,
          z,
          ax,
          az,
          cx,
          cz
        );
        const influence =
          Math.exp(-(dist * dist) / (2 * sigmaRidge * sigmaRidge)) *
          supportInfluence;

        if (influence > ridgeMap[i]) {
          ridgeMap[i] = influence;
        }
      }
    });
  });

  for (let i = 0; i < width * depth; i++) {
    const baseVal = baseline[i];
    const currentHeight = heightmap[i]; //this is unused
    const ridgeVal = ridgeMap[i];
    const peakHeight = baseVal + ridgeScale;

    if (ridgeVal > 0) {
      const ridgeHeight = baseVal + ridgeVal;
      if (ridgeHeight <= peakHeight && heightmap[i] < ridgeHeight) {
        heightmap[i] = ridgeHeight;
      }
    }
  }
  return heightmap;
}


function arrangeItems(uniqueItems, parsedData) {
  const nodes = uniqueItems.map((item) => ({ id: item }));
  const links = parsedData.flatMap((rule) =>
    rule.antecedents.map((antecedent) => ({
      source: antecedent,
      target: rule.consequents[0],
      value: rule.support,
    }))
  );

  const simulation = d3.forceSimulation(nodes)
    .force("link", d3.forceLink(links).id((d) => d.id).strength(0.8))
    .force("charge", d3.forceManyBody().strength(-100))
    .force("center", d3.forceCenter(0, 0))
    .force("collision", d3.forceCollide(15));

  for (let i = 0; i < 300; i++) simulation.tick();

  // Compute extents
  const xValues = nodes.map((node) => node.x);
  const yValues = nodes.map((node) => node.y);
  const xMin = Math.min(...xValues);
  const xMax = Math.max(...xValues);
  const yMin = Math.min(...yValues);
  const yMax = Math.max(...yValues);

  const bufferFactor = 0.15; // Adjust this to control the size of the buffer (10% in this case)
const xBuffer = (xMax - xMin) * bufferFactor;
const yBuffer = (yMax - yMin) * bufferFactor;

const adjustedXExtent = [xMin - xBuffer, xMax + xBuffer];
const adjustedYExtent = [yMin - yBuffer, yMax + yBuffer];

  // Return positions and extents
  return {
    itemPositions: nodes.map((node) => ({
      item: node.id,
      position: [node.x, node.y],
    })),
    adjustedXExtent,
    adjustedYExtent,
    // xExtent: [xMin, xMax],
    // yExtent: [yMin, yMax],
  };
}

function distributeMetrics(parsedData, itemMetrics) {
  parsedData.forEach((rule) => {
    const allItems = [...rule.antecedents, ...rule.consequents];
    const totalItems = allItems.length;

    allItems.forEach((item) => {
      if (!itemMetrics[item]) return;
      const weight = totalItems > 0 ? 1 / totalItems : 0;

      itemMetrics[item].support += (rule.support || 0) * weight;
      itemMetrics[item].lift += (rule.lift || 0) * weight;
      itemMetrics[item].conviction += (rule.conviction || 0) * weight;
      itemMetrics[item].zhangs_metric += (rule.zhangs_metric || 0) * weight;
    });
  });
}

function normalizePosition(position, gridSize, adjustedXExtent, adjustedYExtent) {
  const [x, y] = position;
  const xMin = adjustedXExtent[0];
  const xMax = adjustedXExtent[1];
  const yMin = adjustedYExtent[0];
  const yMax = adjustedYExtent[1];

  const normalizedX = Math.round(((x - xMin) / (xMax - xMin)) * (gridSize - 1));
  const normalizedY = Math.round(((y - yMin) / (yMax - yMin)) * (gridSize - 1));

  return [normalizedX, normalizedY];
}

function mapToTerrainCoords(coord, coordMin, coordMax, terrainSize) {
  const newMin = -terrainSize / 2;
  const newMax = terrainSize / 2;
  return ((coord - coordMin) / (coordMax - coordMin)) * (newMax - newMin) + newMin;
}

function smoothHeightmap(heightmap, width, depth, iterations = 2) {
  const smoothedHeightmap = new Float32Array(heightmap);

  for (let iter = 0; iter < iterations; iter++) {
    for (let z = 1; z < depth - 1; z++) {
      for (let x = 1; x < width - 1; x++) {
        const index = z * width + x;
        const neighbors = [
          heightmap[index - 1], // left
          heightmap[index + 1], // right
          heightmap[index - width], // top
          heightmap[index + width], // bottom
        ];
        const average =
          (heightmap[index] + neighbors.reduce((a, b) => a + b, 0)) /
          (neighbors.length + 1);
        smoothedHeightmap[index] = average;
      }
    }
    heightmap.set(smoothedHeightmap); // Update heightmap for the next iteration
  }
  return heightmap;
}


function generateHeightmapWithPeaksAndRidges(
  width,
  depth,
  itemPositions,
  parsedData,
  itemMetrics,
  adjustedXExtent,
  adjustedYExtent
) {
  const simplex = createNoise3D();

  let heightmap = generateBaseNoise(width, depth, simplex, 5, 0.25);

  heightmap = applyPeaks(
    width,
    depth,
    heightmap,
    itemPositions,
    (pos, gridSize) => normalizePosition(pos, gridSize, adjustedXExtent, adjustedYExtent),
    0.05,
    1.5
  );

  const postPeaksHeightmap = heightmap.slice(); // Copy for baseline

  heightmap = applyLineRidges(
    width,
    depth,
    heightmap,
    parsedData,
    itemPositions,
    (pos, gridSize) => normalizePosition(pos, gridSize, adjustedXExtent, adjustedYExtent),
    0.007,
    60,
    postPeaksHeightmap,
    adjustedXExtent,
    adjustedYExtent
  );

  heightmap = smoothHeightmap(heightmap, width, depth, .75);

  return heightmap;
}

function Terrain({ itemPositions, parsedData, itemMetrics, adjustedXExtent, adjustedYExtent }) {
  const meshRef = useRef();
  const { width: containerWidth, height: containerHeight } = useContext(DimensionContext); //this is unused

  // Calculate terrain size based on data extents
  // const xRange = xExtent[1] - xExtent[0];
  // const yRange = yExtent[1] - yExtent[0];
  const xRange = adjustedXExtent[1] - adjustedXExtent[0];
  const yRange = adjustedYExtent[1] - adjustedYExtent[0];
  const dataRange = Math.max(xRange, yRange);
  const scaleFactor = 4.0; // Adjust as needed
  const size = dataRange * scaleFactor;

  // const divisions = Math.max(Math.floor(size / 5), 50);
  const divisions = Math.max(Math.floor(size / 4), 200); // Increase the base resolution

  const [geometry, heightmap] = useMemo(() => {
    const geometry = new THREE.PlaneGeometry(size, size, divisions, divisions);
    geometry.rotateX(-Math.PI / 2);

    const width = divisions + 1;
    const depth = divisions + 1;

    const heightmap = generateHeightmapWithPeaksAndRidges(
      width,
      depth,
      itemPositions,
      parsedData,
      itemMetrics,
      adjustedXExtent,
      adjustedYExtent
    );

    const positionAttribute = geometry.attributes.position;
    const colorAttribute = new THREE.BufferAttribute(
      new Float32Array(positionAttribute.count * 3),
      3
    );

    for (let i = 0; i < positionAttribute.count; i++) {
      const elevation = heightmap[i];
      const vertex = new THREE.Vector3().fromBufferAttribute(
        positionAttribute,
        i
      );
      vertex.y = elevation;
      positionAttribute.setXYZ(i, vertex.x, vertex.y, vertex.z);

      const elevationNormalized = (elevation + 1) / 3;  //If artifacts are visible, tweak the range or smoothing logic.
      let color;
      if (elevationNormalized < 5) {
        color = new THREE.Color(colorPalette.cranberry);
      } else if (elevationNormalized < 10) {
        color = new THREE.Color(colorPalette.mdpurple);
      } else if (elevationNormalized < 15) {
        color = new THREE.Color(colorPalette.dkpurple);
      } else if (elevationNormalized < 20) {
        color = new THREE.Color(colorPalette.mdorange);
      } else if (elevationNormalized < 25) {
        color = new THREE.Color(colorPalette.dkorange);
      } else if (elevationNormalized < 30) {
        color = new THREE.Color(colorPalette.magenta);
      } else if (elevationNormalized < 35) {
        color = new THREE.Color(colorPalette.ltpurple);
      } else if (elevationNormalized < 40) {
        color = new THREE.Color(colorPalette.ltorange);
      } else {
        color = new THREE.Color(colorPalette.mdgreen);
      }

      colorAttribute.setXYZ(i, color.r, color.g, color.b);
    }

    geometry.setAttribute("color", colorAttribute);
    geometry.computeVertexNormals();
    console.log("[Terrain] Geometry and colors set up successfully.");

    return [geometry, heightmap];
  }, [
    itemPositions,
    parsedData,
    itemMetrics,
    divisions,
    size,
    adjustedXExtent,
    adjustedYExtent,
  ]);

  function getItemHeightForLabel(xPosOriginal, zPosOriginal) {
    const width = divisions + 1;

    // Map item coords to terrain coords using actual extents
    const xPos = mapToTerrainCoords(
      xPosOriginal,
      adjustedXExtent[0],
      adjustedXExtent[1],
      size
    );
    const zPos = mapToTerrainCoords(
      zPosOriginal,
      adjustedYExtent[0],
      adjustedYExtent[1],
      size
    );

    const [ix, iz] = normalizePosition(
      [xPosOriginal, zPosOriginal],
      width,
      adjustedXExtent,
      adjustedYExtent
    );

    const ixClamped = Math.max(0, Math.min(width - 1, ix));
    const izClamped = Math.max(0, Math.min(width - 1, iz));

    const i = izClamped * width + ixClamped;
    const h = heightmap[i] || 0;

    return { terrainX: xPos, terrainZ: zPos, height: h };
  }

  const labelPositions = useMemo(() => {
    return itemPositions.map(({ item, position }) => {
      const [origX, origZ] = position;
      const { terrainX, terrainZ, height: peakHeight } = getItemHeightForLabel(
        origX,
        origZ
      );
      const finalPos = [terrainX, peakHeight + 3, terrainZ];
      return { item, position: finalPos };
    });
  }, [itemPositions, heightmap, size, adjustedXExtent, adjustedYExtent]);

  return (
    <>
      <mesh ref={meshRef} geometry={geometry}>
        <meshStandardMaterial
          vertexColors={true}
          side={THREE.DoubleSide}
          flatShading={false}
        />
      </mesh>
      {labelPositions.map(({ item, position }) => (
        <Html
          key={item}
          position={position}
          style={{ pointerEvents: "none" }}
        >
          <div
            style={{
              color: "white",
              fontSize: "9px",
              fontFamily: "Manjari, sans-serif;",
              textAlign: "center",
              background: "rgba(0,0,0,0.7)",
              padding: "2px 3px",
              borderRadius: "2px",
              boxShadow: "0px 1px 2px rgba(0,0,0,0.3)",
            }}
          >
            {item}
          </div>
        </Html>
      ))}
    </>
  );
}

const FPGraphPage = () => {
  const { data } = useDataset("fpgrowth");

  const fpgrowthData = useMemo(() => {
    if (!data?.FPGrowth) return null;
    const cleanedString = data.FPGrowth.replace(/Infinity/g, "2");
    try {
      return JSON.parse(cleanedString);
    } catch {
      console.error("Error parsing FPGrowth data.", cleanedString);
      return null;
    }
  }, [data]);

  const parsedData = useMemo(() => {
    if (!fpgrowthData || !fpgrowthData.data) return [];
    const { columns, data: rows } = fpgrowthData;
    return rows.map((row) => {
      const rowData = {};
      columns.forEach((col, index) => {
        rowData[col] = row[index];
      });
      const antecedents = parseItemList(rowData["antecedents"]);
      const consequents = parseItemList(rowData["consequents"]);
      return {
        antecedents,
        consequents,
        antecedent_support: rowData["antecedent support"] || 0,
        consequent_support: rowData["consequent support"] || 0,
        support: rowData["support"] || 0,
        confidence: rowData["confidence"] || 0,
        lift: rowData["lift"] || 0,
        conviction: rowData["conviction"] || 0,
        zhangs_metric: rowData["zhangs_metric"] || 0,
      };
    });
  }, [fpgrowthData]);

  const uniqueItems = useMemo(() => {
    const items = new Set();
    parsedData.forEach((rule) => {
      rule.antecedents.forEach((item) => items.add(item));
      rule.consequents.forEach((item) => items.add(item));
    });
    return Array.from(items);
  }, [parsedData]);

  const itemMetrics = useMemo(() => {
    const metrics = {};
    uniqueItems.forEach((item) => {
      metrics[item] = {
        support: 0,
        conviction: 0,
        zhangs_metric: 0,
        lift: 0,
        count: 0,
      };
    });
    distributeMetrics(parsedData, metrics);
    console.log("[FPGraphPage] Item metrics (aggregated):", metrics);
    return metrics;
  }, [parsedData, uniqueItems]);

  const {
    itemPositions: arrangedItemPositions,
    adjustedXExtent,
    adjustedYExtent,
  } = useMemo(() => {
    const { itemPositions, adjustedXExtent, adjustedYExtent } = arrangeItems(
      uniqueItems,
      parsedData
    );
    // Map positions to include metrics
    const positions = itemPositions.map(({ item, position }) => ({
      item,
      position,
      metrics:
        itemMetrics[item] || {
          support: 0,
          lift: 0,
          conviction: 0,
          zhangs_metric: 0,
        },
    }));
    return {
      itemPositions: positions,
      adjustedXExtent,
      adjustedYExtent,
    };
  }, [uniqueItems, itemMetrics, parsedData]);


  console.log("[FPGraphPage] Render start");
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      {/* <Canvas camera={{ position: [0, 300, 300], fov: 75 }}> */}
      {/* <Canvas camera={{ position: [-150, 300, 250], fov: 125 }}> */}
      <Canvas camera={{ position: [-250, 300, 250], fov: 45 }} dpr={[1, 2]} antialias={true} >
      {/* <OrbitControls enableZoom={true} /> */}
      <OrbitControls enableZoom={true} minDistance={50} maxDistance={1000} />

        <ambientLight intensity={1.6} />
        <directionalLight position={[10, 30, 10]} intensity={2.8} />
        {parsedData && <Terrain itemPositions={arrangedItemPositions} parsedData={parsedData} itemMetrics={itemMetrics}            
          adjustedXExtent={adjustedXExtent}
          adjustedYExtent={adjustedYExtent} />}
      </Canvas>
    </div>
  );
};

export default FPGraphPage;







// // FPGraphPage.js
// import React, { useMemo, useRef, useContext } from "react";
// import { Canvas } from "@react-three/fiber";
// import { OrbitControls, Html } from "@react-three/drei";
// import * as THREE from "three";
// import { createNoise3D } from "simplex-noise";
// import { useDataset } from "../components/DataFetcher";
// import * as d3 from "d3-force";
// import { DimensionContext } from '../components/ResponsiveWrapper';

// // Color palette
// const colorPalette = {
//   dkpurple: "#2c294b",
//   mdpurple: "#3b3484",
//   ltpurple: "#7174b0",
//   dkorange: "#de6736",
//   mdorange: "#e08a3c",
//   ltorange: "#ebb844",
//   cranberry: "#762861",
//   magenta: "#c5316a",
//   mdgreen: "#80ba55",
// };

// function parseItemList(itemList) {
//   if (!itemList) return [];
//   return Array.isArray(itemList) ? itemList.flat(Infinity) : [];
// }

// function generateBaseNoise(width, depth, simplex, scale = 15, baseAmplitude = 0.75) {
//   const heightmap = new Float32Array(width * depth);
//   for (let i = 0; i < width * depth; i++) {
//     const x = (i % width) / width;
//     const z = Math.floor(i / width) / depth;
//     heightmap[i] = simplex(x * scale, z * scale, 0) * baseAmplitude;
//   }
//   return heightmap;
// }

// function applyPeaks(
//   width,
//   depth,
//   heightmap,
//   itemPositions,
//   normalizePositionFn,
//   sigmaPeak = 0.05,
//   amplitudeScale = 1.0
// ) {
//   itemPositions.forEach(({ item, position, metrics }) => {
//     const rawAmplitude =
//       (metrics.support || 0) +
//       (metrics.lift || 0) +
//       (metrics.conviction || 0) +
//       (metrics.zhangs_metric || 0);

//     if (rawAmplitude <= 0) return;

//     const [ix, iz] = normalizePositionFn(position, width);
//     const scaledAmplitude = (rawAmplitude / 100) * amplitudeScale * 3;

//     for (let i = 0; i < width * depth; i++) {
//       const x = (i % width) / width;
//       const z = Math.floor(i / width) / depth;

//       const dx = x - ix / width;
//       const dz = z - iz / depth;
//       const distSq = dx * dx + dz * dz;

//       const influence =
//         Math.exp(-distSq / (2 * sigmaPeak * sigmaPeak)) * scaledAmplitude;
//       heightmap[i] = Math.max(heightmap[i], heightmap[i] + influence);
//     }
//   });
//   return heightmap;
// }

// function distanceAndProjectionToLineSegment(px, pz, ax, az, cx, cz) {
//   const acx = cx - ax;
//   const acz = cz - az;
//   const apx = px - ax;
//   const apz = pz - az;
//   const acLengthSq = acx * acx + acz * acz;

//   let dist, t;
//   if (acLengthSq === 0) {
//     // A and C are essentially the same point
//     const dx = px - ax;
//     const dz = pz - az;
//     dist = Math.sqrt(dx * dx + dz * dz);
//     t = 0;
//   } else {
//     t = (apx * acx + apz * acz) / acLengthSq;
//     if (t < 0) {
//       const dx = px - ax;
//       const dz = pz - az;
//       dist = Math.sqrt(dx * dx + dz * dz);
//       t = 0;
//     } else if (t > 1) {
//       const dx = px - cx;
//       const dz = pz - cz;
//       dist = Math.sqrt(dx * dx + dz * dz);
//       t = 1;
//     } else {
//       const projX = ax + t * acx;
//       const projZ = az + t * acz;
//       const dx = px - projX;
//       const dz = pz - projZ;
//       dist = Math.sqrt(dx * dx + dz * dz);
//     }
//   }
//   return { dist, t };
// }

// function applyLineRidges(
//   width,
//   depth,
//   heightmap,
//   parsedData,
//   itemPositions,
//   normalizePositionFn,
//   sigmaRidge,
//   ridgeScale,
//   baseline,
//   xExtent,
//   yExtent
// ) {
//   const ridgeMap = new Float32Array(width * depth).fill(0);

//   parsedData.forEach((rule) => {
//     if (!rule.consequents || rule.consequents.length === 0) return;
//     const consequentItem = rule.consequents[0];
//     const consequentPosData = itemPositions.find((p) => p.item === consequentItem);

//     if (!consequentPosData) return;

//     const cPos = normalizePositionFn(consequentPosData.position, width);

//     const supportInfluence = ((rule.support || 0) / 100) * ridgeScale * 100;

//     if (!rule.antecedents || rule.antecedents.length === 0) return;

//     rule.antecedents.forEach((antItem) => {
//       const antPosData = itemPositions.find((p) => p.item === antItem);
//       if (!antPosData) return;

//       const aPos = normalizePositionFn(antPosData.position, width);

//       const ax = aPos[0] / width;
//       const az = aPos[1] / depth;
//       const cx = cPos[0] / width;
//       const cz = cPos[1] / depth;

//       for (let i = 0; i < width * depth; i++) {
//         const ix = i % width;
//         const iz = Math.floor(i / width);
//         const x = ix / width;
//         const z = iz / depth;

//         const { dist } = distanceAndProjectionToLineSegment(
//           x,
//           z,
//           ax,
//           az,
//           cx,
//           cz
//         );
//         const influence =
//           Math.exp(-(dist * dist) / (2 * sigmaRidge * sigmaRidge)) *
//           supportInfluence;

//         if (influence > ridgeMap[i]) {
//           ridgeMap[i] = influence;
//         }
//       }
//     });
//   });

//   for (let i = 0; i < width * depth; i++) {
//     const baseVal = baseline[i];
//     const currentHeight = heightmap[i]; //this is unused
//     const ridgeVal = ridgeMap[i];
//     const peakHeight = baseVal + ridgeScale;

//     if (ridgeVal > 0) {
//       const ridgeHeight = baseVal + ridgeVal;
//       if (ridgeHeight <= peakHeight && heightmap[i] < ridgeHeight) {
//         heightmap[i] = ridgeHeight;
//       }
//     }
//   }
//   return heightmap;
// }

// function arrangeItems(uniqueItems, parsedData, margin = 100) {
//   const nodes = uniqueItems.map((item) => ({ id: item }));
//   const links = parsedData.flatMap((rule) =>
//     rule.antecedents.map((antecedent) => ({
//       source: antecedent,
//       target: rule.consequents[0],
//       value: rule.support,
//     }))
//   );

//   const simulation = d3.forceSimulation(nodes)
//     .force("link", d3.forceLink(links).id((d) => d.id).strength(0.8))
//     .force("charge", d3.forceManyBody().strength(-100))
//     .force("center", d3.forceCenter(0, 0))
//     .force("collision", d3.forceCollide(15));

//   for (let i = 0; i < 300; i++) simulation.tick();

//   // Compute extents with margin
//   const xValues = nodes.map((node) => node.x);
//   const yValues = nodes.map((node) => node.y);
//   const xMin = Math.min(...xValues) - margin;
//   const xMax = Math.max(...xValues) + margin;
//   const yMin = Math.min(...yValues) - margin;
//   const yMax = Math.max(...yValues) + margin;

//   return {
//     itemPositions: nodes.map((node) => ({
//       item: node.id,
//       position: [node.x, node.y],
//     })),
//     xExtent: [xMin, xMax],
//     yExtent: [yMin, yMax],
//   };
// }

// function distributeMetrics(parsedData, itemMetrics) {
//   parsedData.forEach((rule) => {
//     const allItems = [...rule.antecedents, ...rule.consequents];
//     const totalItems = allItems.length;

//     allItems.forEach((item) => {
//       if (!itemMetrics[item]) return;
//       const weight = totalItems > 0 ? 1 / totalItems : 0;

//       itemMetrics[item].support += (rule.support || 0) * weight;
//       itemMetrics[item].lift += (rule.lift || 0) * weight;
//       itemMetrics[item].conviction += (rule.conviction || 0) * weight;
//       itemMetrics[item].zhangs_metric += (rule.zhangs_metric || 0) * weight;
//     });
//   });
// }
// function normalizePosition(position, gridSize, xExtent, yExtent) {
//   const [x, y] = position;
//   const xMin = xExtent[0];
//   const xMax = xExtent[1];
//   const yMin = yExtent[0];
//   const yMax = yExtent[1];

//   const normalizedX = Math.round(((x - xMin) / (xMax - xMin)) * (gridSize - 1));
//   const normalizedY = Math.round(((y - yMin) / (yMax - yMin)) * (gridSize - 1));

//   // Clamp values to avoid out-of-bound errors
//   return [
//     Math.max(0, Math.min(gridSize - 1, normalizedX)),
//     Math.max(0, Math.min(gridSize - 1, normalizedY)),
//   ];
// }


// function mapToTerrainCoords(coord, coordMin, coordMax, terrainSize) {
//   const newMin = -terrainSize / 2;
//   const newMax = terrainSize / 2;
//   return ((coord - coordMin) / (coordMax - coordMin)) * (newMax - newMin) + newMin;
// }

// function generateHeightmapWithPeaksAndRidges(
//   width,
//   depth,
//   itemPositions,
//   parsedData,
//   itemMetrics,
//   xExtent,
//   yExtent
// ) {
//   const simplex = createNoise3D();

//   let heightmap = generateBaseNoise(width, depth, simplex, 15, 0.75);

//   heightmap = applyPeaks(
//     width,
//     depth,
//     heightmap,
//     itemPositions,
//     (pos, gridSize) => normalizePosition(pos, gridSize, xExtent, yExtent),
//     0.05,
//     1.5
//   );

//   const postPeaksHeightmap = heightmap.slice(); // Copy for baseline

//   heightmap = applyLineRidges(
//     width,
//     depth,
//     heightmap,
//     parsedData,
//     itemPositions,
//     (pos, gridSize) => normalizePosition(pos, gridSize, xExtent, yExtent),
//     0.006,
//     15,
//     postPeaksHeightmap,
//     xExtent,
//     yExtent
//   );

//   return heightmap;
// }

// function Terrain({ itemPositions, parsedData, itemMetrics, xExtent, yExtent }) {
//   const meshRef = useRef();
//   const { width: containerWidth, height: containerHeight } = useContext(DimensionContext); //this is unused

//   // Calculate terrain size based on data extents
//   const xRange = xExtent[1] - xExtent[0];
//   const yRange = yExtent[1] - yExtent[0];
//   const dataRange = Math.max(xRange, yRange);
//   const scaleFactor = 1.5; // Adjust as needed
//   const size = dataRange * scaleFactor;

//   // const divisions = Math.max(Math.floor(size / 5), 50);
//   const divisions = Math.max(Math.floor(size / 2), 100); // Increased resolution

//   const [geometry, heightmap] = useMemo(() => {
//     const geometry = new THREE.PlaneGeometry(size, size, divisions, divisions);
//     geometry.rotateX(-Math.PI / 2);

//     const width = divisions + 1;
//     const depth = divisions + 1;

//     const heightmap = generateHeightmapWithPeaksAndRidges(
//       width,
//       depth,
//       itemPositions,
//       parsedData,
//       itemMetrics,
//       xExtent,
//       yExtent
//     );
//     // Validate heightmap
//     for (let i = 0; i < heightmap.length; i++) {
//       if (isNaN(heightmap[i]) || heightmap[i] === undefined) {
//         console.error(`Invalid heightmap value at index ${i}: ${heightmap[i]}`);
//         heightmap[i] = 0; // Default to 0 if invalid
//       }
//     }

//     // Ensure heightmap dimensions match
//     if (heightmap.length !== width * depth) {
//       console.error(
//         `Heightmap size mismatch: expected ${width * depth}, got ${heightmap.length}`
//       );
//       throw new Error("Heightmap dimensions do not match geometry.");
//     }
    
//     const positionAttribute = geometry.attributes.position;
//     for (let i = 0; i < positionAttribute.count; i++) {
//       const elevation = heightmap[i];
//       if (isNaN(elevation)) {
//         console.error(`Invalid elevation at vertex ${i}: ${elevation}`);
//         throw new Error("NaN detected in heightmap.");
//       }
//     }
//     const colorAttribute = new THREE.BufferAttribute(
//       new Float32Array(positionAttribute.count * 3),
//       3
//     );

//     for (let i = 0; i < positionAttribute.count; i++) {
//       const elevation = heightmap[i];
//       const vertex = new THREE.Vector3().fromBufferAttribute(
//         positionAttribute,
//         i
//       );
//       vertex.y = elevation;
//       positionAttribute.setXYZ(i, vertex.x, vertex.y, vertex.z);

//       const elevationNormalized = (elevation + 1) / 3;
//       let color;
//       if (elevationNormalized < .75) {
//         color = new THREE.Color(colorPalette.cranberry);
//       } else if (elevationNormalized < 1.5) {
//         color = new THREE.Color(colorPalette.mdpurple);
//       } else if (elevationNormalized < 3) {
//         color = new THREE.Color(colorPalette.dkpurple);
//       } else if (elevationNormalized < 5) {
//         color = new THREE.Color(colorPalette.mdorange);
//       } else if (elevationNormalized < 7) {
//         color = new THREE.Color(colorPalette.dkorange);
//       } else if (elevationNormalized < 9) {
//         color = new THREE.Color(colorPalette.magenta);
//       } else if (elevationNormalized < 10.5) {
//         color = new THREE.Color(colorPalette.ltpurple);
//       } else if (elevationNormalized < 12.5) {
//         color = new THREE.Color(colorPalette.ltorange);
//       } else {
//         color = new THREE.Color(colorPalette.mdgreen);
//       }

//       colorAttribute.setXYZ(i, color.r, color.g, color.b);
//     }

//     geometry.setAttribute("color", colorAttribute);
//     geometry.computeVertexNormals();
//     geometry.computeBoundingBox();
//     geometry.computeBoundingSphere();
//     console.log("[Terrain] Geometry and colors set up successfully.");

//     return [geometry, heightmap];
//   }, [
//     itemPositions,
//     parsedData,
//     itemMetrics,
//     divisions,
//     size,
//     xExtent,
//     yExtent,
//   ]);

//   function getItemHeightForLabel(xPosOriginal, zPosOriginal) {
//     const width = divisions + 1;

//     // Map item coords to terrain coords using actual extents
//     const xPos = mapToTerrainCoords(
//       xPosOriginal,
//       xExtent[0],
//       xExtent[1],
//       size
//     );
//     const zPos = mapToTerrainCoords(
//       zPosOriginal,
//       yExtent[0],
//       yExtent[1],
//       size
//     );

//     const [ix, iz] = normalizePosition(
//       [xPosOriginal, zPosOriginal],
//       width,
//       xExtent,
//       yExtent
//     );

//     const ixClamped = Math.max(0, Math.min(width - 1, ix));
//     const izClamped = Math.max(0, Math.min(width - 1, iz));

//     const i = izClamped * width + ixClamped;
//     const h = heightmap[i] || 0;

//     return { terrainX: xPos, terrainZ: zPos, height: h };
//   }

//   const labelPositions = useMemo(() => {
//     return itemPositions.map(({ item, position }) => {
//       const [origX, origZ] = position;
//       const { terrainX, terrainZ, height: peakHeight } = getItemHeightForLabel(
//         origX,
//         origZ
//       );
//       const finalPos = [terrainX, peakHeight + 5, terrainZ]; // Increased height offset
//       return { item, position: finalPos };
//     });
//   }, [itemPositions, heightmap, size, xExtent, yExtent]);
  

//   return (
//     <>
//       <mesh ref={meshRef} geometry={geometry}>
//         <meshStandardMaterial
//           vertexColors={true}
//           side={THREE.DoubleSide}
//           flatShading={false}
//           roughness={0.7} // Adjust for better texture
//           metalness={0.1}
//         />
//       </mesh>
//       {labelPositions.map(({ item, position }) => (
//         <Html
//           key={item}
//           position={position}
//           style={{ pointerEvents: "none" }}
//         >
//           <div
//             style={{
//               color: "white",
//               fontSize: "12px",
//               textAlign: "center",
//               background: "rgba(0,0,0,0.7)",
//               padding: "4px 6px",
//               borderRadius: "4px",
//               boxShadow: "0px 2px 4px rgba(0,0,0,0.3)",
//             }}
//           >
//             {item}
//           </div>
//         </Html>
//       ))}
//     </>
//   );
// }

// const FPGraphPage = () => {
//   const { data } = useDataset("fpgrowth");

//   const fpgrowthData = useMemo(() => {
//     if (!data?.FPGrowth) return null;
//     const cleanedString = data.FPGrowth.replace(/Infinity/g, "2");
//     try {
//       return JSON.parse(cleanedString);
//     } catch {
//       console.error("Error parsing FPGrowth data.", cleanedString);
//       return null;
//     }
//   }, [data]);

//   const parsedData = useMemo(() => {
//     if (!fpgrowthData || !fpgrowthData.data) return [];
//     const { columns, data: rows } = fpgrowthData;
//     return rows.map((row) => {
//       const rowData = {};
//       columns.forEach((col, index) => {
//         rowData[col] = row[index];
//       });
//       const antecedents = parseItemList(rowData["antecedents"]);
//       const consequents = parseItemList(rowData["consequents"]);
//       return {
//         antecedents,
//         consequents,
//         antecedent_support: rowData["antecedent support"] || 0,
//         consequent_support: rowData["consequent support"] || 0,
//         support: rowData["support"] || 0,
//         confidence: rowData["confidence"] || 0,
//         lift: rowData["lift"] || 0,
//         conviction: rowData["conviction"] || 0,
//         zhangs_metric: rowData["zhangs_metric"] || 0,
//       };
//     });
//   }, [fpgrowthData]);

//   const uniqueItems = useMemo(() => {
//     const items = new Set();
//     parsedData.forEach((rule) => {
//       rule.antecedents.forEach((item) => items.add(item));
//       rule.consequents.forEach((item) => items.add(item));
//     });
//     return Array.from(items);
//   }, [parsedData]);

//   const itemMetrics = useMemo(() => {
//     const metrics = {};
//     uniqueItems.forEach((item) => {
//       metrics[item] = {
//         support: 0,
//         conviction: 0,
//         zhangs_metric: 0,
//         lift: 0,
//         count: 0,
//       };
//     });
//     distributeMetrics(parsedData, metrics);
//     console.log("[FPGraphPage] Item metrics (aggregated):", metrics);
//     return metrics;
//   }, [parsedData, uniqueItems]);

//   const {
//     itemPositions: arrangedItemPositions,
//     xExtent,
//     yExtent,
//   } = useMemo(() => {
//     const { itemPositions, xExtent, yExtent } = arrangeItems(
//       uniqueItems,
//       parsedData
//     );
//     // Map positions to include metrics
//     const positions = itemPositions.map(({ item, position }) => ({
//       item,
//       position,
//       metrics:
//         itemMetrics[item] || {
//           support: 0,
//           lift: 0,
//           conviction: 0,
//           zhangs_metric: 0,
//         },
//     }));
//     return {
//       itemPositions: positions,
//       xExtent,
//       yExtent,
//     };
//   }, [uniqueItems, itemMetrics, parsedData]);

//   // Calculate size based on extents
//   const xRange = xExtent[1] - xExtent[0];
//   const yRange = yExtent[1] - yExtent[0];
//   const size = Math.max(xRange, yRange) * 1.5; // Add scaling factor

//   console.log("[FPGraphPage] Render start");
//   return (
//     <div style={{ height: "100vh", width: "100vw" }}>
//       <Canvas camera={{ position: [0, size, size], fov: 50 }}>
//       {/* <Canvas camera={{ position: [0, 50, 50], fov: 50 }}> */}
//         <OrbitControls enableZoom={true} />
//         <ambientLight intensity={1.6} />
//         <directionalLight position={[10, 30, 10]} intensity={2.8} />
//         {parsedData && <Terrain itemPositions={arrangedItemPositions} parsedData={parsedData} itemMetrics={itemMetrics}            
//           xExtent={xExtent}
//           yExtent={yExtent} />}
//       </Canvas>
//     </div>
//   );
// };

// export default FPGraphPage;




// // // FPGraphPage.js
// // import React, { useMemo, useRef, useContext } from "react";
// // import { Canvas } from "@react-three/fiber";
// // import { OrbitControls, Html } from "@react-three/drei";
// // import * as THREE from "three";
// // import { createNoise3D } from "simplex-noise";
// // import { useDataset } from "../components/DataFetcher";
// // import * as d3 from "d3-force";
// // import { DimensionContext } from '../components/ResponsiveWrapper';

// // // Color palette
// // const colorPalette = {
// //   dkpurple: "#2c294b",
// //   mdpurple: "#3b3484",
// //   ltpurple: "#7174b0",
// //   dkorange: "#de6736",
// //   mdorange: "#e08a3c",
// //   ltorange: "#ebb844",
// //   cranberry: "#762861",
// //   magenta: "#c5316a",
// //   mdgreen: "#80ba55",
// // };

// // function parseItemList(itemList) {
// //   if (!itemList) return [];
// //   return Array.isArray(itemList) ? itemList.flat(Infinity) : [];
// // }

// // function generateBaseNoise(width, depth, simplex, scale = 15, baseAmplitude = 0.75) {
// //   const heightmap = new Float32Array(width * depth);
// //   for (let i = 0; i < width * depth; i++) {
// //     const x = (i % width) / width;
// //     const z = Math.floor(i / width) / depth;
// //     heightmap[i] = simplex(x * scale, z * scale, 0) * baseAmplitude;
// //   }
// //   return heightmap;
// // }

// // function applyPeaks(width, depth, heightmap, itemPositions, normalizePositionFn, sigmaPeak = 0.05, amplitudeScale = 1.0) {
// //   console.log("[applyPeaks] Applying peaks...");
// //   itemPositions.forEach(({ item, position, metrics }) => {
// //     const rawAmplitude =
// //       (metrics.support || 0) +
// //       (metrics.lift || 0) +
// //       (metrics.conviction || 0) +
// //       (metrics.zhangs_metric || 0);

// //     if (rawAmplitude <= 0) return;

// //     const [ix, iz] = normalizePositionFn(position, width);
// //     const scaledAmplitude = (rawAmplitude / 100) * amplitudeScale * 3;

// //     for (let i = 0; i < width * depth; i++) {
// //       const x = (i % width) / width;
// //       const z = Math.floor(i / width) / depth;

// //       const dx = x - ix / width;
// //       const dz = z - iz / depth;
// //       const distSq = dx * dx + dz * dz;

// //       const influence = Math.exp(-distSq / (2 * sigmaPeak * sigmaPeak)) * scaledAmplitude;
// //       heightmap[i] = Math.max(heightmap[i], heightmap[i] + influence);
// //     }
// //   });
// //   console.log("[applyPeaks] Finished applying peaks.");
// //   return heightmap;
// // }

// // function distanceAndProjectionToLineSegment(px, pz, ax, az, cx, cz) {
// //   const acx = cx - ax;
// //   const acz = cz - az;
// //   const apx = px - ax;
// //   const apz = pz - az;
// //   const acLengthSq = acx * acx + acz * acz;

// //   let dist, t;
// //   if (acLengthSq === 0) {
// //     // A and C are essentially the same point
// //     const dx = px - ax;
// //     const dz = pz - az;
// //     dist = Math.sqrt(dx * dx + dz * dz);
// //     t = 0;
// //   } else {
// //     t = (apx * acx + apz * acz) / acLengthSq;
// //     if (t < 0) {
// //       const dx = px - ax;
// //       const dz = pz - az;
// //       dist = Math.sqrt(dx * dx + dz * dz);
// //       t = 0;
// //     } else if (t > 1) {
// //       const dx = px - cx;
// //       const dz = pz - cz;
// //       dist = Math.sqrt(dx * dx + dz * dz);
// //       t = 1;
// //     } else {
// //       const projX = ax + t * acx;
// //       const projZ = az + t * acz;
// //       const dx = px - projX;
// //       const dz = pz - projZ;
// //       dist = Math.sqrt(dx * dx + dz * dz);
// //     }
// //   }
// //   return { dist, t };
// // }

// // function applyLineRidges(width, depth, heightmap, parsedData, itemPositions, normalizePositionFn, sigmaRidge, ridgeScale, baseline) {
// //   console.log("[applyLineRidges] Starting applyLineRidges");
// //   console.log(`[applyLineRidges] Width: ${width}, Depth: ${depth}`);
// //   console.log(`[applyLineRidges] sigmaRidge: ${sigmaRidge}, ridgeScale: ${ridgeScale}`);

// //   const ridgeMap = new Float32Array(width * depth).fill(0);
// //   console.log("[applyLineRidges] Initialized ridgeMap with zeros.");

// //   parsedData.forEach((rule) => {
// //     if (!rule.consequents || rule.consequents.length === 0) return;
// //     const consequentItem = rule.consequents[0];
// //     const consequentPosData = itemPositions.find((p) => p.item === consequentItem);

// //     if (!consequentPosData) {
// //       console.log(`[applyLineRidges] No position data found for consequent: ${consequentItem}, skipping rule.`);
// //       return;
// //     }

// //     const cPos = normalizePositionFn(consequentPosData.position, width);
// //     const supportInfluence = ((rule.support || 0) / 100) * ridgeScale * 100; // Amplify support influence

// //     if (!rule.antecedents || rule.antecedents.length === 0) {
// //       console.log("[applyLineRidges] No antecedents found, skipping.");
// //       return;
// //     }

// //     rule.antecedents.forEach((antItem) => {
// //       const antPosData = itemPositions.find((p) => p.item === antItem);
// //       if (!antPosData) {
// //         console.log(`[applyLineRidges] No position data found for antecedent: ${antItem}`);
// //         return;
// //       }

// //       const aPos = normalizePositionFn(antPosData.position, width);

// //       const ax = aPos[0] / width;
// //       const az = aPos[1] / depth;
// //       const cx = cPos[0] / width;
// //       const cz = cPos[1] / depth;

// //       for (let i = 0; i < width * depth; i++) {
// //         const ix = i % width;
// //         const iz = Math.floor(i / width);
// //         const x = ix / width;
// //         const z = iz / depth;

// //         const { dist } = distanceAndProjectionToLineSegment(x, z, ax, az, cx, cz);
// //         const influence = Math.exp(-(dist * dist) / (2 * sigmaRidge * sigmaRidge)) * supportInfluence;

// //         if (influence > ridgeMap[i]) {
// //           ridgeMap[i] = influence;
// //         }
// //       }
// //     });
// //   });

// //   console.log("[applyLineRidges] Ridge map calculated, sample of first 10 cells:", ridgeMap.slice(0, 10));

// //   for (let i = 0; i < width * depth; i++) {
// //     const baseVal = baseline[i];
// //     const currentHeight = heightmap[i];
// //     const ridgeVal = ridgeMap[i];
// //     const peakHeight = baseVal + ridgeScale;

// //     if (ridgeVal > 0) {
// //       const ridgeHeight = baseVal + ridgeVal;
// //       if (ridgeHeight <= peakHeight && heightmap[i] < ridgeHeight) {
// //         heightmap[i] = ridgeHeight;
// //       }
// //     }
// //   }

// //   console.log("[applyLineRidges] Completed applyLineRidges.");
// //   return heightmap;
// // }


// // function arrangeItems(uniqueItems, parsedData, size = 50) {
// //   const nodes = uniqueItems.map((item) => ({ id: item }));
// //   const links = parsedData.flatMap((rule) =>
// //     rule.antecedents.map((antecedent) => ({
// //       source: antecedent,
// //       target: rule.consequents[0],
// //       value: rule.support,
// //     }))
// //   );

// //   const simulation = d3.forceSimulation(nodes)
// //     .force("link", d3.forceLink(links).id(d => d.id).strength(0.8))
// //     .force("charge", d3.forceManyBody().strength(-100))
// //     .force("center", d3.forceCenter(0, 0))
// //     .force("collision", d3.forceCollide(15));

// //   for (let i = 0; i < 300; i++) simulation.tick();

// //   return nodes.map((node) => ({
// //     item: node.id,
// //     position: [node.x * size, node.y * size],
// //   }));
// // }

// // function distributeMetrics(parsedData, itemMetrics) {
// //   parsedData.forEach((rule) => {
// //     const allItems = [...rule.antecedents, ...rule.consequents];
// //     const totalItems = allItems.length;

// //     allItems.forEach((item) => {
// //       if (!itemMetrics[item]) return;
// //       const weight = totalItems > 0 ? 1 / totalItems : 0;

// //       itemMetrics[item].support += (rule.support || 0) * weight;
// //       itemMetrics[item].lift += (rule.lift || 0) * weight;
// //       itemMetrics[item].conviction += (rule.conviction || 0) * weight;
// //       itemMetrics[item].zhangs_metric += (rule.zhangs_metric || 0) * weight;
// //     });
// //   });
// // }

// // // This maps from [-2500,2500] range to [0, width] or [0, depth] for heightmap indexing:
// // function normalizePosition(position, gridSize) {
// //   return position.map((coord) => Math.round(((coord + 2500) / 5000) * gridSize));
// // }

// // // Map from item coordinate system (-2500 to 2500) to terrain coordinate system (-size/2 to size/2)
// // function mapToTerrainCoords(coord, size) {
// //   const oldMin = -2500;
// //   const oldMax = 2500;
// //   const newMin = -size/2;
// //   const newMax = size/2;
// //   return ((coord - oldMin) / (oldMax - oldMin)) * (newMax - newMin) + newMin;
// // }

// // function generateHeightmapWithPeaksAndRidges(width, depth, itemPositions, parsedData, itemMetrics) {
// //   console.log(`[generateHeightmapWithPeaksAndRidges] Generating heightmap with dimensions: ${width} x ${depth}`);
  
// //   const simplex = createNoise3D();
  
// //   console.log("[generateHeightmapWithPeaksAndRidges] Generating base noise...");
// //   let heightmap = generateBaseNoise(width, depth, simplex, 15, 0.75); 
// //   console.log("[generateHeightmapWithPeaksAndRidges] Base noise sample (first 10 points):", heightmap.slice(0, 10));

// //   console.log("[generateHeightmapWithPeaksAndRidges] Adding peaks...");
// //   heightmap = applyPeaks(width, depth, heightmap, itemPositions, normalizePosition, 0.05, 1.5);
// //   console.log("[generateHeightmapWithPeaksAndRidges] Heightmap after peaks sample (first 10 points):", heightmap.slice(0, 10));
// //   const postPeaksHeightmap = heightmap.slice(); // Copy for baseline

// //   console.log("[generateHeightmapWithPeaksAndRidges] Adding ridges...");
// //   heightmap = applyLineRidges(width, depth, heightmap, parsedData, itemPositions, normalizePosition, 0.006, 15, postPeaksHeightmap);
// //   console.log("[generateHeightmapWithPeaksAndRidges] Heightmap final sample (first 10 points):", heightmap.slice(0, 10));

// //   return heightmap;
// // }

// // function Terrain({ itemPositions, parsedData, itemMetrics }) {
// //   const meshRef = useRef();
// //   const { width: containerWidth, height: containerHeight } = useContext(DimensionContext);

// //   const divisions = Math.max(Math.floor(containerWidth / 5), 50);
// //   const size = Math.min(containerWidth, containerHeight) / 10;

// //   console.log(`[Terrain] containerWidth=${containerWidth}, containerHeight=${containerHeight}, divisions=${divisions}, size=${size}`);

// //   const [geometry, heightmap] = useMemo(() => {
// //     console.log("[Terrain] Computing geometry and heightmap...");
// //     const geometry = new THREE.PlaneGeometry(size, size, divisions, divisions);
// //     geometry.rotateX(-Math.PI / 2);

// //     const width = divisions + 1;
// //     const depth = divisions + 1;

// //     // Do NOT rescale itemPositions for heightmap generation here,
// //     // keep them as originally provided. The heightmap calculations
// //     // rely on the original coordinate system and normalization.

// //     const heightmap = generateHeightmapWithPeaksAndRidges(width, depth, itemPositions, parsedData, itemMetrics);    

// //     const positionAttribute = geometry.attributes.position;
// //     const colorAttribute = new THREE.BufferAttribute(new Float32Array(positionAttribute.count * 3), 3);

// //     for (let i = 0; i < positionAttribute.count; i++) {
// //       const elevation = heightmap[i];
// //       const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
// //       vertex.y = elevation;
// //       positionAttribute.setXYZ(i, vertex.x, vertex.y, vertex.z);

// //       const elevationNormalized = (elevation + 1) / 3;
// //       let color;
// //       if (elevationNormalized < .75) {
// //         color = new THREE.Color(colorPalette.cranberry);
// //       } else if (elevationNormalized < 1.5) {
// //         color = new THREE.Color(colorPalette.mdpurple);
// //       } else if (elevationNormalized < 3) {
// //         color = new THREE.Color(colorPalette.dkpurple);
// //       } else if (elevationNormalized < 5) {
// //         color = new THREE.Color(colorPalette.mdorange);
// //       } else if (elevationNormalized < 7) {
// //         color = new THREE.Color(colorPalette.dkorange);
// //       } else if (elevationNormalized < 9) {
// //         color = new THREE.Color(colorPalette.magenta);
// //       } else if (elevationNormalized < 10.5) {
// //         color = new THREE.Color(colorPalette.ltpurple);
// //       } else if (elevationNormalized < 12.5) {
// //         color = new THREE.Color(colorPalette.ltorange);
// //       } else {
// //         color = new THREE.Color(colorPalette.mdgreen);
// //       }

// //       colorAttribute.setXYZ(i, color.r, color.g, color.b);
// //     }

// //     geometry.setAttribute("color", colorAttribute);
// //     geometry.computeVertexNormals();
// //     console.log("[Terrain] Geometry and colors set up successfully.");

// //     return [geometry, heightmap];
// //   }, [itemPositions, parsedData, itemMetrics, divisions, size]);

// //   // We'll fetch heights by first mapping item coordinates to terrain coords:
// //   function getItemHeightForLabel(xPosOriginal, zPosOriginal) {
// //     const width = divisions + 1;

// //     // Map item coords [-2500,2500] to terrain coords [-size/2, size/2]
// //     const xPos = mapToTerrainCoords(xPosOriginal, size);
// //     const zPos = mapToTerrainCoords(zPosOriginal, size);

// //     // Now normalize these terrain coords for indexing into heightmap
// //     const [ix, iz] = normalizePosition([xPos, zPos], width);

// //     // Clamp indices just to be safe
// //     const ixClamped = Math.max(0, Math.min(width - 1, ix));
// //     const izClamped = Math.max(0, Math.min(width - 1, iz));

// //     const i = izClamped * width + ixClamped;
// //     const h = heightmap[i] || 0;
// //     console.log(`[getItemHeightForLabel] original=(${xPosOriginal}, ${zPosOriginal}), mapped=(${xPos}, ${zPos}), ix=${ix}, iz=${iz}, h=${h}`);
// //     return { terrainX: xPos, terrainZ: zPos, height: h };
// //   }
  
// //   const labelPositions = useMemo(() => {
// //     console.log("[Terrain] Computing labelPositions...");
// //     const lp = itemPositions.map(({ item, position }) => {
// //       const [origX, origZ] = position;
// //       const { terrainX, terrainZ, height: peakHeight } = getItemHeightForLabel(origX, origZ);
// //       const finalPos = [terrainX, peakHeight + 3, terrainZ];
// //       console.log(`[Terrain labelPositions] item=${item}, orig=(${origX},${origZ}), terrain=(${terrainX},${terrainZ}), peakHeight=${peakHeight}, finalPos=${finalPos}`);
// //       return { item, position: finalPos };
// //     });
// //     return lp;
// //   }, [itemPositions, heightmap, size]);

// //   return (
// //     <>
// //       <mesh ref={meshRef} geometry={geometry}>
// //         <meshStandardMaterial vertexColors={true} side={THREE.DoubleSide} flatShading={false} />
// //       </mesh>
// //       {labelPositions.map(({ item, position }) => {
// //         console.log(`[Terrain render] Rendering label for item=${item} at position=${position}`);
// //         return (
// //           <Html
// //             key={item}
// //             position={position}
// //             style={{ pointerEvents: "none" }}
// //           >
// //             <div
// //               style={{
// //                 color: "white",
// //                 fontSize: "12px",
// //                 textAlign: "center",
// //                 background: "rgba(0,0,0,0.7)",
// //                 padding: "4px 6px",
// //                 borderRadius: "4px",
// //                 boxShadow: "0px 2px 4px rgba(0,0,0,0.3)",
// //               }}
// //             >
// //               {item}
// //             </div>
// //           </Html>
// //         );
// //       })}
// //     </>
// //   );
// // }

// // const FPGraphPage = () => {
// //   const { data } = useDataset("fpgrowth");

// //   const fpgrowthData = useMemo(() => {
// //     if (!data?.FPGrowth) return null;
// //     const cleanedString = data.FPGrowth.replace(/Infinity/g, "2");
// //     try {
// //       return JSON.parse(cleanedString);
// //     } catch {
// //       console.error("Error parsing FPGrowth data.", cleanedString);
// //       return null;
// //     }
// //   }, [data]);

// //   const parsedData = useMemo(() => {
// //     if (!fpgrowthData || !fpgrowthData.data) return [];
// //     const { columns, data: rows } = fpgrowthData;
// //     return rows.map((row) => {
// //       const rowData = {};
// //       columns.forEach((col, index) => {
// //         rowData[col] = row[index];
// //       });
// //       const antecedents = parseItemList(rowData["antecedents"]);
// //       const consequents = parseItemList(rowData["consequents"]);
// //       return {
// //         antecedents,
// //         consequents,
// //         antecedent_support: rowData["antecedent support"] || 0,
// //         consequent_support: rowData["consequent support"] || 0,
// //         support: rowData["support"] || 0,
// //         confidence: rowData["confidence"] || 0,
// //         lift: rowData["lift"] || 0,
// //         conviction: rowData["conviction"] || 0,
// //         zhangs_metric: rowData["zhangs_metric"] || 0,
// //       };
// //     });
// //   }, [fpgrowthData]);

// //   const uniqueItems = useMemo(() => {
// //     const items = new Set();
// //     parsedData.forEach((rule) => {
// //       rule.antecedents.forEach((item) => items.add(item));
// //       rule.consequents.forEach((item) => items.add(item));
// //     });
// //     return Array.from(items);
// //   }, [parsedData]);

// //   const itemMetrics = useMemo(() => {
// //     const metrics = {};
// //     uniqueItems.forEach((item) => {
// //       metrics[item] = {
// //         support: 0,
// //         conviction: 0,
// //         zhangs_metric: 0,
// //         lift: 0,
// //         count: 0,
// //       };
// //     });
// //     distributeMetrics(parsedData, metrics);
// //     console.log("[FPGraphPage] Item metrics (aggregated):", metrics);
// //     return metrics;
// //   }, [parsedData, uniqueItems]);

// //   const itemPositions = useMemo(() => {
// //     const positions = arrangeItems(uniqueItems, parsedData, 100).map(({ item, position }) => ({
// //       item,
// //       position,
// //       metrics: itemMetrics[item] || { support: 0, lift: 0, conviction: 0, zhangs_metric: 0 },
// //     }));
// //     console.log("[FPGraphPage] itemPositions:", positions);
// //     return positions;
// //   }, [uniqueItems, itemMetrics, parsedData]);

// //   console.log("[FPGraphPage] Render start");
// //   return (
// //     <div style={{ height: "100vh", width: "100vw" }}>
// //       <Canvas camera={{ position: [0, 50, 50], fov: 50 }}>
// //         <OrbitControls enableZoom={true} />
// //         <ambientLight intensity={1.6} />
// //         <directionalLight position={[10, 30, 10]} intensity={2.8} />
// //         {parsedData && <Terrain itemPositions={itemPositions} parsedData={parsedData} itemMetrics={itemMetrics} />}
// //       </Canvas>
// //     </div>
// //   );
// // };

// // export default FPGraphPage;